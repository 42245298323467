interface XIconCellProps {
  title: string;
  imageURL?: string;
}

const XIconCell = (props: XIconCellProps) => {
  return (
    <div className="h-[100%] flex items-center flex-1 gap-4">
      {props.imageURL && (
        <img src={props.imageURL} className="w-[44px] h-[44px] rounded-[50%]" />
      )}
      {props.title}
    </div>
  );
};

export default XIconCell;

import { ChangeEvent, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import XFButton from "./XFButton";
import { iDoc, iX } from "components/common/XIcons";

interface XFUploadProps {
  name: string;
  onChange: (file: File | null) => void;
  imageUrl?: string;
  label?: string;
}

const XFUpload = (props: XFUploadProps) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [fileTitle, setFileTitle] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragEvents = {
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setIsDragOver(false);
      const file = e.dataTransfer.files[0];
      if (file) {
        setFileTitle(file.name);
        props.onChange(file); // Pass the file to the onChange callback
      }
    },
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setFileTitle(file.name);
      props.onChange(file); // Pass the file to the onChange callback
    }
  };

  const handleRemove = () => {
    setFileTitle(""); // Reset fileTitle state
    props.onChange(null); // Clear the file in the parent component
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the input field
    }
  };

  return (
    <div className="flex flex-col flex-1 gap-1.5">
      <input
        type="file"
        className="hidden"
        id="file-upload"
        onChange={handleChange}
        ref={fileInputRef}
      />
      {props.label}
      {props.imageUrl ? (
        <div className="relative w-[200px]">
          <img className="w-[200px] rounded-[100%]" src={props.imageUrl} />
          <div className="absolute p-2 right-3 bottom-3 bg-black/80 rounded-lg">
            <DeleteIcon
              className="cursor-pointer hover:text-[#a3a3a3]"
              fontSize="medium"
              onClick={handleRemove}
            />
          </div>
        </div>
      ) : (
        !fileTitle && (
          <label
            htmlFor="file-upload"
            {...dragEvents}
            className={`relative h-[250px] bg-white/1 border border-dashed border-x-gray3 text-white/40 rounded-lg flex flex-col gap-4 cursor-pointer text-center items-center justify-center ${isDragOver && "bg-white/5"} hover:bg-white/5`}
            style={{
              backgroundImage:
                "url(data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231A1A1AFF' stroke-width='4' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e)",
            }}
          >
            <span>Drag & Drop to upload file</span>
            <XFButton
              title="Browse Files"
              variant="tertiary"
              size="sm"
              type="button"
              onClick={() => fileInputRef.current?.click()}
            />
          </label>
        )
      )}
      {fileTitle && (
        <div className="w-full flex items-center gap-5 px-2 py-2 pr-4 border border-white/20 rounded-xl bg-white/10">
          <div className="flex flex-row gap-2 items-center flex-1">
            <div className="flex items-center justify-center w-[40px] h-[40px] bg-black rounded-[100%]">
              <img src={iDoc} />
            </div>
            <span>{fileTitle}</span>
          </div>
          <img src={iX} onClick={handleRemove} />
        </div>
      )}
    </div>
  );
};

export default XFUpload;

import { XFForm, XFTextField } from "components/form";
import { Formik, FormikValues } from "formik";
import { schemaLeadCreate } from "utils/validation/schema";
import { createLeadAPI } from "shared/service/Lead";
import { useMutation } from "@tanstack/react-query";
import { CreateLeadInput } from "shared/API";
import XModal from "components/XModal";
import { useSnackbar } from "hooks/useSnackbar";
import { generateMutationMessage } from "utils/generateMutationMessage";

interface LeadCreateModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const initialValues = {
  title: "",
  description: "",
  startTimestampstamp: "",
  endTimestampstamp: "",
  ticketLink: "",
  image: "",
};

const getCreateLeadInput = (
  values: FormikValues
): { lead: CreateLeadInput } => ({
  lead: {
    eventType: values.eventType,
    eventTitle: values.eventTitle,
    leadStatus: values.leadStatus,
    startTimestamp: values.startTimestamp,
    endTimestamp: values.endTimestamp,
    guests: values.guests,
  },
});

// export type CreateLeadInput = {
//   id?: string;
//   eventType: EventType;
//   leadStatus: LeadStatus;
//   startTimestamp: number;
//   endTimestamp: number;
//   eventTitle: string;
//   guests: number;
//   minAgeRange?: number;
//   maxAgeRange?: number;
//   budget?: number;
//   venue?: EntityStub;
//   address?: Address;
//   contact?: Contact;
//   name?: Name;
//   description?: string;
//   inquiryRecipient?: EntityStub;
//   services?: ServiceStub[];
//   totalPrice?: number;
//   depositPolicy?: DepositPolicy;
//   cancellationPolicy?: CancellationPolicy;
//   discountPolicy?: DiscountPolicy;
// };

const LeadCreateModal = ({ onSuccess, onClose }: LeadCreateModalProps) => {
  const { showSnackbar } = useSnackbar();
  const { mutate } = useMutation({
    mutationKey: ["LeadCreateModal.createLead"],
    mutationFn: createLeadAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage("Lead", "create", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Lead", "create", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues) => {
    mutate(getCreateLeadInput(values));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaLeadCreate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <XModal
          title="Create Lead"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField label="Event Type" name="eventType" />
              <XFTextField label="Event Title" name="eventTitle" />
              <XFTextField label="Lead Status" name="leadStatus" />
              <XFTextField label="Start Time" name="startTimestamp" />
              <XFTextField label="End Time" name="endTimestamp" />
              <XFTextField label="Guests" name="guests" />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default LeadCreateModal;

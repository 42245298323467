import { useMutation } from "@tanstack/react-query";
import { deleteVendorAPI } from "shared/service/Vendor";
import XConfirmationModal from "components/XConfirmationModal";
import { useSnackbar } from "hooks/useSnackbar";
import { generateMutationMessage } from "utils/generateMutationMessage";

interface ArtistDeleteModalProps {
  onSuccess: () => void;
  onClose: () => void;
  vendorId: string;
}

const ArtistDeleteModal = (props: ArtistDeleteModalProps) => {
  const { showSnackbar } = useSnackbar();
  const { mutate } = useMutation({
    mutationKey: ["ArtistDeleteModal.delete", props.vendorId],
    mutationFn: deleteVendorAPI,
    onSuccess: () => {
      props.onSuccess();
      props.onClose();
      showSnackbar({
        message: generateMutationMessage("Artist", "delete", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Artist", "delete", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  return (
    <XConfirmationModal
      title="Delete Artist"
      message="Are you sure you want to delete this artist?"
      onConfirm={() =>
        mutate({
          vendor: { id: props.vendorId },
        })
      }
      onClose={props.onClose}
    />
  );
};

export default ArtistDeleteModal;

import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
} from "@tanstack/react-query";
import { XFButton, XFForm, XFRow, XFTextField } from "components/form";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "hooks/useSnackbar";
import { useParams, useNavigate } from "react-router";
import { paths } from "router/routes";
import { Vendor } from "shared/API";
import { updateVendorAPI } from "shared/service/Vendor";
import getChangedFields from "utils/detectChangedFields";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { schemaArtistTabPersonalInfo } from "utils/validation/schema";

const getInitialValues = (artist: Vendor): FormikValues => ({
  username: artist.username,
  displayName: artist.displayName,
  headline: artist.headline,
  description: artist.description,
  serviceTypes: artist.serviceTypes,
  base: artist.address?.postalCode,
  phone: artist.contact?.phones[0],
  email: artist.contact?.emails[0],
});

const getCreateMutationInput = (
  artist: Vendor,
  values: FormikValues,
  ownerId: string | undefined
): { vendor: Vendor; isPrivate: boolean } => {
  const changedFields = getChangedFields(getInitialValues(artist), values);
  return {
    vendor: {
      id: artist.id,
      ...(changedFields.username && { username: changedFields.username }),
      ...(changedFields.displayName && {
        displayName: changedFields.displayName,
      }),
      ...(changedFields.headline && { headline: changedFields.headline }),
      ...(changedFields.description && {
        description: changedFields.description,
      }),
      ...(changedFields.base && {
        address: {
          postalCode: changedFields.base,
        },
      }),
      ...((changedFields.phone || changedFields.email) && {
        contact: {
          phones: [values.phone || ""],
          emails: [values.email || ""],
        },
      }),
    },
    isPrivate: Boolean(ownerId),
  };
};

const ArtistTabPersonalInfo = ({
  artist,
  refetch,
}: {
  artist: Vendor;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<Vendor, Error>>;
}) => {
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const { showSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationKey: ["ArtistTabPersonalInfo.updateVendor", artist.id],
    mutationFn: updateVendorAPI,
    onSuccess: () => {
      refetch();
      showSnackbar({
        message: generateMutationMessage("Artist", "update", true),
        severity: "success",
      });
      navigate(paths.Artists.list(ownerId));
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Artist", "update", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const handleSubmit = (values: FormikValues, { setSubmitting }: any) => {
    mutate(getCreateMutationInput(artist, values, ownerId), {
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={getInitialValues(artist)}
      validationSchema={schemaArtistTabPersonalInfo}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty, resetForm }) => (
        <XFForm>
          <XFRow>
            <XFTextField label="Username" name="username" />
            <XFTextField label="Display Name" name="displayName" />
          </XFRow>
          <XFRow>
            <XFTextField label="Headline" name="headline" />
            <XFTextField
              label="Based in"
              name="base"
              placeholder="5-digit Zipcode"
              type="number"
            />
          </XFRow>
          <XFTextField label="Description" name="description" rows={6} />
          <h4 className="mt-[40px]">Agent Info</h4>
          <XFRow>
            <XFTextField label="Phone Number" name="phone" />
            <XFTextField label="Email" name="email" />
          </XFRow>
          {dirty && (
            <XFRow>
              <XFButton
                title="Save Changes"
                type="submit"
                disabled={isSubmitting}
                size="lg"
              />
              <XFButton
                title="Reset"
                type="button"
                onClick={() => resetForm()}
                size="lg"
                variant="secondary"
              />
            </XFRow>
          )}
        </XFForm>
      )}
    </Formik>
  );
};

export default ArtistTabPersonalInfo;

import Header from "layout/Header";
import { Outlet } from "react-router";

const LayoutNaked = () => {
  return (
    <div className="md:relative md:h-[100vh]">
      <Header />
      <main className="max-w-[400px] mx-auto px-8 md:absolute md:left-0 md:right-0 md:top-[25%]">
        <Outlet />
      </main>
    </div>
  );
};

export default LayoutNaked;

import Page from "components/Page";
import { Box, Typography } from "@mui/joy";
import { SxProps } from "@mui/material";
import { XIconed } from "components/common/XIconed";
import { iCalendar, iTime } from "components/common/XIcons";
import { XSpace } from "components/common/XSpace";
import XButton from "components/common/XButton";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { eventByIdAPI } from "shared/service/Event";
import { extractDate, extractTime } from "utils/timestamp";
import { XLabeledContainer } from "components/XTitles";

const EventPage = () => {
  const { id, ownerId } = useParams();

  const { data: event } = useQuery({
    queryKey: ["ArtistDetailsPage.eventByIdAPI", id],
    queryFn: () =>
      eventByIdAPI({
        event: { id: String(id) },
        isPrivate: Boolean(ownerId),
      }),
    enabled: !!id,
  });

  const sxEventDetailsContainer: SxProps = {
    display: "flex",
    gap: 10,
    width: "100%",
  };

  const sxEventDetails: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    fontSize: "20px",
    fontWeight: 300,
    color: "#ffffff",
  };

  const sxDivider: SxProps = {
    background:
      "linear-gradient(90deg, rgba(204, 204, 204, 0.2) 0%, rgba(204, 204, 204, 0) 60%)",
    height: "1px",
  };

  const sxDateTime: SxProps = {
    display: "flex",
    gap: 12,
  };

  const sxList: SxProps = {
    display: "flex",
    gap: 4,
  };

  const sxEventImage: SxProps = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: 400,
    height: 400,
  };

  if (event) {
    return (
      <Page>
        <div className="flex flex-start gap-[48px]">
          <img
            src={event.imageUrl}
            width={400}
            className="w-[400px] max-w-[400px] rounded-lg"
          />
          <div className="flex flex-col gap-[50px]">
            <h1 className="x-h1-bold">{event.title}</h1>
            <XLabeledContainer
              items={[
                {
                  label: "Location",
                  value: event.address
                    ? `${event.address?.city}, ${event.address?.state}`
                    : " ",
                },
                { label: "Date", value: extractDate(event.startTimestamp) },
                { label: "Time", value: extractTime(event.startTimestamp) },
              ]}
            />
            <h3>About Event</h3>
            <p>{event.description}</p>
          </div>
        </div>
        <XSpace />
        <Box sx={sxEventDetailsContainer}>
          <Box sx={sxEventImage}></Box>
          <Box sx={sxEventDetails}>
            <Box sx={sxDivider}></Box>
            <Box sx={sxDateTime}>
              <XIconed icon={iCalendar} text="Jan 27th, 2024" size="md" />
              <XIconed icon={iTime} text="7:00 PM" size="md" />
            </Box>
            <Box sx={sxDivider}></Box>
            <XIconed text="Artists" opts={{ uppercase: true }} size="md">
              <Box sx={sxList}>
                {event.artists?.map((x, i) => {
                  return <Box key={i}>{x.displayName}</Box>;
                })}
              </Box>
            </XIconed>
            {event.hosts && (
              <>
                <XIconed text="Hosts" opts={{ uppercase: true }} size="md">
                  <Box sx={sxList}>
                    {event.hosts.map((x, i) => {
                      return <Box key={i}>{x.displayName}</Box>;
                    })}
                  </Box>
                </XIconed>
              </>
            )}
          </Box>
        </Box>
        <XSpace />
        <Typography level="h1">About Event</Typography>
        <p>{event.description}</p>
        <XSpace />
        <p style={{ textAlign: "center" }}>
          <XButton to="https://eventbrite.com">Buy Tickets</XButton>
        </p>
      </Page>
    );
  } else {
    return <></>;
  }
};

export default EventPage;

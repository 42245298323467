import { XFButton, XFTextField } from "components/form";
import { FormikValues, useFormikContext } from "formik";

const BookStep3 = () => {
  const { submitForm } = useFormikContext<FormikValues>();

  return (
    <>
      <XFTextField label="Event Type" name="eventType" />
      <XFTextField label="Event Title" name="eventTitle" />
      <XFButton title="Next" type="button" onClick={submitForm} />
    </>
  );
};

export default BookStep3;

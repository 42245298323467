import { useMutation, useQuery } from "@tanstack/react-query";
import { XFForm, XFButton, XFTextField, XFTextarea } from "components/form";
import XModal from "components/XModal";
import { Formik, FormikValues } from "formik";
import { UpdateServiceInput } from "shared/API";
import { serviceByIdAPI, updateServiceAPI } from "shared/service/Service";
import { schemaArtistCreatePackage } from "utils/validation/schema";

interface ArtistPackageUpdateModalProps {
  packageId: string;
  vendorId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const ArtistPackageUpdateModal = ({
  packageId,
  vendorId,
  onSuccess,
  onClose,
}: ArtistPackageUpdateModalProps) => {
  const { data: service } = useQuery({
    queryKey: ["ArtistPackageUpdateModal.servicesAPI", packageId],
    queryFn: () =>
      serviceByIdAPI({
        vendor: { id: vendorId },
        service: { id: packageId },
      }),
    enabled: !!vendorId,
  });

  const { mutate } = useMutation({
    mutationKey: ["ArtistPackageUpdateModal.updatePackage", packageId],
    mutationFn: updateServiceAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to update the service: ", error);
    },
  });

  const onSubmit = (values: FormikValues) => {
    const updateInput: {
      vendorId: string;
      service: UpdateServiceInput;
    } = {
      vendorId,
      service: {
        id: packageId,
        serviceType: values.serviceType,
        title: values.title,
        description: values.description,
      },
    };

    mutate(updateInput);
  };

  const initialValues: FormikValues = {
    title: service?.title || "",
    description: service?.description || "",
    serviceType: service?.serviceType || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaArtistCreatePackage}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting }) => (
        <XModal
          title="Update Package"
          onClose={onClose}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField name="eventType" label="Event Type" />
              <XFTextField name="services" label="Services" />
              <XFTextField name="discount" label="Discount" />
              <XFTextField name="title" label="Title" />
              <XFTextarea name="description" label="Description" />
              <XFButton title="Save Changes" type="submit" size="lg" />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};
export default ArtistPackageUpdateModal;

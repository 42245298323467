import { XFForm, XFTextField, XFUpload } from "components/form";
import { Formik, FormikValues } from "formik";
import { schemaEventUpdate } from "utils/validation/schema";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Event, UpdateEventInput } from "shared/API";
import XModal from "components/XModal";
import { useSnackbar } from "hooks/useSnackbar";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { eventByIdAPI, updateEventAPI } from "shared/service/Event";
import { XFDateTimePicker } from "components/form/XFDateField";
import { fetchArtists } from "components/form/autocomplete/queries";
import XFAutocompleteAPI, {
  XFAutocompleteOption,
} from "components/form/XFAutocompleteAPI";

interface EventUpdateModalProps {
  eventId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const getInitialValues = (event: Event | undefined) => ({
  title: event?.title || "",
  description: event?.description || "",
  startTimestamp: event?.startTimestamp || "",
  endTimestamp: event?.endTimestamp || "",
  ticketLink: event?.ticketLink || "",
  artists:
    event && event?.artists
      ? event?.artists?.map((artist) => ({
          label: artist.displayName,
          value: artist.id,
        }))
      : [],
  image: "",
});

const getUpdateEventInput = (
  eventId: string,
  values: FormikValues
): { event: UpdateEventInput; image?: File; isPrivate?: boolean } => ({
  event: {
    id: eventId,
    title: values.title,
    description: values.description,
    startTimestamp: values.startTimestamp,
    endTimestamp: values.endTimestamp,
    ticketLink: values.ticketLink,
    artists: values.artists.map((artist: XFAutocompleteOption) => ({
      displayName: artist.label,
      value: artist.value,
    })),
  },
  image: values.image,
  isPrivate: false,
});

const EventUpdateModal = ({
  eventId,
  onSuccess,
  onClose,
}: EventUpdateModalProps) => {
  const { showSnackbar } = useSnackbar();

  const { data: event } = useQuery({
    queryKey: ["EventUpdateModal.getEvent", eventId],
    queryFn: () =>
      eventByIdAPI({
        event: { id: eventId },
      }),
    enabled: !!eventId,
  });

  const { mutate } = useMutation({
    mutationKey: ["EventUpdateModal.updateEvent", eventId],
    mutationFn: updateEventAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage("Event", "update", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Event", "update", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    console.log("artists", values.artists);
    console.log("input", getUpdateEventInput(eventId, values));

    mutate(getUpdateEventInput(eventId, values), {
      onSettled: () => setSubmitting(false),
    });
  };

  console.log("event", event);

  return (
    <Formik
      initialValues={getInitialValues(event)}
      validationSchema={schemaEventUpdate}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <XModal
          title="Update Event"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField label="Title" name="title" />
              <XFTextField label="Description" name="description" rows={4} />
              <XFTextField label="Ticket Link" name="ticketLink" />
              <XFAutocompleteAPI
                label="Artists"
                name="artists"
                fetchOptions={fetchArtists}
              />
              <XFUpload
                label="Event Picture"
                name="image"
                onChange={(file) => setFieldValue("image", file)}
              />
              <XFDateTimePicker
                label="Start Time"
                name="startTimestamp"
                setFieldValue={setFieldValue}
              />
              <XFDateTimePicker
                label="End Time"
                name="endTimestamp"
                setFieldValue={setFieldValue}
              />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};
export default EventUpdateModal;

import { useMutation } from "@tanstack/react-query";
import { XFForm, XFUpload } from "components/form";
import XModal from "components/XModal";
import { Formik, FormikValues, useFormikContext } from "formik";
import { useSnackbar } from "hooks/useSnackbar";
import { updateVendorAPI } from "shared/service/Vendor";
import { generateMutationMessage } from "utils/generateMutationMessage";

interface ArtistProfilePictureUpdateModalProps {
  vendorId: string;
  onClose: () => void;
  onSuccess: () => void;
  imageURL?: string;
  isPrivate?: boolean;
}

const ArtistProfilePictureUpdateModal = ({
  imageURL,
  vendorId,
  isPrivate,
  onSuccess,
  onClose,
}: ArtistProfilePictureUpdateModalProps) => {
  const { showSnackbar } = useSnackbar();
  const { setFieldValue } = useFormikContext();

  const { mutate } = useMutation({
    mutationKey: [
      "ArtistProfilePictureUpdateModal.updateProfilePicture",
      vendorId,
    ],
    mutationFn: updateVendorAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: `${generateMutationMessage("Profile Picture", "update", true)}.`,
        severity: "error",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Profile Picture", "update", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues) => {
    mutate({
      vendor: {
        id: vendorId,
      },
      image: values.image,
      isPrivate,
    });
  };

  const initialValues: FormikValues = {
    image: imageURL || "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={{}}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting }) => (
        <XModal
          title="Create Package"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFUpload
                label="Profile Picture"
                name="image"
                onChange={(file) => {
                  setFieldValue("image", file);
                }}
              />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};
export default ArtistProfilePictureUpdateModal;

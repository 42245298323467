/*
This file is autogenerated. Please don't make any changes.
The original file can be found at: https://github.com/iplanx/backend/blob/master/functions/src/service/API.ts
*/

import { QueryDocumentSnapshot, WhereFilterOp } from "firebase/firestore";
import { UploadMetadata } from "firebase/storage";

enum Deejay {
  EngagementDJ = "Deejay.EngagementDJ",
  HousePartyDJ = "Deejay.HousePartyDJ",
  WeddingDJ = "Deejay.WeddingDJ",
  CubDJ = "Deejay.CubDJ",
  RadioDJ = "Deejay.RadioDJ",
  FestivalDJ = "Deejay.FestivalDJ",
  DJController = "Deejay.DJController",
}

enum Lighting {
  MovingHead = "Lighting.MovingHead",
  LEDLight = "Lighting.LEDLight",
  UpLight = "Lighting.UpLight",
  Laser = "Lighting.Laser",
  LightController = "Lighting.LightController",
}

enum Effect {
  Firework = "Effect.Firework",
  Flame = "Effect.Flame",
  Co2Gun = "Effect.Co2Gun",
  Co2Jet = "Effect.Co2Jet",
  Co2Tank = "Effect.Co2Tank",
  Fog = "Effect.Fog",
}

enum Visual {
  LEDWall = "Visual.LEDWall",
  Projector = "Visual.Projector",
  Screen = "Visual.Screen",
}

enum Sound {
  SpeakerStand = "Sound.SpeakerStand",
  WirelessMic = "Sound.WirelessMic",
  WiredMic = "Sound.WiredMic",
  SubSpeaker = "Sound.SubSpeaker",
  PoweredSpeaker = "Sound.PoweredSpeaker",
  Monitor = "Sound.Monitor",
  Mixer = "Sound.Mixer",
}

enum Photography {
  WeddingPhotography = "Photography.WeddingPhotography",
  EventPhotography = "Photography.EventPhotography",
  FashionPhotography = "Photography.FashionPhotography",
  DronePhotography = "Photography.DronePhotography",
  ConcertPhotography = "Photography.ConcertPhotography",
  StudioPhotography = "Photography.StudioPhotography",
  PhotoEditing = "Photography.PhotoEditing",
}

enum Videography {
  WeddingVideography = "Videography.WeddingVideography",
  EventVideography = "Videography.EventVideography",
  MusicVideography = "Videography.MusicVideography",
  LifestyleVideography = "Videography.LifestyleVideography",
  SportsVideography = "Videography.SportsVideography",
  DroneVideography = "Videography.DroneVideography",
  VideoEditing = "Videography.VideoEditing",
  SocialMediaVideography = "Videography.SocialMediaVideography",
  TVShowVideography = "Videography.TVShowVideography",
  ConcertVideography = "Videography.ConcertVideography",
}

enum Singer {
  LivePerformance = "Singer.LivePerformance",
  WeddingSinger = "Singer.WeddingSinger",
  BackgroundSinger = "Singer.BackgroundSinger",
  MusicalPlays = "Singer.MusicalPlays",
  Voiceover = "Singer.Voiceover",
  LessonsAndCoaching = "Singer.LessonsAndCoaching",
  OnlinePerformance = "Singer.OnlinePerformance",
  EducationalWorkshops = "Singer.EducationalWorkshops",
  WorshipService = "Singer.WorshipService",
  CruisePerformance = "Singer.CruisePerformance",
  ClubPerformance = "Singer.ClubPerformance",
  BarPerformance = "Singer.BarPerformance",
  TVPerformance = "Singer.TVPerformance",
  ConcertPerformance = "Singer.ConcertPerformance",
  Competitions = "Singer.Competitions",
}

enum Instrumentalist {
  Piano = "Instrumentalist.Piano",
  GuitarAcoustic = "Instrumentalist.GuitarAcoustic",
  GuitarElectric = "Instrumentalist.GuitarElectric",
  GuitarBass = "Instrumentalist.GuitarBass",
  Violin = "Instrumentalist.Violin",
  Drum = "Instrumentalist.Drum",
  Percussion = "Instrumentalist.Percussion",
  Cello = "Instrumentalist.Cello",
  Saxophone = "Instrumentalist.Saxophone",
  Trumpet = "Instrumentalist.Trumpet",
  Flute = "Instrumentalist.Flute",
  Keyboard = "Instrumentalist.Keyboard",
  Accordion = "Instrumentalist.Accordion",
  Sitar = "Instrumentalist.Sitar",
  MusicTeacher = "Instrumentalist.MusicTeacher",
}

enum Conductor {
  OrchestralConductor = "Conductor.OrchestralConductor",
  ChoirConductor = "Conductor.ChoirConductor",
  BandConductor = "Conductor.BandConductor",
}

enum Producer {
  MusicProducer = "Producer.MusicProducer",
  BeatProducer = "Producer.BeatProducer",
  Songwriter = "Producer.Songwriter",
  Arranger = "Producer.Arranger",
  Composer = "Producer.Composer",
}

enum Staff {
  WeddingPlanner = "Staff.WeddingPlanner",
  EventPlanner = "Staff.EventPlanner",
  Valet = "Staff.Valet",
  Security = "Staff.Security",
  Usher = "Staff.Usher",
  Server = "Staff.Server",
  Bartender = "Staff.Bartender",
}

enum Staging {
  DanceFloor = "Staging.DanceFloor",
  Stage = "Staging.Stage",
  Truss = "Staging.Truss",
  LiftForTruss = "Staging.LiftForTruss",
  Podium = "Staging.Podium",
  Platform = "Staging.Platform",
}

enum Designer {
  HairArtist = "Designer.HairArtist",
  MakeupArtist = "Designer.MakeupArtist",
  FashionDesigner = "Designer.FashionDesigner",
  FlyerDesigner = "Designer.FlyerDesigner",
}

enum Decorator {
  EngagementDecoration = "Decorator.EngagementDecoration",
  BirthdayDecoration = "Decorator.BirthdayDecoration",
  WeddingDecoration = "Decorator.WeddingDecoration",
  FoodTableDecoration = "Decorator.FoodTableDecoration",
  AppetizerDecoration = "Decorator.AppetizerDecoration",
  BalloonDecoration = "Decorator.BalloonDecoration",
  FlowerDecoration = "Decorator.FlowerDecoration",
  Backdrop = "Decorator.Backdrop",
  PhotoBooth = "Decorator.PhotoBooth",
  InflatableStructure = "Decorator.InflatableStructure",
}

enum Rental {
  Car = "Rental.Car",
  Tent = "Rental.Tent",
  PhotoBooth360 = "Rental.PhotoBooth360",
  Heater = "Rental.Heater",
  FanAndAC = "Rental.FanAndAC",
  Generator = "Rental.Generator",
  BarEquipment = "Rental.BarEquipment",
  PortableRestroom = "Rental.PortableRestroom",
  Barrier = "Rental.Barrier",
  Stanchion = "Rental.Stanchion",
  Signage = "Rental.Signage",
  ParkingEquipment = "Rental.ParkingEquipment",
}

enum Furniture {
  Table = "Furniture.Table",
  Chair = "Furniture.Chair",
  Tablecloth = "Furniture.Tablecloth",
  CocktailTable = "Furniture.CocktailTable",
  LoungeSeating = "Furniture.LoungeSeating",
}

enum Catering {
  FoodWarmer = "Catering.FoodWarmer",
  Dishes = "Catering.Dishes",
  Utensil = "Catering.Utensil",
  Napkin = "Catering.Napkin",
  Plate = "Catering.Plate",
  Glasses = "Catering.Glasses",
}

enum Performer {
  BalletDancer = "Performer.BalletDancer",
  LatinDancer = "Performer.LatinDancer",
  BallroomDancer = "Performer.BallroomDancer",
  BreakDancer = "Performer.BreakDancer",
  TapDancer = "Performer.TapDancer",
  BellyDancer = "Performer.BellyDancer",
  Aerialist = "Performer.Aerialist",
  TraditionalDancer = "Performer.TraditionalDancer",
  ClubDancer = "Performer.ClubDancer",
  Choreographer = "Performer.Choreographer",
  FestivalDancer = "Performer.FestivalDancer",
  PoleDancer = "Performer.PoleDancer",
  ExoticShow = "Performer.ExoticShow",
  FireDancer = "Performer.FireDancer",
  LEDDancer = "Performer.LEDDancer",
  GlowDancer = "Performer.GlowDancer",
  AcrobaticShow = "Performer.AcrobaticShow",
  StiltPerformer = "Performer.StiltPerformer",
  SynchronizedDancer = "Performer.SynchronizedDancer",
  Magician = "Performer.Magician",
  CaricatureArtist = "Performer.CaricatureArtist",
  Comedian = "Performer.Comedian",
  MC = "Performer.MC",
  Jugglers = "Performer.Jugglers",
}

enum TopLevelServiceType {
  Deejay = "Deejay",
  Lighting = "Lighting",
  Effect = "Effect",
  Visual = "Visual",
  Sound = "Sound",
  Staff = "Staff",
  Photography = "Photography",
  Videography = "Videography",
  Singer = "Singer",
  Instrumentalist = "Instrumentalist",
  Conductor = "Conductor",
  Producer = "Producer",
  Staging = "Staging",
  Designer = "Designer",
  Decorator = "Decorator",
  Rental = "Rental",
  Furniture = "Furniture",
  Catering = "Catering",
  Performer = "Performer",
}

export type ServiceType =
  | TopLevelServiceType
  | Rental
  | Deejay
  | Lighting
  | Effect
  | Visual
  | Sound
  | Photography
  | Videography
  | Singer
  | Conductor
  | Producer
  | Staff
  | Staging
  | Designer
  | Decorator
  | Rental
  | Furniture
  | Catering
  | Performer
  | Instrumentalist;
export const ServiceType = {
  ...TopLevelServiceType,
  ...Rental,
  ...Deejay,
  ...Lighting,
  ...Effect,
  ...Visual,
  ...Sound,
  ...Photography,
  ...Videography,
  ...Singer,
  ...Conductor,
  ...Producer,
  ...Staff,
  ...Staging,
  ...Designer,
  ...Decorator,
  ...Rental,
  ...Furniture,
  ...Catering,
  ...Performer,
  ...Instrumentalist,
};

/**
 * Converts string type to the corresponding enum value
 * @param {string} typeStr type in string
 * @return {ServiceType | undefined} if the typestr doesn't correspond to any
 * type it returns undefined otherwise returns the enum value of the type
 */
export function convertToServiceType(typeStr: string): ServiceType | undefined {
  return ServiceType[typeStr as keyof typeof ServiceType];
}

/**
 * Check the elements of an array are equal
 * @param {T[]} arr1
 * @param {T[]} arr2
 * @return {boolean}
 */
export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((value, index) => value === arr2[index]);
}

export type Contact = {
  emails: string[];
  phones: string[];
};

export type GeoLocation = {
  latitude: number;
  longitude: number;
  geoHash?: string;
};

export type GeoLocationInput = {
  latitude: number;
  longitude: number;
};

export type Address = {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

export type UpdateAddress = {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

export enum SponsorStatus {
  None,
  Gold,
  Platinum,
}

export type Event = {
  id: string;
  title: string;
  imageUrl?: string;
  description?: string;
  startTimestamp: number;
  endTimestamp?: number;
  sponsorStatus: SponsorStatus;
  ticketLink?: string;
  tags?: string[];
  location?: GeoLocation;
  contact?: Contact;
  venue?: EntityStub;
  hosts?: EntityStub[];
  artists?: EntityStub[];
  address?: Address;
  createdAt?: number;
  date?: string;
  minAgeRange?: number;
  maxAgeRange?: number;
  leadId?: string;
  ownerId: string;
};

export type CreateEventInput = {
  id?: string;
  title: string;
  imageUrl?: string;
  description?: string;
  startTimestamp: number;
  endTimestamp?: number;
  address?: Address;
  location?: GeoLocationInput;
  ticketLink?: string;
  tags?: string[];
  contact?: Contact;
  venue?: EntityStub;
  hosts?: EntityStub[];
  minAgeRange?: number;
  maxAgeRange?: number;
  artists?: EntityStub[];
  leadId?: string;
};

export type UpdateEventInput = {
  id: string;
  title?: string;
  imageUrl?: string;
  description?: string;
  startTimestamp?: number;
  endTimestamp?: number;
  sponsorStatus?: SponsorStatus;
  address?: UpdateAddress;
  ticketLink?: string;
  tags?: string[];
  location?: GeoLocation;
  contact?: Contact;
  venue?: EntityStub;
  minAgeRange?: number;
  maxAgeRange?: number;
  hosts?: EntityStub[];
  artists?: EntityStub[];
};

export type GetObjectInput = {
  id: string;
};

export type DeleteObjectInput = {
  id: string;
};

export type CreateObjectInput = {
  id?: string;
};

export type OwnedObject = {
  ownerId: string;
};

export type UpdateObjectInput = {
  id: string;
  startTimestamp?: number;
};

export type WhereStatement = {
  field: string;
  operation: WhereFilterOp;
  value: string | number;
};

export type OrStatement = {
  whereFilters: WhereStatement[];
};

export type QueryInput = {
  andStatements: OrStatement[];
};

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export type SortField = {
  field: string;
  direction: SortDirection;
};

export type ListQueryInput = {
  queryInput?: QueryInput;
  sortBy?: SortField;
  limit?: number;
  nextToken?: QueryDocumentSnapshot;
};

export type ListNearByQueryInput = {
  queryInput?: QueryInput;
  center: GeoLocationInput;
  radiusInKiloMeter: number;
};

export type ModelResponse<ObjectType> = {
  items: Array<ObjectType>;
  nextToken?: QueryDocumentSnapshot;
};

export enum XUserStatus {
  ScamLikely = "ScamLikely",
  VerificationRequested = "VerificationRequested",
  Banned = "Banned",
  Active = "Active",
  PendingReview = "PendingReview",
  Verified = "Verified",
}

export type XUser = {
  id: string;
  isAdmin: boolean;
  gender?: string;
  birthday?: string;
  status?: XUserStatus;
  locations?: string[];
  identityInfo?: {
    idOrPassportImage: string;
    selfie: string;
    isHuman: boolean;
  };
  createdAt: number;
  ownerId: string;
};

export type CreateXUserInput = {
  id: string;
  gender?: string;
  birthday?: string;
};

export type UpdateXUserInput = {
  id: string;
  isAdmin?: boolean;
  gender?: string;
  birthday?: string;
  isIdVerified?: boolean;
  locations?: string[];
  identityInfo?: {
    idOrPassportImage?: string;
    selfie?: string;
  };
};

export type UpdateUserInput = {
  name?: Name;
  photoURL?: string;
  email?: string;
  password?: string;
};

export type Name = {
  firstName: string;
  lastName: string;
  middleName?: string;
};

export type SocialMedia = {
  url: string;
  isPinned?: boolean;
};

export type DepositPolicy = {
  required: boolean;
  // Amount required as a deposit
  amount?: number;
  // Percentage of total amount required as a deposit
  percentage?: number;
};

export type DiscountPolicy = {
  // Amount required as a discount
  amount?: number;
  // Percentage of total amount required as a discount
  percentage?: number;
};

export type CancellationPolicy = {
  refundable: boolean;
  // Percentage of refund if canceled
  refundPercentage?: number;
  // Number of days before event for cancellation
  cancellationDeadline?: number;
};

export type BookingPolicy = {
  depositPolicy?: DepositPolicy;
  cancellationPolicy?: CancellationPolicy;
  // Minimum number of hours required per booking
  minimumBookingHours?: number;
  // Maximum number of hours allowed per booking
  maximumBookingHours?: number;
};

export enum VendorType {
  Vendor = "Vendor",
  Venue = "Venue",
}

export enum BadgeType {
  None = "None",
  // Red Badge: for vendors who are partner with IplanX
  IplanX = "IplanX",
  // Gold Badge: for world-famous vendors/artists (e.g., Calvin Harris)
  Wellknown = "Wellknown",
  // Green or Blue Badge: for vendors that their business document is verified
  BusinessVerified = "BusinessVerified",
  // White Badge: for members they pay for the iPlanX membership fee
  // (e.g., booking, contract generation, etc.)
  PaidMember = "PaidMember",
}

export type Vendor = {
  id: string;
  username: string;
  displayName: string;
  headline: string;
  badge?: BadgeType;
  imageUrl?: string;
  description?: string;
  sponsorStatus: SponsorStatus;
  location?: GeoLocation;
  address?: Address;
  contact?: Contact;
  audience?: string[];
  serviceTypes?: ServiceType[];
  socialMedia?: SocialMedia[];
  createdAt?: number;
  bookingPolicy?: BookingPolicy;
  vendorType?: VendorType;
  ownerId: string;
};

export type CreateVendorInput = {
  id?: string;
  username: string;
  displayName: string;
  headline: string;
  imageUrl?: string;
  description?: string;
  address?: Address;
  location?: GeoLocation;
  contact?: Contact;
  audience?: string[];
  vendorType?: VendorType;
  socialMedia?: SocialMedia[];
};

export type UpdateVendorInput = {
  id: string;
  username?: string;
  displayName?: string;
  headline?: string;
  imageUrl?: string;
  description?: string;
  sponsorStatus?: SponsorStatus;
  location?: GeoLocation;
  address?: UpdateAddress;
  contact?: Contact;
  audience?: string[];
  socialMedia?: SocialMedia[];
};

export enum VenueType {
  Club = "Club",
  NightClub = "NightClub",
  DayClub = "DayClub",
  ConcertHall = "ConcertHall",
  Cruise = "Cruise",
  Hotel = "Hotel",
  House = "House",
  Mansion = "Mansion",
  Museum = "Museum",
  Park = "Park",
  Restaurant = "Restaurant",
  School = "School",
  Studio = "Studio",
  Theater = "Theater",
  Virtual = "Virtual",
  Banquet = "Banquet",
  Lounge = "Lounge",
  Ballroom = "Ballroom",
  Bar = "Bar",
  Church = "Church",
}

export type Venue = Vendor & {
  capacity: number;
  venueType: VenueType;
  // e.g., WIFI, PARKING, RESTROOMS
  amenities: string[];
  rules?: string[];
  squareFootage?: number;
  outdoorSquareFootage?: number;
  parkingCapacity?: number;
  documents?: string[];
};

export type CreateVenueInput = CreateVendorInput & {
  capacity: number;
  venueType: VenueType;
  amenities: string[];
  rules?: string[];
  squareFootage?: number;
  outdoorSquareFootage?: number;
  parkingCapacity?: number;
  documents?: string[];
};

export type UpdateVenueInput = UpdateVendorInput & {
  capacity?: number;
  venueType?: VenueType;
  amenities?: string[];
  rules?: string[];
  squareFootage?: number;
  outdoorSquareFootage?: number;
  parkingCapacity?: number;
  documents?: string[];
};

export type ChatMember = {
  memberId: string;
  type: EntityType;
  displayName: string;
  lastReadTime: number;
  lastWriteTime: number;
};

export type CreateChatMember = {
  memberId: string;
  type: EntityType;
};

export type UpdateChatMember = {
  lastReadTime?: number;
  lastWriteTime?: number;
};

export type Chat = {
  id: string;
  title: string;
  client: ChatMember;
  provider: ChatMember;
  // only used for security purposes and maintained by backend
  authorizedUid?: string[];
  // timestamp of the last update or new message in this chat
  updatedAt: number;
  createdAt: number;
  ownerId: string;
};

export enum EntityType {
  User = "USER",
  Vendor = "VENDOR",
  Chat = "CHAT",
  Venue = "VENUE",
}
/**
 * Returns collection name corresponding to the entity.
 * @param {EntityType} entity
 * @return {string} collection name
 */
export function getCollectionName(entity: EntityType): string {
  /* eslint-disable indent */
  switch (entity) {
    case EntityType.User:
      return "users";
    case EntityType.Vendor:
      return "vendors";
    case EntityType.Chat:
      return "chats";
    case EntityType.Venue:
      return "venues";
    default:
      return "unknown";
  }
  /* eslint-enable indent*/
}

export type CreateChatInput = {
  id?: string;
  title: string;
  client: CreateChatMember;
  provider: CreateChatMember;
};

export type UpdateChatInput = {
  id: string;
  title?: string;
  client?: UpdateChatMember;
  provider?: UpdateChatMember;
};

export type Message = {
  id: string;
  text: string;
  senderId: string;
  isDeleted: boolean;
  createdAt: number;
};

export type CreateMessageInput = {
  id?: string;
  text: string;
  senderId: string;
};

export type UpdateMessageInput = {
  id: string;
  text?: string;
  isDeleted?: boolean;
};

export type Notification = {
  id: string;
  notifier: EntityStub;
  url: string;
  message: string;
  createTimestamp: string;
  sean: boolean;
  ownerId: string;
};
export type CreateNotificationInput = {
  id?: string;
  notifier: EntityStub;
  url: string;
  message: string;
};

export type UpdateNotificationInput = {
  id: string;
  notifier?: EntityStub;
  url?: string;
  message?: string;
  sean?: boolean;
};

export type UploadFileInput = {
  directoryName: string;
  fileName: string;
  file: File | Uint8Array;
  metadata?: UploadMetadata;
};

export type EntityStub = {
  id: string;
  type: EntityType;
  displayName?: string;
  imageUrl?: string;
};

export type Highlight = {
  id: string;
  order: number;
  title: string;
  subtitle?: string;
  imageUrl?: string;
  url: string;
  ownerId: string;
};

export type CreateHighlightInput = {
  id?: string;
  order: number;
  title: string;
  subtitle?: string;
  imageUrl?: string;
  url: string;
};

export type UpdateHighlightInput = {
  id: string;
  order?: number;
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  url?: string;
};

export type Endorsement = {
  id: string;
  endorser: EntityStub;
  ownerId: string;
};

export type CreateEndorsementInput = {
  id?: string;
  endorser: EntityStub;
};

export enum EventType {
  Anniversary = "Anniversary",
  ArtShows = "ArtShows",
  Club = "Club",
  Corporate = "Corporate",
  Concert = "Concert",
  Cruise = "Cruise",
  Engagement = "Engagement",
  Festival = "Festival",
  Fundraiser = "Fundraiser",
  Funeral = "Funeral",
  Graduation = "Graduation",
  HouseParty = "HouseParty",
  Nonprofit = "Nonprofit",
  Other = "Other",
  Virtual = "Virtual",
  Wedding = "Wedding",
}

// State Machine: https://drive.google.com/file/d/1O4H0S7BXCcrNzTwmM7UtVq8X_D6HYUcn/view?usp=drive_link
export enum LeadStatus {
  Incomplete = "Incomplete",
  New = "New", // User Action
  PendingReview = "PendingReview", // Vendor Action
  PendingRevision = "PendingRevision", // User Action
  PendingSignature = "PendingSignature", // User Action
  PendingDeposit = "PendingDeposit", // User Action
  PendingFinalPayment = "PendingFinalPayment", // User Action
  Rejected = "Rejected", // User Vendor Action
  Completed = "Completed", // Automated action
  Expired = "Expired", // Automated action
}

export type LeadHistory = {
  status?: LeadStatus;
  message?: string;
  timestamp: number;
};

export enum ConversionType {
  Inquiry = "Inquiry",
  Private = "Private",
  Public = "Public",
}

export type Lead = {
  id: string;
  eventType: EventType;
  leadStatus: LeadStatus;
  startTime: number;
  endTime: number;
  eventTitle: string;
  guests: number;
  minAgeRange?: number;
  maxAgeRange?: number;
  budget: number;
  venue?: EntityStub;
  address?: Address;
  contact?: Contact;
  name?: Name;
  description?: string;
  createdAt?: number;
  ownerId: string;
  inquiryRecipient?: EntityStub;
  recipientOwnerId?: string;
  history?: LeadHistory[];
  services?: ServiceStub[];
  totalPrice?: number;
  conversionType?: ConversionType;
  depositPolicy?: DepositPolicy;
  cancellationPolicy?: CancellationPolicy;
  discountPolicy?: DiscountPolicy;
};

export type CreateLeadInput = {
  id?: string;
  eventType: EventType;
  leadStatus: LeadStatus;
  startTimestamp: number;
  endTimestamp: number;
  eventTitle: string;
  guests: number;
  minAgeRange?: number;
  maxAgeRange?: number;
  budget?: number;
  venue?: EntityStub;
  address?: Address;
  contact?: Contact;
  name?: Name;
  description?: string;
  inquiryRecipient?: EntityStub;
  services?: ServiceStub[];
  totalPrice?: number;
  depositPolicy?: DepositPolicy;
  cancellationPolicy?: CancellationPolicy;
  discountPolicy?: DiscountPolicy;
};

export type UpdateLeadInput = {
  id: string;
  eventType?: EventType;
  leadStatus?: LeadStatus;
  startTimestamp?: number;
  endTimestamp?: number;
  eventTitle?: string;
  guests?: number;
  minAgeRange?: number;
  maxAgeRange?: number;
  budget?: number;
  venue?: EntityStub;
  address?: Address;
  contact?: Contact;
  name?: Name;
  description?: string;
  inquiryRecipient?: EntityStub;
  services?: ServiceStub[];
  totalPrice?: number;
  depositPolicy?: DepositPolicy;
  cancellationPolicy?: CancellationPolicy;
  discountPolicy?: DiscountPolicy;
};

export type Service = {
  id: string;
  serviceType: ServiceType;
  title: string;
  description?: string;
  quantity?: number;
  price?: number;
  createdAt?: number;
  ownerId: string;
};

export type ServiceStub = {
  id: string;
  serviceType: ServiceType;
  title: string;
  description?: string;
  quantity?: number;
  price?: number;
};

/**
 * Populates the content of newService in the existing service stub.
 * @param {ServiceStub} existing
 * @param {Service} newService
 */
export function populateServiceStub(
  existing: ServiceStub,
  newService: Service,
) {
  existing.title = newService.title;
  existing.serviceType = newService.serviceType;
  if (newService.description) {
    existing.description = newService.description;
  }
}

export type CreateServiceInput = {
  id?: string;
  serviceType: ServiceType;
  isPinned?: boolean;
  title: string;
  description?: string;
};

export type UpdateServiceInput = {
  id: string;
  serviceType?: ServiceType;
  isPinned?: boolean;
  title?: string;
  description?: string;
};

export type Discount = {
  amount: number;
  percentage: number;
};

export type Package = {
  id: string;
  title: string;
  eventType: EventType;
  services: ServiceStub[];
  serviceIds?: string[];
  discount?: Discount;
  ownerId: string;
};

export type CreatePackageInput = {
  id?: string;
  title: string;
  eventType: EventType;
  services?: ServiceStub[];
  discount?: Discount;
};

export type UpdatePackageInput = {
  id: string;
  title?: string;
  eventType?: EventType;
  services?: ServiceStub[];
  discount?: Discount;
};

export type Follow = {
  entity: EntityStub;
  ownerId: string;
  createdAt: number;
};

import { useMutation } from "@tanstack/react-query";
import XConfirmationModal from "components/XConfirmationModal";
import { deleteServiceAPI } from "shared/service/Service";

interface ArtistPackageDeleteModalProps {
  onSuccess: () => void;
  onClose: () => void;
  packageId: string;
  vendorId: string;
}

const ArtistPackageDeleteModal = ({
  onSuccess,
  onClose,
  packageId,
  vendorId,
}: ArtistPackageDeleteModalProps) => {
  const { mutate } = useMutation({
    mutationKey: ["ArtistPackageDeleteModal.delete", packageId],
    mutationFn: deleteServiceAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to delete the service: ", error);
    },
  });

  return (
    <XConfirmationModal
      title="Delete Service"
      message="Are you sure you want to delete this service?"
      onConfirm={() =>
        mutate({
          vendorId: vendorId,
          service: { id: packageId },
        })
      }
      onClose={onClose}
    />
  );
};

export default ArtistPackageDeleteModal;

export type MutationType =
  | "create"
  | "update"
  | "delete"
  | "publish"
  | "convert";

const capitalizeWord = (word: string): string => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const generateMutationMessage = (
  entity: string,
  mutationType: MutationType,
  isSuccess: boolean,
): string => {
  const entityName = capitalizeWord(entity);

  const operationMessages: Record<
    MutationType,
    { success: string; failure: string }
  > = {
    create: {
      success: `${entityName} has been successfully created.`,
      failure: `Failed to create ${entityName}. Please try again or contact support.`,
    },
    update: {
      success: `${entityName} has been successfully updated.`,
      failure: `Failed to update ${entityName}. Please try again or contact support.`,
    },
    delete: {
      success: `${entityName} has been successfully deleted.`,
      failure: `Failed to delete ${entityName}. Please try again or contact support.`,
    },
    publish: {
      success: `${entityName} has been successfully published.`,
      failure: `Failed to publish ${entityName}. Please try again or contact support.`,
    },
    convert: {
      success: `${entityName} has been successfully converted.`,
      failure: `Failed to convert ${entityName}. Please try again or contact support.`,
    },
  };

  const messages = operationMessages[mutationType];
  return isSuccess ? messages.success : messages.failure;
};

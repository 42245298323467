import { iLink } from "./common/XIcons";

interface XHighlightProps {
  title: string;
  subtitle?: string;
  imageURL?: string;
}

export const XHighlight = ({ imageURL, title, subtitle }: XHighlightProps) => {
  return (
    <>
      <img
        src={imageURL}
        className="w-[48px] h-[48px] lg:w-[86px] lg:h-[86px] rounded-[100%] transition-transform duration-300 ease-in-out transform"
      />
      <div className="flex flex-col flex-1 justify-center">
        <div className="text-2xs lg:text-md font-medium">{title}</div>
        <div className="text-3xs lg:text-2xs text-white/70 font-light">
          {subtitle}
        </div>
      </div>
      <img src={iLink} className="w-[16px] md:w-[20px]" />
    </>
  );
};

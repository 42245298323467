import React, { useMemo, useRef } from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { useField } from "formik";
import { ServiceType } from "shared/API";
import {
  lowkeyDisplayNameMapping,
  transformServiceTypes,
} from "utils/ServiceType";
import { iDown } from "components/common/XIcons";

type ServiceOption = {
  value: string;
  label: string;
  group: string;
};

type XFServiceFieldProps = {
  label: string;
  name: string;
};

const XFServiceField = (props: XFServiceFieldProps) => {
  const [menuOpen, setMenuOpen] = React.useState(false); // Track menu open state
  const [field, meta, helpers] = useField(props);
  const hasError = meta.error && meta.touched;
  const { setValue } = helpers;
  const inputRef = useRef<HTMLInputElement>(null);

  const serviceData = transformServiceTypes(ServiceType);

  const options: ServiceOption[] = useMemo(() => {
    const result: ServiceOption[] = [];
    Object.keys(serviceData).forEach((category) => {
      serviceData[category].forEach((subService) => {
        const value = `${category}.${subService}`;
        result.push({
          value,
          label: lowkeyDisplayNameMapping[subService] || subService,
          group: category,
        });
      });
    });
    return result;
  }, []);

  const selectedOption = useMemo(
    () => options.find((option) => option.value === field.value) || null,
    [field.value, options]
  );

  const handleServiceSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: ServiceOption | null
  ) => {
    setValue(value?.value || null);
    inputRef.current?.blur(); // Blur the input after selection
  };

  return (
    <div className="flex flex-col flex-1 gap-3">
      <label className="text-md">{props.label}</label>
      <Autocomplete
        options={options}
        groupBy={(option) => option.group}
        getOptionLabel={(option) =>
          selectedOption && selectedOption.value === option.value
            ? `${option.group}  ›  ${option.label}`
            : option.label
        }
        onOpen={() => setMenuOpen(true)} // Set open state to true
        onClose={() => setMenuOpen(false)} // Set open state to false
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="outlined"
            error={!!hasError}
            helperText={hasError ? meta.error : ""}
            placeholder="Select a service type"
            sx={{
              "& .MuiInputLabel-root": {
                px: 1,
                color: "rgba(255,255,255,0.4)",
                borderWidth: 1,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "60px",
                borderColor: "rgba(255,255,255,0.2)",
                px: 3,
                borderWidth: 1,
                borderBottom: menuOpen ? 0 : "1px",
                borderBottomLeftRadius: menuOpen ? 0 : "60px",
                borderBottomRightRadius: menuOpen ? 0 : "60px",
                borderTopLeftRadius: menuOpen ? "24px" : "60px",
                borderTopRightRadius: menuOpen ? "24px" : "60px",
                background: "#000",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderBottomWidth: menuOpen ? 0 : "1px",
                  borderBottomLeftRadius: menuOpen ? 0 : "60px",
                  borderBottomRightRadius: menuOpen ? 0 : "60px",
                  borderTopLeftRadius: menuOpen ? "24px" : "60px",
                  borderTopRightRadius: menuOpen ? "24px" : "60px",
                  borderColor: "rgba(255,255,255,0.7)",
                  borderWidth: "1px",
                  borderBottom: menuOpen ? 0 : "1px",
                },
            }}
          />
        )}
        value={selectedOption}
        onChange={handleServiceSelect}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        filterOptions={createFilterOptions({
          stringify: (option) => `${option.label} ${option.group}`,
        })}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.value}
            className="hover:bg-x-gray3 px-6 py-2 cursor-pointer mx-2 rounded-lg"
          >
            {option.label}
          </li>
        )}
        renderGroup={(params) => {
          const count = Array.isArray(params.children)
            ? params.children.length
            : 0;

          return (
            <div key={params.key}>
              <div className="flex justify-between items-center bg-black text-primary text-sm sticky top-0 z-1 px-6 py-2.5">
                <span>{params.group}</span>
                <span>{count}</span>
              </div>
              <div>{params.children}</div>
            </div>
          );
        }}
        PaperComponent={(props) => (
          <div
            {...props}
            className="bg-black border border-t-0 border-white/70 rounded-bl-lg rounded-br-lg"
          />
        )}
        popupIcon={<img src={iDown} />}
        classes={{
          inputRoot: "text-heading1",
          groupLabel: "text-xs uppercase text-primary",
          option: "text-xs",
          listbox: "pt-0", // Tailwind class to remove padding at the top
          endAdornment: "right-[24px]",
        }}
      />
    </div>
  );
};

export default XFServiceField;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

interface Slide {
  title: string;
  subtitle: string;
  description: string;
  image: string;
}

interface XSlidesProps {
  items: Slide[];
  duration?: number;
  transitionDuration?: number;
}

const XSlides: React.FC<XSlidesProps> = ({
  items,
  duration = 2000,
  transitionDuration = 0.8,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1,
      );
    }, duration);

    return () => clearInterval(interval);
  }, [items.length, duration]);

  // Handle drag end to switch slides
  const handleDragEnd = (event: MouseEvent | TouchEvent, info: any) => {
    const threshold = 50; // Adjust this value as needed
    const offsetY = info.offset.y;

    if (offsetY < -threshold) {
      // Dragged up: go to next slide
      setCurrentIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1,
      );
    } else if (offsetY > threshold) {
      // Dragged down: go to previous slide
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? items.length - 1 : prevIndex - 1,
      );
    }
  };

  return (
    <div className="relative w-full h-[500px] overflow-hidden my-[40px]">
      <div className="absolute top-0 left-0 w-full h-full">
        {items.map((slide, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={currentIndex === index ? { opacity: 1 } : {}}
            exit={{ opacity: 0 }}
            transition={{ duration: transitionDuration, ease: "easeInOut" }}
            drag={currentIndex === index ? "y" : false}
            dragConstraints={{ top: 0, bottom: 0 }}
            onDragEnd={currentIndex === index ? handleDragEnd : undefined}
            className="absolute w-full h-full flex bg-black text-white border border-x-gray2 rounded-md"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(${slide.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="max-w-2xl p-6">
              <h5 className="absolute top-[30px] left-[40px] text-lg font-medium text-white">
                {slide.subtitle}
              </h5>
              <div className="absolute bottom-[30px] left-[40px] flex flex-col gap-4 max-w-[600px]">
                <h5 className="text-xl font-normal m-0">{slide.title}</h5>
                <p className="text-xs text-x-heading2">{slide.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      <div className="absolute top-1/2 right-4 transform -translate-y-1/2 flex flex-col gap-y-1">
        {items.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-[2px] cursor-pointer rounded-[30px] ${
              currentIndex === index
                ? "bg-white h-[42px]"
                : "bg-x-gray1 h-[14px]"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default XSlides;

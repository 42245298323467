import { XFForm, XFRow, XFTextField, XFUpload } from "components/form";
import { Formik, FormikValues } from "formik";
import { schemaArtistCreate } from "utils/validation/schema";
import { createVendorAPI } from "shared/service/Vendor";
import { useMutation } from "@tanstack/react-query";
import { CreateVendorInput } from "shared/API";
import XModal from "components/XModal";
import { useSnackbar } from "hooks/useSnackbar";
import { generateMutationMessage } from "utils/generateMutationMessage";

interface ArtistCreateModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const initialValues = {
  username: "",
  displayName: "",
  headline: "",
  types: [],
  image: "",
};

const ArtistCreateModal = ({ onSuccess, onClose }: ArtistCreateModalProps) => {
  const { showSnackbar } = useSnackbar();
  const { mutate } = useMutation({
    mutationKey: ["ArtistCreateModal.createVendor"],
    mutationFn: createVendorAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage("Artist", "create", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Artist", "create", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues) => {
    const vendor: CreateVendorInput = {
      username: values.username,
      displayName: values.displayName,
      address: {
        postalCode: values.base,
      },
      headline: values.headline,
    };
    mutate({ vendor, image: values?.image });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaArtistCreate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <XModal
          title="Create Artist"
          onClose={onClose}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFRow>
                <XFTextField label="Username" name="username" />
                <XFTextField label="Display Name" name="displayName" />
              </XFRow>
              <XFUpload
                label="Profile Picture"
                name="image"
                onChange={(file) => {
                  setFieldValue("image", file);
                }}
              />
              <XFTextField label="Headline" name="headline" />
              <XFTextField label="Based in" name="base" />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default ArtistCreateModal;

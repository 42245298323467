import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import { ReactNode } from "react";

type XDragSortContextProps = {
  onDragEnd: (event: DragEndEvent) => void;
  allItems: any[];
  children: ReactNode;
};

const XDragSortContext = ({
  onDragEnd,
  allItems,
  children,
}: XDragSortContextProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <SortableContext items={allItems}>{children}</SortableContext>
    </DndContext>
  );
};

export default XDragSortContext;

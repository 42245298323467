import XCardsGrid from "components/cards/XCardGrid";
import {
  iAiRed,
  iCalendarRed,
  iChatRed,
  iEndorseRed,
  iHighlightRed,
  iInsightsRed,
  iLinksRed,
  iRecordRed,
  iServicesRed,
} from "components/common/XIcons";
import { XFButton } from "components/form";
import Page from "components/Page";
import XCarousel, { XCarouselItem } from "components/XCarousel";
import XSection from "components/XSection";
import XSlides from "components/XSlides";
import useBodyBackground from "hooks/useBodyBackground";

const HomePage = () => {
  useBodyBackground({
    imageUrl: "/images/iplanx-home.jpg",
  });

  const homeCards = [
    {
      icon: <img src={iHighlightRed} />,
      title: "Highlights",
      description: "Showcase your latest music, service, or product with ease.",
    },
    {
      icon: <img src={iCalendarRed} />,
      title: "Upcoming Events",
      description: "Keep your audience informed about upcoming performances.",
    },
    {
      icon: <img src={iLinksRed} />,
      title: "Social Links",
      description:
        "Centralize all social media and streaming links in one place.",
    },
    {
      icon: <img src={iServicesRed} />,
      title: "Services",
      description:
        "List service offerings and attract potential bookings effortlessly.",
    },
    {
      icon: <img src={iEndorseRed} />,
      title: "Endorsements",
      description: "Acquire validation from esteemed artists to build trust.",
    },
    {
      icon: <img src={iInsightsRed} />,
      title: "Insights",
      description: "Gain data-driven guidance to optimize engagement strategy.",
    },
  ];

  const slideItems = [
    {
      title: "Instant Contract Generation",
      subtitle: "Contract Management",
      description:
        "Easily customize, generate, revise, and e-sign contracts using built-in templates, ensuring a seamless and efficient agreement process.",
      image: "/images/slides-contract-management.jpg",
    },
    {
      title: "Secure Payments",
      subtitle: "Safe & Reliable Transactions",
      description:
        "Ensure smooth, secure payments for bookings, deposits, and services with our trusted transaction partners.",
      image: "/images/slides-secure-payments.jpg",
    },
    {
      title: "From Inquiry To Event",
      subtitle: "Lead Optimization",
      description:
        "Manage inquiries, secure deposits, and seamlessly convert leads into successful events with our streamlined process.",
      image: "/images/slides-lead-optimization.jpg",
    },
    {
      title: "Personalized Growth",
      subtitle: "Data-Driven Marketing Strategies",
      description:
        "Leverage booking and audience insights to create tailored marketing strategies to drive business growth.",
      image: "/images/slides-personalized-growth.jpg",
    },
  ];

  const carouselItems: XCarouselItem[] = [
    {
      description:
        "iPlanX empowers you to streamline bookings, expand your audience, and seamlessly manage events, contracts, and leads—all while securing the vendors you need in minutes.",
      type: "text",
      width: 300,
    },
    {
      title: "Artists & Live Performers",
      subtitle:
        "Optimize your bookings and expand your audience with the Artist Hub.",
      image: "/images/carousel-artists-live-performers.jpg",
      width: 640,
    },
    {
      title: "Event Planners & Vendors",
      subtitle:
        "Easily discover and secure all the vendors you need for your event in a matter of minutes.",
      image: "/images/carousel-event-planners-vendors.jpg",
      width: 640,
    },
    {
      title: "Management Agencies",
      subtitle:
        "Efficiently manage your artists' events, contracts, and leads—all in one platform.",
      image: "/images/carousel-management-agencies.jpg",
      width: 640,
    },
  ];

  return (
    <Page>
      <div className="flex flex-col py-[12%] text-center items-center">
        <h1 className="flex flex-col text-xxl mt-0 mb-[50px] leading-[50px] gap-1">
          <span className="font-extralight">Planning</span>
          <span className="font-medium">Redefined</span>
        </h1>
        <XFButton title="Coming soon..." />
        <p className="mt-[130px]">
          Take control of your online presence and maximize your revenue with an{" "}
          <b>all-in-one</b> platform featuring an exclusive landing page and
          streamlined booking tools, designed to optimize campaigns and{" "}
          <b>reduce traditional advertising and management expenses</b>.
        </p>
      </div>

      <XCarousel items={carouselItems} />

      <div className="flex flex-col gap-[120px]">
        <XSection
          title={
            <>
              <span className="font-bold">The Spotlight</span> Awaits
            </>
          }
          subtitle="Providing a home for talents to highlight their services and connect
          with opportunities."
        >
          <XCardsGrid cards={homeCards} imageURL="/images/iplanx-iphone.png" />
        </XSection>

        <XSection
          title={
            <>
              <span className="font-bold">All-in-One</span> Event & Booking
              Platform
            </>
          }
          subtitle="From lead capture to post-event sales, we deliver a complete event lifecycle, ensuring seamless execution and maximized profitability."
        >
          <XSlides items={slideItems} duration={8000} transitionDuration={1} />
        </XSection>

        <XSection
          title={
            <span className="font-medium">
              Optimize Bookings & Audience Engagement
            </span>
          }
          topTitle="AI Revolution in Entertainment"
          align="left"
        >
          <div className="flex items-center gap-[60px]">
            <div className="flex flex-col gap-10">
              <div className="flex items-start gap-6">
                <img src={iAiRed} />
                <div className="flex flex-col gap-1">
                  <h4 className="text-lg font-medium m-0">
                    AI-Powered Marketing
                  </h4>
                  <p>
                    Utilize AI-driven insights to refine targeting, optimize
                    outreach, and boost campaign performance.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-6">
                <img src={iChatRed} />
                <div className="flex flex-col gap-1">
                  <h4 className="text-lg font-medium m-0">
                    Automated Sales & Negotiation
                  </h4>
                  <p>
                    Streamline deal-making with AI-assisted pricing, automated
                    negotiation, and seamless communication.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-6">
                <img src={iRecordRed} />
                <div className="flex flex-col gap-1">
                  <h4 className="text-lg font-medium m-0">
                    Smart Recommendations
                  </h4>
                  <p>
                    Leverage data-driven insights to drive audience engagement
                    and increase post-booking revenue opportunities.
                  </p>
                </div>
              </div>
            </div>
            <img src="/images/sample-graph.png" />
          </div>
        </XSection>

        <div className="text-center mb-[80px]">
          <h4 className="text-md font-light mt-0 mb-2">
            Your Talent, Our Technology
          </h4>
          <h3 className="text-xl font-light mt-0 mb-6">
            Let’s Redefine Success!
          </h3>
          <p className="text-sm font-light mt-0 mb-12">
            Boost your online presence and revenue with an all-in-one platform
            featuring
            <br /> an exclusive landing page and streamlined booking tools.
          </p>
          <div className="flex justify-center">
            <XFButton title="Coming soon..." />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default HomePage;

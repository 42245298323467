import { validateEventInput } from "shared/service/utils/InputValidator";
import {
  CreateEventInput,
  DeleteObjectInput,
  Event,
  GetObjectInput,
  ListQueryInput,
  UpdateEventInput,
  ModelResponse,
  ListNearByQueryInput,
} from "shared/API";
import {
  FIREBASE_ANALYTICS,
  FIREBASE_AUTH,
  FIREBASE_FUNCTIONS,
  LOGGER,
} from "shared/Firebase";
import {
  IsImageValid,
  getFileDirectory,
  getPictureType,
} from "shared/service/utils/ImageUtil";
import { getFileURL, saveImage } from "shared/service/Storage";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  getFirestoreObjectById,
  listFirestoreObjects,
  listNearbyObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { XStatus } from "shared/service/utils/XStatus";
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";

const EVENT_COLLECTION = "events";
const PRIVATE_EVENT_COLLECTION = "privateevents";

export async function eventsAPI(input: {
  query: ListQueryInput;
  isPrivate?: boolean;
}): Promise<ModelResponse<Event>> {
  return listFirestoreObjects<Event>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input,
  );
}

export async function eventByIdAPI(input: {
  event: GetObjectInput;
  isPrivate?: boolean;
}): Promise<Event> {
  return getFirestoreObjectById<Event>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
  );
}

export async function eventsNearbyAPI(input: {
  isPrivate?: boolean;
  query: ListNearByQueryInput;
}): Promise<Event[]> {
  return listNearbyObjects<Event>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.query,
  );
}

export async function createEventAPI(input: {
  event: CreateEventInput;
  image?: File;
}): Promise<string> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  const eventId = await createFirestoreObjectPrivileged<CreateEventInput>(
    PRIVATE_EVENT_COLLECTION,
    input.event,
    validateEventInput,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    LOGGER!.info(input);
    const upload_result = await saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: `${eventId}.${getPictureType(input.image)}`,
      file: input.image,
    });
    {
      input.event.imageUrl = await getFileURL(upload_result.ref.fullPath);
      await updateEventAPI({
        event: { id: eventId, imageUrl: input.event.imageUrl },
        isPrivate: true,
      });
    }
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "event_creation", { uid });
  }
  return eventId;
}

export async function deleteEventAPI(input: {
  event: DeleteObjectInput;
  isPrivate?: boolean;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "event_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
  );
}

export async function updateEventAPI(input: {
  event: UpdateEventInput;
  image?: File;
  isPrivate?: boolean;
}): Promise<void> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  await updateFirestoreObject<UpdateEventInput>(
    input.isPrivate ? PRIVATE_EVENT_COLLECTION : EVENT_COLLECTION,
    input.event,
    validateEventInput,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    const upload_result = await saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: `${input.event.id}.${getPictureType(input.image)}`,
      file: input.image,
    });
    {
      input.event.imageUrl = await getFileURL(upload_result.ref.fullPath);
      await updateEventAPI({
        event: { id: input.event.id, imageUrl: input.event.imageUrl },
        isPrivate: input.isPrivate,
      });
    }
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "event_update", { uid });
  }
}

export async function publishEventAPI(input: { eventId: string }) {
  const publishEvent = httpsCallable(FIREBASE_FUNCTIONS!, "publishEvent");

  await publishEvent({ eventId: input.eventId! })
    .then(() => {
      expect(true);
    })
    .catch(() => {
      expect(false);
    });
}

import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./styles/tailwind.css";
import { createBrowserRouter, RouterProvider } from "react-router";
import { initializeFirebase } from "shared/Firebase";
import { ThemeProvider } from "@mui/material";
import theme from "styles/theme";
import { routes } from "./router/routes";
import { SnackbarProvider } from "hooks/useSnackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Create a new QueryClient instance
const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container!);
const router = createBrowserRouter(routes, {
  future: {
    v7_relativeSplatPath: true,
  },
});

initializeFirebase(true);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </LocalizationProvider>
);

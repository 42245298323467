import { validateHighlightInput } from "shared/service/utils/InputValidator";
import {
  Highlight,
  UpdateHighlightInput,
  ModelResponse,
  CreateHighlightInput,
  DeleteObjectInput,
  GetObjectInput,
} from "shared/API";
import {
  FIREBASE_ANALYTICS,
  FIREBASE_AUTH,
  FIREBASE_DATABASE,
  LOGGER,
} from "shared/Firebase";
import {
  IsImageValid,
  getFileDirectory,
  getPictureType,
} from "shared/service/utils/ImageUtil";
import { getFileURL, saveImage } from "shared/service/Storage";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  getFirestoreObjectById,
  listFirestoreObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { XStatus } from "shared/service/utils/XStatus";
import { logEvent } from "firebase/analytics";
import { VENDOR_COLLECTION } from "./Vendor";
import { doc, runTransaction } from "firebase/firestore";

const HIGHLIGHT_COLLECTION = "highlights";

function generateHighlightCollectionPath(vendorId: string): string {
  return `${VENDOR_COLLECTION}/${vendorId}/${HIGHLIGHT_COLLECTION}`;
}

export async function highlightsAPI(input: {
  vendor: GetObjectInput;
}): Promise<ModelResponse<Highlight>> {
  return listFirestoreObjects<Highlight>(
    generateHighlightCollectionPath(input.vendor.id),
    {
      query: {
        /* Empty query lists everything */
      },
    },
  );
}

export async function highlightByIdAPI(input: {
  vendor: GetObjectInput;
  highlight: GetObjectInput;
}): Promise<Highlight> {
  return getFirestoreObjectById<Highlight>(
    generateHighlightCollectionPath(input.vendor.id),
    input.highlight,
  );
}

export async function createHighlightAPI(input: {
  vendorId: string;
  highlight: CreateHighlightInput;
  image?: File;
}): Promise<string> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  const highlightId =
    await createFirestoreObjectPrivileged<CreateHighlightInput>(
      generateHighlightCollectionPath(input.vendorId),
      input.highlight,
      validateHighlightInput,
    );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    LOGGER!.info(input);
    const upload_result = await saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: `${highlightId}.${getPictureType(input.image)}`,
      file: input.image,
    });
    {
      input.highlight.imageUrl = await getFileURL(upload_result.ref.fullPath);
      await updateHighlightAPI({
        vendorId: input.vendorId,
        highlight: { id: highlightId, imageUrl: input.highlight.imageUrl },
      });
    }
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "highlight_creation", { uid });
  }
  return highlightId;
}

export async function deleteHighlightAPI(input: {
  vendorId: string;
  highlight: DeleteObjectInput;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "highlight_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(
    generateHighlightCollectionPath(input.vendorId),
    input.highlight,
  );
}

export async function updateHighlightAPI(input: {
  vendorId: string;
  highlight: UpdateHighlightInput;
  image?: File;
}): Promise<void> {
  if (input.image && IsImageValid(input.image) != XStatus.Valid) {
    throw XStatus.InvalidType;
  }
  await updateFirestoreObject<UpdateHighlightInput>(
    generateHighlightCollectionPath(input.vendorId),
    input.highlight,
    validateHighlightInput,
  );
  const uid = FIREBASE_AUTH!.currentUser!.uid;
  if (input.image) {
    const upload_result = await saveImage({
      directoryName: getFileDirectory(uid, "images"),
      fileName: `${input.highlight.id}.${getPictureType(input.image)}`,
      file: input.image,
    });
    {
      input.highlight.imageUrl = await getFileURL(upload_result.ref.fullPath);
      await updateHighlightAPI({
        vendorId: input.vendorId,
        highlight: {
          id: input.highlight.id,
          imageUrl: input.highlight.imageUrl,
        },
      });
    }
  }
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "highlight_update", { uid });
  }
}

export async function updateHighlightOrder(highlightInput: {
  vendorId: string;
  highlightOrder: { highlightId: string; order: number }[];
}): Promise<void> {
  console.log("highlightInput", highlightInput);
  return runTransaction(FIREBASE_DATABASE!, async (transaction) => {
    for (const orderInput of highlightInput.highlightOrder) {
      const highlightDocRef = doc(
        FIREBASE_DATABASE!,
        `vendors/${highlightInput.vendorId}/highlights/${orderInput.highlightId}`,
      );
      transaction.update(highlightDocRef, { order: orderInput.order });
    }
  });
}

import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { Link } from "react-router";

const Footer = () => {
  return (
    <footer className="w-full">
      {/* <div className="w-full bg-x-gray1/10 py-[60px]">
        <div className="flex flex-row gap-30 container max-w-[1200px] mx-auto">
          <div className="flex flex-col flex-1 gap-8">
            <div className="flex items-start gap-[50px]">
              <img
                className="w-[68px] mb-3"
                src="/images/iplanx-logo-white.png"
              />
              <div className="flex gap-8">
                <Link to="https://www.facebook.com/iplanx">
                  <Facebook fontSize="large" />
                </Link>
                <Link to="https://www.instagram.com/iplanxofficial">
                  <Instagram fontSize="large" />
                </Link>
                <Link to="https://www.youtube.com/iplanxofficial">
                  <YouTube fontSize="large" />
                </Link>
              </div>
            </div>
            <Formik onSubmit={handleSubscribe} initialValues={{ email: "" }}>
              <XFForm className="flex flex-row gap-2 max-w-[500px]">
                <XFTextField label="Email" name="email" />
                <XFButton title="Subscribe" type="submit" variant="secondary" />
              </XFForm>
            </Formik>
          </div>
          <div className="flex flex-1">
            <div className="flex flex-col flex-1 gap-4">
              <Link to={paths.Login}>Artists</Link>
              <Link to={paths.Login}>Events</Link>
              <Link to={paths.Login}>Contact</Link>
            </div>
            <div className="flex flex-col flex-1 gap-4">
              <Link to={paths.Login}>About</Link>
              <Link to={paths.Login}>Terms & Condition</Link>
              <Link to={paths.Login}>Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full bg-black/60 py-5">
        <div className="container max-w-[1200px] mx-auto text-center">
          <div className="w-full flex items-center">
            <div className="flex-1">
              <img className="w-[91px]" src="/images/iplanx-logo-white.png" />
            </div>
            <div className="flex-1 text-center text-white/50">
              <p>&copy; 2025 iPlanX. All rights reserved.</p>
            </div>
            <div className="flex-1 flex justify-end gap-4">
              <Link to="https://www.facebook.com/iplanx" target="_blank">
                <Facebook fontSize="large" className="text-[#B6B6B6]" />
              </Link>
              <Link
                to="https://www.instagram.com/iplanxofficial"
                target="_blank"
              >
                <Instagram fontSize="large" className="text-[#B6B6B6]" />
              </Link>
              <Link to="https://www.youtube.com/iplanxofficial" target="_blank">
                <YouTube fontSize="large" className="text-[#B6B6B6]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useQuery } from "@tanstack/react-query";
import { XFButton } from "components/form";
import { useState } from "react";
import XListMini from "components/XListMini";
import { packagesAPI } from "shared/service/Package";
import { testPackage1 } from "shared/service/__test__/TestData";
import { Package } from "shared/API";
import {
  ArtistPackageCreateModal,
  ArtistPackageDeleteModal,
  ArtistPackageUpdateModal,
} from "./actions";
import { iAddRed } from "components/common/XIcons";

const ArtistTabPackages = ({ vendorId }: { vendorId: string }) => {
  const [packageId, setPackageId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);

  const { data: packages, refetch: refetchPackages } = useQuery({
    queryKey: ["ArtistTabPackages.packagesAPI", vendorId],
    queryFn: () =>
      packagesAPI({
        vendor: { id: vendorId },
        query: {
          limit: 10,
          queryInput: {
            andStatements: [
              {
                whereFilters: [
                  {
                    field: "eventType",
                    operation: "==",
                    value: testPackage1.eventType,
                  },
                ],
              },
            ],
          },
        },
      }),
    enabled: !!vendorId,
  });

  const onClose = () => {
    setModalName(null);
    setPackageId(null);
  };

  return (
    <>
      <div className="flex flex-col gap-6 w-[500px]">
        {packages &&
          packages.items.map((x: Package, i: number) => (
            <XListMini
              index={i}
              title={x.title}
              subtitle={x.eventType}
              onUpdate={() => {
                setPackageId(x.id);
                setModalName("update");
              }}
              onDelete={() => {
                setPackageId(x.id);
                setModalName("delete");
              }}
              key={x.id}
            />
          ))}
        <XFButton
          title="Add Package"
          type="button"
          onClick={() => {
            setModalName("create");
          }}
          variant="quaternary"
          icon={iAddRed}
        />
      </div>
      {modalName === "create" && (
        <ArtistPackageCreateModal
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchPackages}
        />
      )}
      {modalName === "update" && packageId && (
        <ArtistPackageUpdateModal
          packageId={packageId}
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchPackages}
        />
      )}
      {modalName === "delete" && packageId && (
        <ArtistPackageDeleteModal
          packageId={packageId}
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchPackages}
        />
      )}
    </>
  );
};

export default ArtistTabPackages;

interface XTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const XTabPanel = ({
  children,
  value,
  index,
  ...other
}: XTabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="bg-black z-20"
    >
      {value === index && <div className="py-[60px]">{children}</div>}
    </div>
  );
};

interface XTabsProps {
  tabs: string[];
  tabValue: number;
  onTabChange: (index: number) => void;
}

export const XTabs = ({ tabs, tabValue, onTabChange }: XTabsProps) => {
  return (
    <div className="flex gap-[60px]">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`relative py-6 text-md whitespace-nowrap ${tabValue === index ? "font-medium text-white" : "text-white/70 font-light"}`}
          onClick={() => onTabChange(index)}
        >
          {tabValue === index && (
            <div
              className="bg-primary w-[50%] h-[50px] absolute top-[80px] left-0 right-0 mx-auto"
              style={{
                filter: "blur(21.45px)",
              }}
            ></div>
          )}
          {tab}
        </button>
      ))}
    </div>
  );
};

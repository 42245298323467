import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "hooks/useSnackbar";
import {
  generateMutationMessage,
  MutationType,
} from "utils/generateMutationMessage";
import XModal from "./XModal";
import { Formik, FormikValues } from "formik";

interface XConfirmModalProps {
  onSuccess: () => void;
  onClose: () => void;
  entity: "artist" | "event" | "lead" | "package" | "service" | "highlight";
  action: MutationType;
  entityId: string;
  mutationFn: any;
  mutationInput: any;
  title?: string;
  message?: string;
  confirmText?: string; // Allows overriding the "Submit" button text
  cancelText?: string; // Allows overriding the "Cancel" button text
}

const XConfirmModal = ({
  onSuccess,
  onClose,
  entity,
  action,
  entityId,
  mutationFn,
  mutationInput,
  title,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
}: XConfirmModalProps) => {
  const { showSnackbar } = useSnackbar();
  const mutationKey = [[entity, action].join(""), entityId];

  const { mutate } = useMutation({
    mutationKey,
    mutationFn,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage(entity, action, true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage(entity, action, false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    mutate(mutationInput, {
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <XModal
          open={true}
          title={title || `${action} ${entity}`}
          onClose={onClose}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submitText={confirmText}
          cancelText={cancelText}
          renderContent={() => (
            <span className="text-xs text-heading2">
              {message || `Are you sure you want to ${action} this ${entity}?`}
            </span>
          )}
          isConfirmation={true}
        />
      )}
    </Formik>
  );
};

export default XConfirmModal;

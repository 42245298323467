const getChangedFields = (
  initialValues: Record<string, any>,
  currentValues: Record<string, any>,
): Record<string, any> => {
  return Object.keys(currentValues).reduce(
    (acc, key) => {
      if (currentValues[key] !== initialValues[key]) {
        acc[key] = currentValues[key];
      }
      return acc;
    },
    {} as Record<string, any>,
  );
};

export default getChangedFields;

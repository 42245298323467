import { XFButton, XFForm, XFRow, XFTextField } from "components/form";
import { FormikValues, useFormikContext } from "formik";

const BookStep1 = () => {
  const { submitForm } = useFormikContext<FormikValues>();

  return (
    <XFForm>
      <XFRow>
        <XFTextField label="Event Type" name="eventType" />
        <XFTextField label="Event Title" name="eventTitle" />
      </XFRow>
      <XFRow>
        <XFTextField label="Lead Status" name="leadStatus" />
        <XFTextField label="Start Time" name="startTimestamp" />
      </XFRow>
      <XFRow>
        <XFTextField label="End Time" name="endTimestamp" />
        <XFTextField label="Guests" name="guests" />
      </XFRow>
      <XFButton title="Next" type="button" onClick={submitForm} />
    </XFForm>
  );
};

export default BookStep1;

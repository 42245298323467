export const transformServiceTypes = (
  serviceTypes: Record<string, string>,
): Record<string, string[]> => {
  const result: Record<string, string[]> = {};
  Object.entries(serviceTypes).forEach(([, value]) => {
    const [topLevel, subKey] = value.split(".");
    if (!subKey) return;

    if (!result[topLevel]) {
      result[topLevel] = [];
    }

    result[topLevel].push(subKey);
  });

  return result;
};

export const lowkeyDisplayNameMapping: Record<string, string> = {
  EngagementDJ: "Engagement DJ",
  HousePartyDJ: "House Party DJ",
  WeddingDJ: "Wedding DJ",
  CubDJ: "Cub DJ",
  RadioDJ: "Radio DJ",
  FestivalDJ: "Festival DJ",
  DJController: "DJ Controller",
  MovingHead: "Moving Head",
  LEDLight: "LED Light",
  UpLight: "Up Light",
  Laser: "Laser",
  LightController: "Light Controller",
  Firework: "Firework",
  Flame: "Flame",
  Co2Gun: "CO2 Gun",
  Co2Jet: "CO2 Jet",
  Co2Tank: "CO2 Tank",
  Fog: "Fog",
  LEDWall: "LED Wall",
  Projector: "Projector",
  Screen: "Screen",
  SpeakerStand: "Speaker Stand",
  WirelessMic: "Wireless Mic",
  WiredMic: "Wired Mic",
  SubSpeaker: "Sub Speaker",
  PoweredSpeaker: "Powered Speaker",
  Monitor: "Monitor",
  Mixer: "Mixer",
  WeddingPhotography: "Wedding Photography",
  EventPhotography: "Event Photography",
  FashionPhotography: "Fashion Photography",
  DronePhotography: "Drone Photography",
  ConcertPhotography: "Concert Photography",
  StudioPhotography: "Studio Photography",
  PhotoEditing: "Photo Editing",
  WeddingVideography: "Wedding Videography",
  EventVideography: "Event Videography",
  MusicVideography: "Music Videography",
  LifestyleVideography: "Lifestyle Videography",
  SportsVideography: "Sports Videography",
  DroneVideography: "Drone Videography",
  VideoEditing: "VideoEditing",
  SocialMediaVideography: "SocialMedia Videography",
  TVShowVideography: "TVShow Videography",
  ConcertVideography: "Concert Videography",
  LivePerformance: "Live Performance",
  WeddingSinger: "Wedding Singer",
  BackgroundSinger: "Background Singer",
  MusicalPlays: "Musical Plays",
  Voiceover: "Voiceover",
  LessonsAndCoaching: "Lessons And Coaching",
  OnlinePerformance: "Online Performance",
  EducationalWorkshops: "Educational Workshops",
  WorshipService: "Worship Service",
  CruisePerformance: "Cruise Performance",
  ClubPerformance: "Club Performance",
  BarPerformance: "Bar Performance",
  TVPerformance: "TV Performance",
  ConcertPerformance: "Concert Performance",
  Competitions: "Competitions",
  Piano: "Piano",
  GuitarAcoustic: "Guitar Acoustic",
  GuitarElectric: "Guitar Electric",
  GuitarBass: "Guitar Bass",
  Violin: "Violin",
  Drum: "Drum",
  Percussion: "Percussion",
  Cello: "Cello",
  Saxophone: "Saxophone",
  Trumpet: "Trumpet",
  Flute: "Flute",
  Keyboard: "Keyboard",
  Accordion: "Accordion",
  Sitar: "Sitar",
  MusicTeacher: "Music Teacher",
  OrchestralConductor: "Orchestral Conductor",
  ChoirConductor: "Choir Conductor",
  BandConductor: "Band Conductor",
  MusicProducer: "Music Producer",
  BeatProducer: "Beat Producer",
  Songwriter: "Songwriter",
  Arranger: "Arranger",
  Composer: "Composer",
  WeddingPlanner: "Wedding Planner",
  EventPlanner: "Event Planner",
  Valet: "Valet",
  Security: "Security",
  Usher: "Usher",
  Server: "Server",
  Bartender: "Bartender",
  DanceFloor: "Dance Floor",
  Stage: "Stage",
  Truss: "Truss",
  LiftForTruss: "Lift For Truss",
  Podium: "Podium",
  Platform: "Platform",
  HairArtist: "Hair Artist",
  MakeupArtist: "Makeup Artist",
  FashionDesigner: "Fashion Designer",
  FlyerDesigner: "Flyer Designer",
  EngagementDecoration: "Engagement Decoration",
  BirthdayDecoration: "Birthday Decoration",
  WeddingDecoration: "Wedding Decoration",
  FoodTableDecoration: "Food Table Decoration",
  AppetizerDecoration: "Appetizer Decoration",
  BalloonDecoration: "Balloon Decoration",
  FlowerDecoration: "Flower Decoration",
  Backdrop: "Backdrop",
  PhotoBooth: "Photo Booth",
  InflatableStructure: "Inflatable Structure",
  Car: "Car",
  Tent: "Tent",
  PhotoBooth360: "Photo Booth 360",
  Heater: "Heater",
  FanAndAC: "Fan And AC",
  Generator: "Generator",
  BarEquipment: "Bar Equipment",
  PortableRestroom: "Portable Restroom",
  Barrier: "Barrier",
  Stanchion: "Stanchion",
  Signage: "Signage",
  ParkingEquipment: "Parking Equipment",
  Table: "Table",
  Chair: "Chair",
  Tablecloth: "Tablecloth",
  CocktailTable: "Cocktail Table",
  LoungeSeating: "Lounge Seating",
  FoodWarmer: "Food Warmer",
  Dishes: "Dishes",
  Utensil: "Utensil",
  Napkin: "Napkin",
  Plate: "Plate",
  Glasses: "Glasses",
  BalletDancer: "Ballet Dancer",
  LatinDancer: "Latin Dancer",
  BallroomDancer: "Ballroom Dancer",
  BreakDancer: "Break Dancer",
  TapDancer: "Tap Dancer",
  BellyDancer: "Belly Dancer",
  Aerialist: "Aerialist",
  TraditionalDancer: "Traditional Dancer",
  ClubDancer: "Club Dancer",
  Choreographer: "Choreographer",
  FestivalDancer: "Festival Dancer",
  PoleDancer: "Pole Dancer",
  ExoticShow: "Exotic Show",
  FireDancer: "Fire Dancer",
  LEDDancer: "LED Dancer",
  GlowDancer: "Glow Dancer",
  AcrobaticShow: "Acrobatic Show",
  StiltPerformer: "Stilt Performer",
  SynchronizedDancer: "Synchronized Dancer",
  Magician: "Magician",
  CaricatureArtist: "Caricature Artist",
  Comedian: "Comedian",
  MC: "MC",
  Jugglers: "Jugglers",
};

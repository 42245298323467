import XCard from "./XCard";

interface CardData {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface XCardsGridProps {
  cards: CardData[];
  imageURL?: string;
}

const XCardsGrid: React.FC<XCardsGridProps> = ({ cards, imageURL }) => {
  return (
    <div className="flex gap-[100px]">
      <div className="grid grid-cols-2 gap-x-[64px] gap-y-[48px] w-[calc(100%-450px)]">
        {cards.map((card, index) => (
          <XCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
      {imageURL && <img src={imageURL} className="w-350 flex-1" />}
    </div>
  );
};

export default XCardsGrid;

import { Form } from "formik";

const XFForm = ({
  className,
  ...props
}: {
  className?: string;
  [key: string]: any;
}) => {
  return (
    <Form
      className={[
        "w-full flex flex-col flex-wrap gap-y-6 max-w-[800px]",
        className,
      ].join(" ")}
      {...props}
    />
  );
};

export default XFForm;

import { useMutation, useQuery } from "@tanstack/react-query";
import { XFButton } from "components/form";
import { useEffect, useState } from "react";
import { Highlight } from "shared/API";
import { highlightsAPI, updateHighlightOrder } from "shared/service/Highlight";
import ArtistHighlightCreateModal from "./actions/ArtistHighlightCreateModal";
import ArtistHighlightUpdateModal from "./actions/ArtistHighlightUpdateModal";
import ArtistHighlightDeleteModal from "./actions/ArtistHighlightDeleteModal";
import XListMini from "components/XListMini";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import XDragSortContext from "components/drag/XDragSortContext";
import XDragSortItem from "components/drag/XDragSortItem";
import { useSnackbar } from "hooks/useSnackbar";
import { iAddRed } from "components/common/XIcons";

const ArtistTabHighlights = ({
  vendorId,
  refetch,
}: {
  vendorId: string;
  refetch: () => void;
}) => {
  const { showSnackbar } = useSnackbar();

  const [highlightId, setHighlightId] = useState<string | null>(null);
  const [allItems, setAllItems] = useState<Highlight[]>([]);
  const [modalName, setModalName] = useState<string | null>(null);
  const [orderChanged, setOrderChanged] = useState<boolean>(false);

  const { data: highlights, refetch: refetchHighlights } = useQuery({
    queryKey: ["ArtistTabHighlights.highlightsAPI", vendorId],
    queryFn: () =>
      highlightsAPI({
        vendor: { id: vendorId },
      }),
    enabled: !!vendorId,
  });

  const { mutate } = useMutation({
    mutationKey: ["ArtistTabHighlightsUpdate.updateOrder"],
    mutationFn: updateHighlightOrder,
    onSuccess: () => {
      showSnackbar({
        message: "Order updated successfully",
        severity: "success",
      });
    },
    onError: (error) => {
      console.error("Failed to update highlight order: ", error);
    },
  });

  const onClose = () => {
    setModalName(null);
    setHighlightId(null);
  };

  useEffect(() => {
    if (highlights)
      setAllItems(highlights.items.sort((a, b) => a.order - b.order));
  }, [highlights]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id && allItems) {
      setAllItems((x) => {
        const oldIndex = x.findIndex((item) => item.id === active.id);
        const newIndex = x.findIndex((item) => item.id === over.id);
        return arrayMove(x, oldIndex, newIndex);
      });
    }
    setOrderChanged(true);
  };

  const handleUpdateOrder = () => {
    mutate({
      vendorId,
      highlightOrder: allItems.map((item, i) => ({
        highlightId: item.id,
        order: i,
      })),
    });
    console.log(refetch);
  };

  return (
    <>
      <div className="flex flex-col gap-8 w-[500px]">
        {allItems && (
          <XDragSortContext onDragEnd={handleDragEnd} allItems={allItems}>
            {allItems.map((highlight: Highlight, i: number) => (
              <XDragSortItem id={highlight.id} key={highlight.id}>
                <XListMini
                  index={i}
                  title={highlight.title}
                  subtitle={highlight.subtitle}
                  imageURL={highlight.imageUrl}
                  onUpdate={() => {
                    setHighlightId(highlight.id);
                    setModalName("update");
                  }}
                  onDelete={() => {
                    setHighlightId(highlight.id);
                    setModalName("delete");
                  }}
                />
              </XDragSortItem>
            ))}
          </XDragSortContext>
        )}
        <XFButton
          title="Add Highlight"
          type="button"
          onClick={() => {
            setModalName("create");
          }}
          variant="quaternary"
          icon={iAddRed}
        />
        {orderChanged && (
          <XFButton
            onClick={handleUpdateOrder}
            title="Update Order"
            type="submit"
            size="lg"
          />
        )}
      </div>
      {modalName === "create" && (
        <ArtistHighlightCreateModal
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchHighlights}
        />
      )}
      {modalName === "update" && highlightId && (
        <ArtistHighlightUpdateModal
          vendorId={vendorId}
          highlightId={highlightId}
          onClose={onClose}
          onSuccess={refetchHighlights}
        />
      )}
      {modalName === "delete" && highlightId && (
        <ArtistHighlightDeleteModal
          vendorId={vendorId}
          highlightId={highlightId}
          onClose={onClose}
          onSuccess={refetchHighlights}
        />
      )}
    </>
  );
};

export default ArtistTabHighlights;

// import React from "react";
// import { XFButton } from "./form";
// import XModal from "./XModal";

interface XConfirmationModalProps {
  title?: string;
  message?: string;
  onConfirm: () => void;
  onClose: () => void;
}

const XConfirmationModal = (props: XConfirmationModalProps) => {
  console.log(props);
  return (
    <></>
    // <XModal onClose={props.onClose} title={props.title}>
    //   <span className="text-xs text-heading2">{props.message}</span>
    //   <div className="flex items-stretch gap-4 mt-[40px]">
    //     <XFButton
    //       title="Cancel"
    //       onClick={props.onClose}
    //       variant="secondary"
    //       className="flex-1"
    //     />
    //     <XFButton
    //       title="Confirm"
    //       onClick={props.onConfirm}
    //       variant="primary"
    //       className="flex-1"
    //     />
    //   </div>
    // </XModal>
  );
};

export default XConfirmationModal;

import { validatePackageInput } from "shared/service/utils/InputValidator";
import {
  Package,
  UpdatePackageInput,
  ModelResponse,
  CreatePackageInput,
  DeleteObjectInput,
  GetObjectInput,
  ListQueryInput,
} from "shared/API";
import { FIREBASE_ANALYTICS, FIREBASE_AUTH } from "shared/Firebase";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  listFirestoreObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { logEvent } from "firebase/analytics";
import { VENDOR_COLLECTION } from "./Vendor";

const PACKAGE_COLLECTION = "packages";

function generatePackageCollectionPath(vendorId: string): string {
  return `${VENDOR_COLLECTION}/${vendorId}/${PACKAGE_COLLECTION}`;
}

export async function packagesAPI(input: {
  vendor: GetObjectInput;
  query: ListQueryInput;
}): Promise<ModelResponse<Package>> {
  return listFirestoreObjects<Package>(
    generatePackageCollectionPath(input.vendor.id),
    input,
  );
}

export async function createPackageAPI(input: {
  vendorId: string;
  package: CreatePackageInput;
}): Promise<string> {
  const packageId = await createFirestoreObjectPrivileged<CreatePackageInput>(
    generatePackageCollectionPath(input.vendorId),
    input.package,
    validatePackageInput,
  );
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "package_creation", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  return packageId;
}

export async function deletePackageAPI(input: {
  vendorId: string;
  package: DeleteObjectInput;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "package_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(
    generatePackageCollectionPath(input.vendorId),
    input.package,
  );
}

export async function updatePackageAPI(input: {
  vendorId: string;
  package: UpdatePackageInput;
}): Promise<void> {
  await updateFirestoreObject<UpdatePackageInput>(
    generatePackageCollectionPath(input.vendorId),
    input.package,
    validatePackageInput,
  );
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "package_update", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
}

import { XFForm, XFTextField, XFUpload } from "components/form";
import { Formik, FormikValues } from "formik";
import { schemaEventCreate } from "utils/validation/schema";
import { useMutation } from "@tanstack/react-query";
import { CreateEventInput } from "shared/API";
import XModal from "components/XModal";
import { useSnackbar } from "hooks/useSnackbar";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { createEventAPI } from "shared/service/Event";
import { XFDateTimePicker } from "components/form/XFDateField";
import XFAutocompleteAPI from "components/form/XFAutocompleteAPI";
import { fetchArtists } from "components/form/autocomplete/queries";

interface EventCreateModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const initialValues = {
  title: "",
  description: "",
  startTimestamp: "",
  endTimestamp: "",
  ticketLink: "",
  image: "",
};

const getCreateEventInput = (
  values: FormikValues
): { event: CreateEventInput; image?: File; isPrivate?: boolean } => ({
  event: {
    title: values.title,
    description: values.description,
    startTimestamp: values.startTimestamp,
    endTimestamp: values.endTimestamp,
    ticketLink: values.ticketLink,
  },
  image: values.image,
  isPrivate: true,
});

const EventCreateModal = ({ onSuccess, onClose }: EventCreateModalProps) => {
  const { showSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationKey: ["EventCreateModal.createEvent"],
    mutationFn: createEventAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage("Event", "create", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Event", "create", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    mutate(getCreateEventInput(values), {
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaEventCreate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <XModal
          title="Create Event"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField label="Title" name="title" />
              <XFTextField label="Description" name="description" rows={4} />
              <XFTextField label="Ticket Link" name="ticketLink" />
              <XFAutocompleteAPI
                label="Artists"
                name="artists"
                fetchOptions={fetchArtists}
              />
              <XFUpload
                label="Event Picture"
                name="image"
                onChange={(file) => setFieldValue("image", file)}
              />
              <XFDateTimePicker
                label="Start Time"
                name="startTimestamp"
                setFieldValue={setFieldValue}
              />
              <XFDateTimePicker
                label="End Time"
                name="endTimestamp"
                setFieldValue={setFieldValue}
              />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default EventCreateModal;

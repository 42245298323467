import { useState } from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { XFButton, XFForm } from "components/form";

export type StepConfig = {
  component: React.FC<{
    onStepSubmit: (
      values: FormikValues,
      helpers: FormikHelpers<FormikValues>
    ) => void;
  }>;
  validationSchema: any;
};

interface MultiStepFormProps {
  initialValues: FormikValues;
  steps: StepConfig[];
  onStepSubmit: (values: FormikValues, step: number) => void;
}

const MultiStepForm: React.FC<MultiStepFormProps> = ({
  initialValues,
  steps,
  onStepSubmit,
}) => {
  const [step, setStep] = useState(0);

  const handleSubmit = async (values: FormikValues) => {
    await onStepSubmit(values, step);
    if (step < steps.length - 1) {
      setStep(step + 1); // Move to the next step
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={steps[step].validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        const StepComponent = steps[step].component;
        return (
          <XFForm>
            <StepComponent onStepSubmit={handleSubmit} />
            <div className="flex justify-between mt-4">
              {step > 0 && (
                <XFButton
                  title="Back"
                  type="button"
                  onClick={() => setStep(step - 1)}
                  size="lg"
                  className="flex-1"
                  disabled={isSubmitting}
                />
              )}
            </div>
          </XFForm>
        );
      }}
    </Formik>
  );
};

export default MultiStepForm;

import { TextField } from "@mui/material";
import { useField } from "formik";
import { ChangeEventHandler, FocusEventHandler } from "react";

interface XFTextFieldProps {
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  focus?: boolean;
  icon?: React.ReactNode;
  rows?: number;
}

const XFTextField = ({ label, ...props }: XFTextFieldProps) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error); // Check if field is touched and has an error

  return (
    <div className="flex flex-col flex-1 gap-1.5">
      {label && <label className="text-xs text-gray-600">{label}</label>}
      <TextField
        {...field}
        {...props}
        variant="outlined"
        error={hasError}
        helperText={meta.error}
        InputProps={{
          startAdornment: props.icon,
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: props.rows ? "25px" : "50px",
            py: "13px",
            px: "24px",
            gap: 1,
            "& fieldset": {
              borderColor: hasError
                ? "var(--tw-color-primary)"
                : "var(--tw-color-x-gray2)",
            },
            "&:hover fieldset": {
              borderColor: hasError
                ? "var(--tw-color-primary)"
                : "var(--tw-color-x-heading2)",
            },
            "&.Mui-focused fieldset": {
              borderColor: hasError
                ? "var(--tw-color-primary)"
                : "var(--tw-color-x-heading2)",
            },
          },
          // Input
          "& .MuiInputBase-input": {
            color: "var(--tw-color-white)",
            lineHeight: "30px !important",
            height: "30px",
            p: 0,
            gap: 1,
          },
          // Error
          "& .MuiFormHelperText-root": {
            color: "var(--tw-color-primary)",
          },
          // Adornment
          "& .MuiInputBase-adornedStart & img": {
            gap: 1,
          },
        }}
        rows={props.rows}
        multiline={Boolean(props.rows)}
      />
    </div>
  );
};

export default XFTextField;

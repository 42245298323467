import Page from "components/Page";
import { Link } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import XTable from "components/table/XTable";
import { paths } from "router/routes";
import { useState } from "react";
import { useParams } from "react-router";
import { LeadConvertModal, LeadCreateModal, LeadDeleteModal } from "./actions";
import { leadsAPI } from "shared/service/Lead";
import { iDelete, iEdit, iPublish, iView } from "components/common/XIcons";

const MyLeadsPage = () => {
  const [leadId, setLeadId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);
  const { ownerId } = useParams();

  const { data: artists, refetch } = useQuery({
    queryKey: ["MyLeadsPage", ownerId],
    queryFn: () =>
      leadsAPI({
        query: {
          queryInput: {
            andStatements: [
              {
                whereFilters: [
                  {
                    field: "ownerId",
                    operation: "==",
                    value: String(ownerId),
                  },
                ],
              },
            ],
          },
        },
      }),
    enabled: !!ownerId,
  });

  const rows =
    artists?.items?.map((x) => ({
      id: x.id,
      eventType: x.eventType,
      createdAt: x.createdAt,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "eventType",
      headerName: "Event Type",
      sortable: true,
      minWidth: 240,
    },
    { field: "id", headerName: "Lead ID", minWidth: 240 },
    { field: "createdAt", headerName: "Created At", minWidth: 200 },
    {
      field: "actions",
      headerName: "",
      minWidth: 200,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <div className="flex items-center justify-end gap-2">
          <Link to={paths.Leads.details(params.row.id, String(ownerId))}>
            <IconButton>
              <img src={iView} />
            </IconButton>
          </Link>
          <Link to={paths.Leads.update(params.row.id, String(ownerId))}>
            <IconButton>
              <img src={iEdit} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setModalName("convert");
              setLeadId(params.row.id);
            }}
          >
            <img src={iPublish} />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalName("delete");
              setLeadId(params.row.id);
            }}
          >
            <img src={iDelete} />
          </IconButton>
        </div>
      ),
      flex: 1,
    },
  ];

  const onClose = () => {
    setLeadId(null);
    setModalName(null);
  };

  return (
    <Page>
      <XTable
        rows={rows}
        columns={columns}
        title="My Leads"
        onCreate={() => setModalName("create")}
      />
      {modalName === "create" && (
        <LeadCreateModal onClose={onClose} onSuccess={refetch} />
      )}
      {modalName === "delete" && leadId && (
        <LeadDeleteModal
          leadId={leadId}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
      {modalName === "convert" && leadId && (
        <LeadConvertModal
          leadId={leadId}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
    </Page>
  );
};

export default MyLeadsPage;

import { useState } from "react";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { TextField, Popover } from "@mui/material";

interface XFDateRangePickerProps {
  label: string;
  name: string;
  setFieldValue: (field: string, value: { from: number; to: number }) => void;
}

interface XFDateTimePickerProps {
  label: string;
  name: string;
  setFieldValue: (field: string, value: number) => void;
}

const XFDateRangePicker: React.FC<XFDateRangePickerProps> = ({
  label,
  name,
  setFieldValue,
}) => {
  const [dates, setDates] = useState<{ from: Dayjs | null; to: Dayjs | null }>({
    from: null,
    to: null,
  });

  const handleDateChange = (type: "from" | "to", newValue: Dayjs | null) => {
    const newDates = { ...dates, [type]: newValue };
    setDates(newDates);
    if (newDates.from && newDates.to) {
      setFieldValue(name, {
        from: dayjs(newDates.from).valueOf(),
        to: dayjs(newDates.to).valueOf(),
      });
    }
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label className="text-xs text-gray-600">{label}</label>
      <div className="flex gap-4">
        <DatePicker
          label="From"
          value={dates.from}
          onChange={(newValue) => handleDateChange("from", newValue)}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              sx: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                  py: "13px",
                  px: "24px",
                  gap: 1,
                  "& fieldset": {
                    borderColor: "var(--tw-color-x-gray2)",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--tw-color-x-heading2)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--tw-color-x-heading2)",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "var(--tw-color-white)",
                  lineHeight: "30px !important",
                  p: 0,
                  gap: 1,
                },
              },
            },
          }}
        />
        <DatePicker
          label="To"
          value={dates.to}
          onChange={(newValue) => handleDateChange("to", newValue)}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              sx: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                  py: "13px",
                  px: "24px",
                  gap: 1,
                  "& fieldset": {
                    borderColor: "var(--tw-color-x-gray2)",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--tw-color-x-heading2)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--tw-color-x-heading2)",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "var(--tw-color-white)",
                  lineHeight: "30px !important",
                  p: 0,
                  gap: 1,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

const XFDateTimePicker: React.FC<XFDateTimePickerProps> = ({
  label,
  name,
  setFieldValue,
}) => {
  const [dateTime, setDateTime] = useState<Dayjs | null>(null);

  const handleChange = (newValue: Dayjs | null) => {
    setDateTime(newValue);
    if (newValue) {
      setFieldValue(name, dayjs(newValue).valueOf());
    }
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label className="text-xs text-gray-600">{label}</label>
      <DateTimePicker
        value={dateTime}
        onChange={handleChange}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "outlined",
            sx: {
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
                py: "13px",
                px: "24px",
                gap: 1,
                "& fieldset": {
                  borderColor: "var(--tw-color-x-gray2)",
                },
                "&:hover fieldset": {
                  borderColor: "var(--tw-color-x-heading2)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--tw-color-x-heading2)",
                },
              },
              "& .MuiInputBase-input": {
                color: "var(--tw-color-white)",
                lineHeight: "30px !important",
                p: 0,
                gap: 1,
              },
            },
          },
        }}
      />
    </div>
  );
};

interface XFDateSingleInputRangePickerProps {
  label: string;
  name: string;
  setFieldValue: (field: string, value: { from: number; to: number }) => void;
}

const XFDateSingleInputRangePicker: React.FC<
  XFDateSingleInputRangePickerProps
> = ({ label, name, setFieldValue }) => {
  const [dates, setDates] = useState<{ from: Dayjs | null; to: Dayjs | null }>({
    from: null,
    to: null,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (type: "from" | "to", newValue: Dayjs | null) => {
    const newDates = { ...dates, [type]: newValue };
    setDates(newDates);
    if (newDates.from && newDates.to) {
      setFieldValue(name, {
        from: dayjs(newDates.from).valueOf(),
        to: dayjs(newDates.to).valueOf(),
      });
      handleClose();
    }
  };

  return (
    <div className="flex flex-col gap-1.5">
      <label className="text-xs text-gray-600">{label}</label>
      <div className="flex gap-4">
        <TextField
          variant="outlined"
          fullWidth
          value={
            dates.from && dates.to
              ? `${dayjs(dates.from).format("MM/DD/YYYY")} - ${dayjs(dates.to).format("MM/DD/YYYY")}`
              : ""
          }
          onClick={handleOpen}
          InputProps={{
            readOnly: true,
            sx: {
              display: "flex",
              alignItems: "center",
              borderRadius: "50px",
              py: "13px",
              px: "24px",
              gap: 1,
              textAlign: "center",
              "& fieldset": {
                borderColor: "var(--tw-color-x-gray2)",
              },
              "&:hover fieldset": {
                borderColor: "var(--tw-color-x-heading2)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "var(--tw-color-x-heading2)",
              },
              "& .MuiInputBase-input": {
                color: "var(--tw-color-white)",
                lineHeight: "30px !important",
                p: 0,
                gap: 1,
                textAlign: "center",
              },
            },
          }}
        />
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <div className="p-4 flex space-x-4">
            <DatePicker
              label="From"
              value={dates.from}
              onChange={(newValue) => handleDateChange("from", newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                      py: "13px",
                      px: "24px",
                      gap: 1,
                      "& fieldset": {
                        borderColor: "var(--tw-color-x-gray2)",
                      },
                      "&:hover fieldset": {
                        borderColor: "var(--tw-color-x-heading2)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--tw-color-x-heading2)",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "var(--tw-color-white)",
                      lineHeight: "30px !important",
                      p: 0,
                      gap: 1,
                    },
                  },
                },
              }}
            />
            <DatePicker
              label="To"
              value={dates.to}
              onChange={(newValue) => handleDateChange("to", newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                      py: "13px",
                      px: "24px",
                      gap: 1,
                      "& fieldset": {
                        borderColor: "var(--tw-color-x-gray2)",
                      },
                      "&:hover fieldset": {
                        borderColor: "var(--tw-color-x-heading2)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "var(--tw-color-x-heading2)",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "var(--tw-color-white)",
                      lineHeight: "30px !important",
                      p: 0,
                      gap: 1,
                    },
                  },
                },
              }}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export { XFDateRangePicker, XFDateTimePicker, XFDateSingleInputRangePicker };

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { paths } from "router/routes";

import XMenuItem from "components/XMenuItem";
import { useNavigate } from "react-router";

interface AccountMenuProps {
  userId: string;
  initial?: string;
}

const AccountMenu = ({ userId, initial }: AccountMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
      window.location.reload(); // Reload the current page to clear any user-specific data
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <>
      <Avatar
        onClick={handleClick}
        className="bg-x-gray1 text-white w-[40px] h-[40px] cursor-pointer"
      >
        {initial}
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "& ul": {
                width: "180px",
                bgcolor: "#121212",
                border: "0px solid #263238",
                borderRadius: "10px",
                top: 0,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 10,
                width: 16,
                height: 16,
                bgcolor: "#121212",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <XMenuItem to={paths.Artists.list(userId)} label="My Artists" />
        <XMenuItem to={paths.Events.list(userId)} label="My Events" />
        <XMenuItem to={paths.Leads.list(userId)} label="My Leads" />
        <Divider />
        <XMenuItem
          label="Logout"
          onClick={handleLogout}
          icon={<Logout fontSize="small" />}
        />
      </Menu>
    </>
  );
};

export default AccountMenu;

interface XSectionProps {
  title?: string | React.ReactNode;
  topTitle?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  align?: "left" | "center";
  shadow?: "center";
  children: React.ReactNode;
}

const XSection: React.FC<XSectionProps> = ({
  title,
  subtitle,
  topTitle,
  children,
  align,
  shadow,
}) => {
  return (
    <div
      className={`${align === "left" ? "text-left" : "text-center"} relative`}
    >
      {topTitle && <h4 className="text-lg font-light m-0">{topTitle}</h4>}
      <h3 className="text-xl font-extralight m-0">{title}</h3>
      {subtitle && <p className="mt-[12px]">{subtitle}</p>}
      <div className="text-left mt-[60px]">{children}</div>
      {shadow === "center" && (
        <div className="absolute w-full h-[200px] bottom-0 left-0 right-0 margin-auto bg-primary opacity-20 blur-[250px]"></div>
      )}
    </div>
  );
};

export default XSection;

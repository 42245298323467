import React from "react";

interface XFButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  link?: string;
  icon?: string;
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  size?: "sm" | "md" | "lg";
  isExternal?: boolean;
  width?: string | number;
}

const XFButton = ({
  variant = "primary",
  size = "lg",
  className = "",
  link,
  isExternal,
  onClick,
  disabled,
  ...props
}: XFButtonProps) => {
  const handleCardClick = () => {
    window.open(link, isExternal ? "_blank" : "_self", "noopener,noreferrer");
  };

  const state = disabled ? "disabled" : "default";

  const baseClasses =
    "flex items-center justify-center gap-2 rounded-[60px] font-medium cursor-pointer transition-all duration-500 transform whitespace-nowrap leading-normal";

  const variantClasses = {
    primary: {
      default:
        "bg-primary border-primary border text-primary-text hover:bg-primary-hover",
      disabled:
        "bg-primary-disabled border-primary-disabled text-primary-disabled-text",
    },
    secondary: {
      default:
        "bg-transparent text-white border border-white hover:bg-white hover:text-black",
      disabled: "border-primary-disabled text-primary-disabled",
    },
    tertiary: {
      default: "bg-[#808080]/20 text-white hover:bg-[#808080]/40",
      disabled: "bg-[#808080]/15 text-white/30",
    },
    quaternary: {
      default: "bg-transparent text-primary",
      disabled: "bg-[#808080]/15 text-white/30",
    },
  };

  const sizeClasses = {
    sm: "text-2xs px-6 py-1.5",
    md: "text-xs px-6 py-2",
    lg: "text-sm px-9 py-3.5",
  };

  const resolvedClasses = [
    baseClasses,
    variant !== "quaternary" ? sizeClasses[size] : "", // Remove padding if quaternary
    variantClasses[variant][state],
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <button
      onClick={link ? handleCardClick : onClick}
      className={resolvedClasses}
      disabled={disabled}
      style={{ width: props.width || "fit-content" }}
      {...props}
    >
      {props.icon && (
        <img src={props.icon} alt={props.title} className="w-[20px] h-[20px]" />
      )}
      <span>{props.title}</span>
    </button>
  );
};

export default XFButton;

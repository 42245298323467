export const labelMap: Record<string, React.ReactNode> = {
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  YOUTUBE: "Youtube",
  TWITTER: "X",
  TIKTOK: "Tiktok",
  TELEGRAM: "Telegram",
  "SOUND-CLOUD": "Sound Cloud",
  SPOTIFY: "Spotify",
  "APPLE-MUSIC": "Apple Music",
  WHATSAPP: "Whatsapp",
  "ONLY-FANS": "Only Fans",
  // "AMAZON-MUSIC": <AiFillAmazonCircle />,
  // PINTEREST: <RiPinterestLine />,
  // YELP: <FaYelp />,
  // LINKEDIN: <AiFillLinkedin />,
  // SNAPCHAT: <RiSnapchatFill />,
  // TWITCH: <RiTwitchFill />,
  // GOOGLE: <AiOutlineGoogle />,
};

// Icon map for each platform
export const iconMap: Record<string, React.ReactNode> = {
  FACEBOOK: "s-facebook.svg",
  INSTAGRAM: "s-instagram.svg",
  YOUTUBE: "s-youtube.svg",
  TWITTER: "s-x.svg",
  TIKTOK: "s-tiktok.svg",
  TELEGRAM: "s-telegram.svg",
  "SOUND-CLOUD": "s-soundcloud.svg",
  SPOTIFY: "s-spotify.svg",
  "APPLE-MUSIC": "s-apple-music.svg",
  WHATSAPP: "s-whatsapp.svg",
  "ONLY-FANS": "s-of.svg",
  LINK: "s-link.svg",
  // "AMAZON-MUSIC": <AiFillAmazonCircle />,
  // PINTEREST: <RiPinterestLine />,
  // YELP: <FaYelp />,
  // LINKEDIN: <AiFillLinkedin />,
  // SNAPCHAT: <RiSnapchatFill />,
  // TWITCH: <RiTwitchFill />,
  // GOOGLE: <AiOutlineGoogle />,
};

export const colorMap: Record<string, { stroke: string; bg: string }> = {
  FACEBOOK: {
    stroke: "#337FFF",
    bg: "#0B1A34",
  },
  INSTAGRAM: {
    stroke: "#903AA5",
    bg: "#230F1C",
  },
  YOUTUBE: {
    stroke: "#FF3000",
    bg: "#340A01",
  },
  TWITTER: {
    stroke: "#FFFFFF",
    bg: "#343434",
  },
  TIKTOK: {
    stroke: "#EE1D52",
    bg: "#300711",
  },
  TELEGRAM: {
    stroke: "#34AADF",
    bg: "#0B232D",
  },
  "SOUND-CLOUD": {
    stroke: "#FF6D38",
    bg: "#34170C",
  },
  SPOTIFY: {
    stroke: "#E53650",
    bg: "#2F0C11",
  },
  "APPLE-MUSIC": {
    stroke: "#E53650",
    bg: "#2F0C11",
  },
  WHATSAPP: {
    stroke: "#10BC4C",
    bg: "#042610",
  },
  "ONLY-FANS": {
    stroke: "#008CCF",
    bg: "#011D2A",
  },
  LINK: {
    stroke: "#FFFFFF",
    bg: "#343434",
  },
};

export const detectPlatform = (url: string): string => {
  if (url.includes("facebook.com")) return "FACEBOOK";
  if (url.includes("instagram.com")) return "INSTAGRAM";
  if (url.includes("youtube.com")) return "YOUTUBE";
  if (url.includes("x.com")) return "TWITTER";
  if (url.includes("tiktok.com")) return "TIKTOK";
  if (url.includes("telegram.me")) return "TELEGRAM";
  if (url.includes("soundcloud.com")) return "SOUND-CLOUD";
  if (url.includes("spotify.com")) return "SPOTIFY";
  if (url.includes("music.apple.com")) return "APPLE-MUSIC";
  if (url.includes("whatsapp.com")) return "WHATSAPP";
  if (url.includes("onlyfans.com")) return "ONLY-FANS";
  if (url.includes("music.amazon.com")) return "AMAZON-MUSIC";
  if (url.includes("pinterest.com")) return "PINTEREST";
  // if (url.includes("yelp.com")) return "YELP";
  if (url.includes("linkedin.com")) return "LINKEDIN";
  if (url.includes("snapchat.com")) return "SNAPCHAT";
  if (url.includes("twitch.tv")) return "TWITCH";
  if (url.includes("google.com")) return "GOOGLE";
  return "LINK"; // Default case if no match
};

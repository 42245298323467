import { IconButton } from "@mui/material";
import { Link } from "react-router";
import {
  colorMap,
  detectPlatform,
  iconMap,
  labelMap,
} from "utils/detectPlatform";

interface XSocialsProps {
  variant?: "bw";
  socialMedia: {
    url: string;
    order?: number;
    isPinned?: boolean;
  }[];
}

interface XSocialProps {
  to: string;
  icon: string;
}

export const XSocial = (props: XSocialProps) => {
  return (
    <Link
      to={props.to}
      className="flex flex-col w-[60px] h-[60px] items-center justify-center rounded-[50%] bg-red/20"
    >
      <img src={props.icon} width={40} />
    </Link>
  );
};

export const XSocialsPinned = (props: XSocialsProps) => {
  return (
    <div className="flex items-center gap-5 justify-center md:justify-start">
      {props.socialMedia
        .filter((x) => x.isPinned)
        .map((x, i) => {
          const platform = detectPlatform(x.url);
          const icon = iconMap[platform] || null;
          let color = colorMap[platform];

          if (props.variant == "bw")
            color = {
              stroke: "transparent",
              bg: "rgba(255, 255, 255, 0.1)",
            };
          return (
            <Link to={x.url} key={i} style={{}}>
              <IconButton
                aria-label="edit"
                sx={{
                  background: color?.bg,
                  border: props.variant === "bw" ? 0 : `1px solid ${color?.bg}`,
                  padding: "12px",
                  borderRadius: "50%",
                  transition: "transform 1s ease-in-out, border-color 1s ease",
                  "&:hover": {
                    borderColor: color?.stroke,
                  },
                }}
                className="transform transition-transform duration-300 ease-in-out hover:scale-110"
              >
                <img
                  src={`/assets/icons/${props.variant == "bw" ? "bw-" : ""}${icon}`}
                />
              </IconButton>
            </Link>
          );
        })}
    </div>
  );
};

export const XSocials = (props: XSocialsProps) => {
  return (
    <div className="flex items-center gap-5 flex-wrap">
      {props.socialMedia.map((x, i) => {
        const platform = detectPlatform(x.url);
        const icon = iconMap[platform];
        const color = colorMap[platform];
        const label = labelMap[platform] || null;

        console.log("platform", platform, "icon", icon, "color", color);
        return (
          <Link to={x.url} key={i}>
            <IconButton
              aria-label="edit"
              sx={{
                background: color?.bg || "#000",
                border: `1px solid ${color?.bg || "#000"}`,
                transition: "transform 1s ease-in-out, border-color 1s ease",
                "&:hover": {
                  borderColor: color?.stroke || "#fff",
                },
              }}
              className="rounded-xl text-sm capitalize pl-4 pr-6"
            >
              <img src={`/assets/icons/${icon}`} /> {label}
            </IconButton>
          </Link>
        );
      })}
    </div>
  );
};

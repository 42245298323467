import { useMutation } from "@tanstack/react-query";
import { deleteLeadAPI } from "shared/service/Lead";
import XConfirmationModal from "components/XConfirmationModal";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { useSnackbar } from "hooks/useSnackbar";

interface LeadDeleteModalProps {
  onSuccess: () => void;
  onClose: () => void;
  leadId: string;
}

const LeadDeleteModal = (props: LeadDeleteModalProps) => {
  const { showSnackbar } = useSnackbar();
  const { mutate } = useMutation({
    mutationKey: ["LeadDeleteModal.delete", props.leadId],
    mutationFn: deleteLeadAPI,
    onSuccess: () => {
      props.onSuccess();
      props.onClose();
      showSnackbar({
        message: generateMutationMessage("Lead", "delete", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Lead", "delete", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  return (
    <XConfirmationModal
      title="Delete Lead"
      message="Are you sure you want to delete this artist?"
      onConfirm={() =>
        mutate({
          lead: { id: props.leadId },
        })
      }
      onClose={props.onClose}
    />
  );
};

export default LeadDeleteModal;

import { useMutation } from "@tanstack/react-query";
import { XFForm, XFTextField, XFTextarea } from "components/form";
import XModal from "components/XModal";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "hooks/useSnackbar";
import { CreatePackageInput } from "shared/API";
import { createPackageAPI } from "shared/service/Package";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { schemaArtistCreatePackage } from "utils/validation/schema";

interface ArtistPackageCreateModalProps {
  vendorId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const initialValues: FormikValues = {
  eventType: "",
  services: "",
  discount: "",
  title: "",
  description: "",
};

const getCreatePackageInput = (
  values: FormikValues,
  vendorId: string
): {
  vendorId: string;
  package: CreatePackageInput;
} => ({
  vendorId,
  package: {
    title: values?.title,
    eventType: values?.eventType,
    services: values?.services,
    discount: values?.discount,
  },
});

const ArtistPackageCreateModal = ({
  vendorId,
  onSuccess,
  onClose,
}: ArtistPackageCreateModalProps) => {
  const { showSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationKey: ["ArtistPackageUpdateModal.createPackage", vendorId],
    mutationFn: createPackageAPI,
    onSuccess: () => {
      onClose();
      onSuccess();
      showSnackbar({
        message: generateMutationMessage("Package", "update", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Package", "update", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    mutate(getCreatePackageInput(values, vendorId), {
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaArtistCreatePackage}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <XModal
          title="Create Package"
          onClose={onClose}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField name="eventType" label="Event Type" />
              <XFTextField name="services" label="Services" />
              <XFTextField name="discount" label="Discount" />
              <XFTextField name="title" label="Title" />
              <XFTextarea name="description" label="Description" />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default ArtistPackageCreateModal;

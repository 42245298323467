import React from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import XFForm from "./XFForm";
import XFTextField from "./XFTextField";
import XFButton from "./XFButton";

interface SocialMedia {
  platform: string;
  url: string;
}

interface FormValues {
  socialMedia: SocialMedia[];
}

// Icon map for each platform
const iconMap: Record<string, React.ReactNode> = {
  FACEBOOK: <img src="/assets/icons/s-facebook.svg" />,
  INSTAGRAM: <img src="/assets/icons/s-instagram.svg" />,
  YOUTUBE: <img src="/assets/icons/s-youtube.svg" />,
  TWITTER: <img src="/assets/icons/s-x.svg" />,
  TIKTOK: <img src="/assets/icons/s-tiktok.svg" />,
  TELEGRAM: <img src="/assets/icons/s-telegram.svg" />,
  "SOUND-CLOUD": <img src="/assets/icons/s-telegram.svg" />,
  SPOTIFY: <img src="/assets/icons/s-telegram.svg" />,
  "APPLE-MUSIC": <img src="/assets/icons/s-apple-music.svg" />,
  WHATSAPP: <img src="/assets/icons/s-whatsapp.svg" />,
  "ONLY-FANS": <img src="/assets/icons/s-of.svg" />,
  // "AMAZON-MUSIC": <AiFillAmazonCircle />,
  // PINTEREST: <RiPinterestLine />,
  // YELP: <FaYelp />,
  // LINKEDIN: <AiFillLinkedin />,
  // SNAPCHAT: <RiSnapchatFill />,
  // TWITCH: <RiTwitchFill />,
  // GOOGLE: <AiOutlineGoogle />,
};

// Utility function to detect platform
const detectPlatform = (url: string): string => {
  if (url.includes("facebook.com")) return "FACEBOOK";
  if (url.includes("instagram.com")) return "INSTAGRAM";
  if (url.includes("youtube.com")) return "YOUTUBE";
  if (url.includes("twitter.com")) return "TWITTER";
  if (url.includes("tiktok.com")) return "TIKTOK";
  if (url.includes("telegram.me")) return "TELEGRAM";
  if (url.includes("soundcloud.com")) return "SOUND-CLOUD";
  if (url.includes("spotify.com")) return "SPOTIFY";
  if (url.includes("music.apple.com")) return "APPLE-MUSIC";
  if (url.includes("whatsapp.com")) return "WHATSAPP";
  if (url.includes("onlyfans.com")) return "ONLY-FANS";
  if (url.includes("music.amazon.com")) return "AMAZON-MUSIC";
  if (url.includes("pinterest.com")) return "PINTEREST";
  if (url.includes("yelp.com")) return "YELP";
  if (url.includes("linkedin.com")) return "LINKEDIN";
  if (url.includes("snapchat.com")) return "SNAPCHAT";
  if (url.includes("twitch.tv")) return "TWITCH";
  if (url.includes("google.com")) return "GOOGLE";
  return ""; // Default case if no match
};

const XFSocialMedia: React.FC = () => {
  const initialValues: FormValues = {
    socialMedia: [{ platform: "", url: "" }],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        socialMedia: Yup.array()
          .of(
            Yup.object({
              platform: Yup.string(),
              url: Yup.string().url("Invalid URL").required("URL is required"),
            })
          )
          .min(1, "At least one social media field is required"),
      })}
      onSubmit={(values) => {
        console.log("Form Values:", values);
        // Save `values.socialMedia` to the database
      }}
    >
      {({ values }) => (
        <XFForm>
          <FieldArray name="socialMedia">
            {({ push, remove }) => (
              <div>
                {values.socialMedia.map((item, index) => {
                  const detectedPlatform = detectPlatform(item.url);
                  const icon = iconMap[detectedPlatform] || null;

                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "1rem",
                      }}
                    >
                      <div>{icon}</div>
                      <XFTextField
                        name={`socialMedia[${index}].url`}
                        label="Social Media URL"
                        placeholder="Enter URL"
                      />
                      {values.socialMedia.length > 1 && (
                        <button type="button" onClick={() => remove(index)}>
                          -
                        </button>
                      )}
                    </div>
                  );
                })}
                <XFButton
                  title="+ Add Social Media"
                  type="button"
                  onClick={() => push({ platform: "", url: "" })}
                  variant="secondary"
                />
              </div>
            )}
          </FieldArray>
          <div style={{ marginTop: "2rem" }}>
            <button type="submit">Submit</button>
          </div>
        </XFForm>
      )}
    </Formik>
  );
};

export default XFSocialMedia;

import { XIconed } from "components/common/XIconed";
import { iLocation, iRight } from "components/common/XIcons";
import { truncateTooltip } from "utils/formatter/truncate";
import XEventDate from "./event/XEventDate";
import XTruncate from "./XTruncate";

interface XEventItemProps {
  title: string;
  imageURL?: string;
  location?: string;
  date?: Date;
  isMobile?: boolean;
}

export const XEventItem = ({
  title,
  imageURL,
  location,
  date,
  isMobile,
}: XEventItemProps) => {
  return isMobile ? (
    <div className="flex flex-row items-center gap-3 py-4">
      {date && <XEventDate date={date} variant="dark" />}
      <div className="flex flex-col flex-1 w-full gap-1">
        <div className="text-sm font-medium">
          <XTruncate text={title} />
        </div>
        <div className="flex items-center gap-1">
          <img src={iLocation} width={16} />
          <span className="text-2xs text-x-heading2/70 leading-[10px]">
            {location}
          </span>
        </div>
      </div>
      <img src={iRight} className="min-w-[20px]" />
    </div>
  ) : (
    <div className="flex flex-col p-2 gap-3">
      <div
        className="flex items-center w-full md:w-[222px] md:max-w-[222px] md:h-[222px] bg-center bg-cover rounded-[10px] relative"
        style={{
          backgroundImage: `url(${imageURL})`,
        }}
      >
        {date && <XEventDate date={date} variant="light" />}
      </div>
      <div className="text-sm font-medium">{truncateTooltip(title, 19)}</div>
      {location && (
        <div className="text-sm text-white/70">
          <XIconed icon={iLocation} text={location} />
        </div>
      )}
    </div>
  );
};

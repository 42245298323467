import {
  isEmailValidRegex,
  isPhoneNumberRegex,
  isUrlValidRegex,
} from "shared/service/utils/regex";
import * as yup from "yup";

export const yupEmail = yup
  .string()
  .matches(isEmailValidRegex, "Email is not valid")
  .required("Required");
export const yupStringReq = (fieldName?: string) =>
  yup.string().required(`${fieldName || "This field"} is required`);

export const schemaRegister = yup.object().shape({
  email: yup.string().email(),
  password: yup.string(),
  confirmPassword: yup.string(),
});

export const schemaArtistCreate = yup.object().shape({
  username: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Username is required"),
  displayName: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Display Name is required"),
  headline: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Headline is required"),
});

export const schemaArtistUpdate = yup.object().shape({
  username: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Username is required"),
  displayName: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Display Name is required"),
  headline: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Headline is required"),
  description: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Description is required"),
  contactEmail: yup
    .string()
    .matches(isEmailValidRegex, "Email is not valid")
    .required("Email is required"),
  contactPhone: yup
    .string()
    .matches(isPhoneNumberRegex, "Phone number is not valid")
    .required("Phone number is required"),
  website: yup.string().matches(isUrlValidRegex, "Please enter a valid URL"),
});

export const schemaEventCreate = yup.object().shape({
  title: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Required"),
  ticketLink: yup.string().matches(isUrlValidRegex, "Please enter a valid URL"),
  description: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Description is required"),
  // startTimestamp: yup
  //   .date()
  //   .min(new Date(), "Start time must be in the future")
  //   .required("Date is required"),
  // endTimestamp: yup
  //   .date()
  //   .min(yup.ref("startDate"), "End time must be later than start time"),
  // contactEmail: yup
  //   .string()
  //   .matches(isEmailValidRegex, "Email is not valid")
  //   .required("Email is required"),
  // contactPhone: yup
  //   .string()
  //   .matches(isPhoneNumberRegex, "Phone number is not valid")
  //   .required("Phone number is required"),
});

export const schemaLogin = yup.object().shape({
  email: yupEmail,
  password: yupStringReq("Password"),
});

export const schemaArtistUpdateContact = yup.object().shape({
  email: yupEmail,
  phone: yupStringReq("Phone Number"),
});

export const schemaArtistUpdateAddress = yup.object().shape({});
export const schemaArtistTabPersonalInfo = yup.object().shape({
  username: yup
    .string()
    .min(3, "Must have 3 or more characters")
    .required("Username is required"),
});
export const schemaArtistUpdateSocialMedia = yup.object({});
export const schemaArtistUpdatePicture = yup.object().shape({});
export const schemaArtistTabHighlights = yup.object().shape({});
export const schemaArtistCreateService = yup.object().shape({});
export const schemaArtistUpdateService = yup.object().shape({});
export const schemaArtistDeleteService = yup.object().shape({});
export const schemaArtistCreatePackage = yup.object().shape({});
export const schemaArtistUpdatePackage = yup.object().shape({});
export const schemaArtistDeletePackage = yup.object().shape({});
export const schemaLeadCreate = yup.object().shape({});
export const schemaLeadUpdate = yup.object().shape({});
export const schemaLeadDelete = yup.object().shape({});
export const schemaEventUpdate = yup.object().shape({});
export const schemaEventDelete = yup.object().shape({});

export const bookStep1ValidationSchema = yup.object().shape({});
export const bookStep2ValidationSchema = yup.object().shape({});
export const bookStep3ValidationSchema = yup.object().shape({});
// export const bookStep1ValidationSchema = yup.object({
//   eventType: yup.string().required("Event Type is required"),
//   eventTitle: yup.string().required("Event Title is required"),
// });

// export const bookStep2ValidationSchema = yup.object({
//   eventType: yup.string().required("Event Type is required"),
//   eventTitle: yup.string().required("Event Title is required"),
// });

// export const bookStep3ValidationSchema = yup.object({
//   eventType: yup.string().required("Event Type is required"),
//   eventTitle: yup.string().required("Event Title is required"),
// });

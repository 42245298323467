import React from "react";
import { IconButton, Modal } from "@mui/material";
import { XFButton } from "components/form";
import { iX } from "./common/XIcons";

interface XModalProps {
  onClose: () => void;
  onSubmit: () => void;
  renderContent: () => React.ReactNode;
  title?: string;
  open?: boolean;
  submitText?: string;
  cancelText?: string;
  isSubmitting?: boolean;
  isConfirmation?: boolean;
}

const XModal = ({
  open,
  onClose,
  onSubmit,
  title = "Modal Title",
  renderContent,
  submitText = "Submit",
  cancelText = "Cancel",
  isSubmitting = false,
  isConfirmation = false,
}: XModalProps) => {
  console.log(open);
  return (
    <Modal
      open={true}
      onClose={onClose}
      className="w-[600px] flex items-center mx-auto"
      classes={{
        backdrop: "bg-x-gray2/40",
      }}
    >
      <div className="w-full mx-auto bg-black rounded-xl ">
        {/* HEADER */}
        <div
          className={`flex justify-between items-start px-[40px] py-[30px] ${isConfirmation ? "border-0" : "border-b"} border-x-gray3`}
        >
          <h5 className="capitalize m-0">{title}</h5>
          <IconButton onClick={onClose}>
            <img src={iX} />
          </IconButton>
        </div>

        {/* BODY */}
        <div
          className={`max-h-[50vh] overflow-y-scroll ${isConfirmation ? "py-0" : "py-[30px]"} px-[40px]`}
        >
          {renderContent()}
        </div>

        {/* FOOTER */}
        <div
          className={`flex justify-end gap-[20px] px-[40px] py-[30px] ${isConfirmation ? "border-0" : "border-t"} border-x-gray3`}
        >
          <XFButton
            title={cancelText}
            type="button"
            variant="secondary"
            size="lg"
            onClick={onClose}
            disabled={isSubmitting}
            className="flex-1"
          />
          <XFButton
            title={submitText}
            type="submit"
            size="lg"
            onClick={onSubmit}
            disabled={isSubmitting}
            className="flex-1"
          />
        </div>
      </div>
    </Modal>
  );
};

export default XModal;

export function extractDate(timestamp: string | number | Date): string {
  const date = new Date(timestamp);
  return formatDate(date);
}

export function extractTime(timestamp: string | number | Date): string {
  const date = new Date(timestamp);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  }); // Returns "3:45 PM"
}

export function extractDateTime(timestamp: string | number | Date): string {
  const date = new Date(timestamp);
  return `${formatDate(date)} ${extractTime(timestamp)}`; // Returns "Feb 15th, 2025 3:45 PM"
}

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Add ordinal suffix (st, nd, rd, th)
  const day = date.getDate();
  const suffix = getDaySuffix(day);

  return formattedDate.replace(/\d+/, `${day}${suffix}`);
}

function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

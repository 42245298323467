import { useState } from "react";

export interface XCarouselItem {
  title?: string;
  subtitle?: string;
  description?: string;
  image?: string;
  type?: "text" | "image";
  width: number;
}

interface XCarouselProps {
  items: XCarouselItem[];
}

const XCarousel: React.FC<XCarouselProps> = ({ items }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const handleSlide = (direction: "left" | "right") => {
    const newSlide =
      direction === "left"
        ? Math.max(0, activeSlide - 1)
        : Math.min(items.length - 1, activeSlide + 1);
    setActiveSlide(newSlide);
  };

  const getTranslateX = (): number => {
    let translation = 0;
    for (let i = 0; i < activeSlide; i++) {
      translation += items[i].width + 24; // account for gap-6 (24px)
    }
    if (activeSlide === items.length - 1) translation -= 480;
    return translation;
  };

  return (
    <div className="relative w-full overflow-hidden mb-[120px]">
      <div
        className="flex gap-6 transition-transform duration-500"
        style={{ transform: `translateX(-${getTranslateX()}px)` }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col flex-shrink-0 justify-end rounded-md"
            style={{
              width: `${item.width}px`,
              height: "300px",
              ...(item.type !== "text" && item.image
                ? {
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
                : {}),
            }}
          >
            {item.type === "text" ? (
              <div className="p-0">
                {item.description && (
                  <p className="text-xs text-x-heading2">{item.description}</p>
                )}
              </div>
            ) : (
              <div className="p-7">
                {item.title && (
                  <h3 className="text-xl text-x-heading1 font-regular m-0">
                    {item.title}
                  </h3>
                )}
                {item.subtitle && (
                  <p className="text-xs text-x-heading2 font-light m-0 max-w-[420px]">
                    {item.subtitle}
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {activeSlide > 0 && (
        <>
          <button
            onClick={() => handleSlide("left")}
            className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2 text-white"
          >
            <img src="/assets/icons/x-arrow-left.svg" alt="Left arrow" />
          </button>
          {activeSlide === items.length - 1 && (
            <div className="absolute z-2 w-[100px] h-[300px] top-0 left-[-60px] bg-gradient-to-r from-black via-black/100 to-black/0"></div>
          )}
        </>
      )}

      {activeSlide < items.length - 1 && (
        <>
          <button
            onClick={() => handleSlide("right")}
            className="absolute z-10 right-4 top-1/2 transform -translate-y-1/2 text-white"
          >
            <img src="/assets/icons/x-arrow-right.svg" alt="Right arrow" />
          </button>
          <div className="absolute z-2 w-[100px] h-[300px] top-0 right-[-60px] bg-gradient-to-l from-black via-black/100 to-black/0"></div>
        </>
      )}
    </div>
  );
};

export default XCarousel;

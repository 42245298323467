import { useMutation } from "@tanstack/react-query";
import XConfirmationModal from "components/XConfirmationModal";
import { deleteServiceAPI } from "shared/service/Service";

interface ArtistServiceDeleteModalProps {
  onSuccess: () => void;
  onClose: () => void;
  serviceId: string;
  vendorId: string;
}

const ArtistServiceDeleteModal = ({
  onSuccess,
  onClose,
  serviceId,
  vendorId,
}: ArtistServiceDeleteModalProps) => {
  const { mutate } = useMutation({
    mutationKey: ["ArtistServiceDeleteModal.delete", serviceId],
    mutationFn: deleteServiceAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to delete the service: ", error);
    },
  });

  return (
    <XConfirmationModal
      title="Delete Service"
      message="Are you sure you want to delete this service?"
      onConfirm={() =>
        mutate({
          vendorId: vendorId,
          service: { id: serviceId },
        })
      }
      onClose={onClose}
    />
  );
};

export default ArtistServiceDeleteModal;

import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { IconButton, TextField, Tooltip } from "@mui/material";
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
} from "@tanstack/react-query";
import { iAddRed, iDelete, iDrag } from "components/common/XIcons";
import XDragSortContext from "components/drag/XDragSortContext";
import XDragSortItem from "components/drag/XDragSortItem";
import { XFButton, XFForm, XFRow, XFTextField } from "components/form";
import XFError from "components/form/XFError";
import { FieldArray, Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { paths } from "router/routes";
import { SocialMedia, Vendor } from "shared/API";
import { updateVendorAPI } from "shared/service/Vendor";
import { detectPlatform, iconMap } from "utils/detectPlatform";
import { schemaArtistUpdateSocialMedia } from "utils/validation/schema";

interface SocialMediaWithId extends SocialMedia {
  id: string;
  order: number;
}

const ArtistUpdateSocialMedia = ({
  artist,
  refetch,
}: {
  artist: Vendor;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<Vendor, Error>>;
}) => {
  const [allItems, setAllItems] = useState<SocialMediaWithId[]>([]);
  const [orderChanged, setOrderChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const { ownerId } = useParams();

  const { mutate, error } = useMutation({
    mutationKey: ["ArtistUpdateSocialMedia.updateVendor", artist.id],
    mutationFn: updateVendorAPI,
    onSuccess: () => {
      refetch();
      navigate(paths.Artists.list(ownerId));
    },
  });

  // Ensure each item gets an `id` and `order` field
  useEffect(() => {
    if (artist.socialMedia) {
      setAllItems(
        artist.socialMedia
          .map((item, i) => ({
            ...item,
            id: `social-${i}`, // Generate unique ID if missing
            order: i, // Assign initial order based on index
          }))
          .sort((a, b) => a.order - b.order) // Ensure consistent ordering
      );
    }
  }, [artist.socialMedia]);

  const initialValues: FormikValues = {
    socialMedia: allItems.map(({ id, url, isPinned }) => ({
      id,
      url,
      isPinned: isPinned || false, // Ensure boolean value
    })),
  };

  // Handle Form Submission
  const handleSubmit = (values: FormikValues) => {
    const processedSocialMedia = values.socialMedia.map(
      (x: SocialMediaWithId, i: number) => ({
        ...x,
        url: x.url.startsWith("https://") ? x.url : `https://${x.url}`,
        order: i, // Assign order during submission
      })
    );

    const changedFields = {
      socialMedia: processedSocialMedia,
    };

    mutate({
      vendor: {
        id: artist.id,
        ...changedFields,
      },
      isPrivate: Boolean(ownerId),
    });
  };

  // Handle Drag and Drop Sorting
  const handleDragEnd = (
    event: DragEndEvent,
    values: FormikValues,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    setAllItems((items) => {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      if (oldIndex === -1 || newIndex === -1) return items;

      const newItems = arrayMove(items, oldIndex, newIndex).map((item, i) => ({
        ...item,
        order: i, // Reassign order after sorting
      }));

      setFieldValue("socialMedia", newItems); // Sync with Formik state
      return newItems;
    });

    setOrderChanged(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaArtistUpdateSocialMedia}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, isSubmitting, dirty, resetForm }) => (
        <XFForm>
          {error && <XFError message={error.message} />}
          <FieldArray name="socialMedia">
            {({ push, remove }) => (
              <div className="flex flex-col gap-8 max-w-[650px]">
                <XDragSortContext
                  allItems={allItems}
                  onDragEnd={(event) =>
                    handleDragEnd(event, values, setFieldValue)
                  }
                >
                  {values.socialMedia.map((x: SocialMediaWithId, i: number) => {
                    const platform = detectPlatform(x.url);
                    const icon = iconMap[platform] || null;

                    return (
                      <XDragSortItem id={x.id} key={x.id}>
                        <div className="flex items-center gap-6">
                          <img src={iDrag} />
                          <XFTextField
                            name={`socialMedia[${i}].url`}
                            placeholder="Enter URL"
                            onChange={(e) => {
                              const updatedUrl = (e.target as HTMLInputElement)
                                .value;
                              setFieldValue(
                                `socialMedia[${i}].url`,
                                updatedUrl
                              );
                            }}
                            icon={
                              <img src={`/assets/icons/${icon}`} width={30} />
                            }
                          />
                          <TextField
                            className="hidden"
                            name={`socialMedia[${i}].isPinned`}
                          />
                          <IconButton onClick={() => remove(i)}>
                            <img src={iDelete} />
                          </IconButton>
                          <Tooltip title={x.isPinned ? "Unpin" : "Pin"}>
                            <IconButton
                              onClick={() =>
                                setFieldValue(
                                  `socialMedia[${i}].isPinned`,
                                  !x.isPinned
                                )
                              }
                            >
                              <img
                                src={`/assets/icons/x-${x.isPinned ? "pinned" : "pin"}.svg`}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </XDragSortItem>
                    );
                  })}
                </XDragSortContext>
                <XFButton
                  title="Add Social Media"
                  type="button"
                  onClick={() => {
                    const newItem: SocialMediaWithId = {
                      url: "",
                      isPinned: false,
                      id: `social-${allItems.length}`,
                      order: allItems.length, // Ensure new items have an order
                    };
                    push(newItem);
                    setAllItems((prev) => [...prev, newItem]);
                  }}
                  variant="quaternary"
                  icon={iAddRed}
                />
              </div>
            )}
          </FieldArray>
          <br />
          {(dirty || orderChanged) && (
            <XFRow>
              <XFButton
                title="Save Changes"
                type="submit"
                size="lg"
                disabled={isSubmitting}
              />
              <XFButton
                title="Reset"
                type="button"
                onClick={() => resetForm()}
                size="lg"
                variant="secondary"
              />
            </XFRow>
          )}
        </XFForm>
      )}
    </Formik>
  );
};

export default ArtistUpdateSocialMedia;

import ArtistsPage from "pages/artist/ArtistsPage";
import EventsPage from "pages/event/EventsPage";
import Login from "pages/Login";
import Register from "pages/Register";
import ArtistDetailsPage from "pages/artist/ArtistPage";
import ArtistUpdatePage from "pages/artist/actions/ArtistUpdatePage";
import Layout from "Layout";
import LayoutNaked from "LayoutNaked";
import MyArtistsPage from "pages/artist/MyArtistsPage";
import MyEventsPage from "pages/event/MyEventsPage";
import EventPage from "pages/event/EventPage";
import MyLeadsPage from "pages/booking/MyLeadsPage";
import BookPage from "pages/booking/BookPage";
import HomePage from "pages/HomePage";

export const paths = {
  Home: "/",
  Login: "/login",
  Register: "/register",
  Events: {
    list: (ownerId?: string) => `/events${ownerId ? `/${ownerId}` : ""}`,
    details: (id: string, ownerId?: string) =>
      `/event/${id}${ownerId ? `/${ownerId}` : ""}`,
    create: (ownerId?: string) => `/event/create/${ownerId}`,
    update: (id: string, ownerId?: string) =>
      `/event/update/${id}${ownerId ? `/${ownerId}` : ""}`,
  },
  Artists: {
    list: (ownerId?: string) => `/artists${ownerId ? `/${ownerId}` : ""}`,
    details: (id: string, ownerId?: string) =>
      `/artist/${id}${ownerId ? `/${ownerId}` : ""}`,
    create: "/artist/create",
    update: (id: string, ownerId?: string) =>
      `/artist/update/${id}${ownerId ? `/${ownerId}` : ""}`,
    book: (id: string) => `/book/${id}`,
  },
  Leads: {
    list: (ownerId: string) => `/leads/${ownerId}`,
    details: (id: string, ownerId?: string) =>
      `/lead/${id}${ownerId ? `/${ownerId}` : ""}`,
    create: "/lead/create",
    update: (id: string, ownerId?: string) =>
      `/lead/update/${id}${ownerId ? `/${ownerId}` : ""}`,
  },
};

export const routes = [
  {
    path: paths.Home,
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "artists/",
        element: <ArtistsPage />,
      },
      {
        path: "artists/:ownerId/", // view artist with just id
        element: <MyArtistsPage />,
      },
      {
        path: "artist/:id/", // view artist with just id
        element: <ArtistDetailsPage />,
      },
      {
        path: "artist/:id/:ownerId/", // view private artist with both id and ownerId
        element: <ArtistDetailsPage />,
      },
      {
        path: "artist/update/:id/", // update artist with just id
        element: <ArtistUpdatePage />,
      },
      {
        path: "artist/update/:id/:ownerId/", // update private artist with both id and ownerId
        element: <ArtistUpdatePage />,
      },
      {
        path: "book/:id/", // booking vendors
        element: <BookPage />,
      },
      {
        path: "events/",
        element: <EventsPage />,
      },
      {
        path: "events/:ownerId/", // view artist with just id
        element: <MyEventsPage />,
      },
      {
        path: "event/:id",
        element: <EventPage />,
      },
      {
        path: "leads/:ownerId/", // view leads that are owned by the logged in user
        element: <MyLeadsPage />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutNaked />,
    children: [
      {
        path: paths.Login,
        element: <Login />,
      },
      {
        path: paths.Register,
        element: <Register />,
      },
    ],
  },
];

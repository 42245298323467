import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "hooks/useSnackbar";
import { createLeadAPI, updateLeadAPI } from "shared/service/Lead";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { FormikValues } from "formik";
import XFMultiStepForm, { StepConfig } from "components/form/XFMultiStepForm";
import { CreateLeadInput, UpdateLeadInput } from "shared/API";
import BookStep1 from "./steps/BookStep1";
import BookStep2 from "./steps/BookStep2";
import BookStep3 from "./steps/BookStep3";
import {
  bookStep1ValidationSchema,
  bookStep2ValidationSchema,
  bookStep3ValidationSchema,
} from "utils/validation/schema";

const initialValues = {
  eventType: "",
  eventTitle: "",
  leadStatus: "",
  startTimestamp: "",
  endTimestamp: "",
  guests: "",
  leadId: null, // Store lead ID for updating later
};

const getCreateLeadInput = (
  values: FormikValues
): { lead: CreateLeadInput } => ({
  lead: {
    eventType: values.eventType,
    eventTitle: values.eventTitle,
    leadStatus: values.leadStatus,
    startTimestamp: values.startTimestamp,
    endTimestamp: values.endTimestamp,
    guests: values.guests,
  },
});

const getUpdateLeadInput = (
  values: FormikValues
): { lead: UpdateLeadInput } => ({
  lead: {
    id: values.leadId,
    eventType: values.eventType,
    eventTitle: values.eventTitle,
    leadStatus: values.leadStatus,
    startTimestamp: values.startTimestamp,
    endTimestamp: values.endTimestamp,
    guests: values.guests,
  },
});

const BookPage = () => {
  const { showSnackbar } = useSnackbar();

  const createLeadMutation = useMutation({
    mutationKey: ["LeadCreateModal.createLead"],
    mutationFn: createLeadAPI,
    onSuccess: () => {
      showSnackbar({
        message: generateMutationMessage("Lead", "create", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Lead", "create", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const updateLeadMutation = useMutation({
    mutationKey: ["LeadCreateModal.updateLead"],
    mutationFn: updateLeadAPI,
    onSuccess: () => {
      showSnackbar({
        message: generateMutationMessage("Lead", "update", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Lead", "update", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const handleStepSubmit = async (values: FormikValues, step: number) => {
    if (step === 0) {
      // Create Lead
      await createLeadMutation.mutateAsync(getCreateLeadInput(values));
    } else {
      // Update Lead
      await updateLeadMutation.mutateAsync(getUpdateLeadInput(values));
    }
  };

  const steps: StepConfig[] = [
    { component: BookStep1, validationSchema: bookStep1ValidationSchema },
    { component: BookStep2, validationSchema: bookStep2ValidationSchema },
    { component: BookStep3, validationSchema: bookStep3ValidationSchema },
  ];

  return (
    <XFMultiStepForm
      onStepSubmit={handleStepSubmit}
      initialValues={initialValues}
      steps={steps}
    />
  );
};

export default BookPage;

import Page from "components/Page";
import { useState } from "react";
import { eventsAPI } from "shared/service/Event";
import { paths } from "router/routes";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Link } from "react-router";
import XTable from "components/table/XTable";
import { useQuery } from "@tanstack/react-query";
import EventDeleteModal from "./actions/EventDeleteModal";
import EventUpdateModal from "./actions/EventUpdateModal";
import { iDelete, iEdit, iView } from "components/common/XIcons";

const EventsPage = () => {
  const [eventId, setEventId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);

  const { data: events, refetch } = useQuery({
    queryKey: ["EventsPage"],
    queryFn: () =>
      eventsAPI({
        query: {},
      }),
  });

  const rows =
    events?.items?.map((x) => ({
      id: x.id,
      title: x.title,
      createdAt: x.createdAt,
      imageUrl: x.imageUrl,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      minWidth: 240,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="h-[100%] flex items-center flex-1 gap-4">
            <img
              src={params.row.imageUrl}
              className="w-[44px] h-[44px] rounded-[50%]"
            />{" "}
            {params.row.displayName}
          </div>
        );
      },
    },
    { field: "id", headerName: "Event ID", minWidth: 240 },
    { field: "createdAt", headerName: "Created At", minWidth: 200 },
    {
      field: "actions",
      headerName: "",
      minWidth: 200,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <div className="flex items-center justify-end gap-2">
          <Link to={paths.Events.details(params.row.id)}>
            <IconButton>
              <img src={iView} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setModalName("update");
              setEventId(params.row.id);
            }}
          >
            <img src={iEdit} />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalName("delete");
              setEventId(params.row.id);
            }}
          >
            <img src={iDelete} />
          </IconButton>
        </div>
      ),
      flex: 1,
    },
  ];

  const onClose = () => {
    setEventId(null);
    setModalName(null);
  };

  return (
    <Page>
      <XTable rows={rows} columns={columns} rowHeight={76} title="Events" />
      {modalName === "update" && eventId && (
        <EventUpdateModal
          eventId={eventId}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
      {modalName === "delete" && eventId && (
        <EventDeleteModal
          eventId={eventId}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
    </Page>
  );
};

export default EventsPage;

export const iAdd = "/assets/icons/x-add.svg";
export const iAddRed = "/assets/icons/x-add-red.svg";
export const iArrow = "/assets/icons/x-arrow.svg";
export const iCalendar = "/assets/icons/x-calendar.svg";
export const iDelete = "/assets/icons/x-delete.svg";
export const iDoc = "/assets/icons/x-doc.svg";
export const iDown = "/assets/icons/x-down.svg";
export const iDrag = "/assets/icons/x-drag.svg";
export const iEdit = "/assets/icons/x-edit.svg";
export const iEditRed = "/assets/icons/x-edit-red.svg";
export const iEndorse = "/assets/icons/x-endorse.svg";
export const iFilter = "/assets/icons/x-filter.svg";
export const iHeart = "/assets/icons/x-heart.svg";
export const iHost = "/assets/icons/x-host.svg";
export const iLink = "/assets/icons/x-link.svg";
export const iLocation = "/assets/icons/x-location.svg";
export const iLocationWhite = "/assets/icons/x-location-white.svg";
export const iMenu = "/assets/icons/x-menu.svg";
export const iNotification = "/assets/icons/x-notification.svg";
export const iPin = "/assets/icons/x-pin.svg";
export const iPinned = "/assets/icons/x-pinned.svg";
export const iProfile = "/assets/icons/x-profile.svg";
export const iPublish = "/assets/icons/x-publish.svg";
export const iRight = "/assets/icons/x-right.svg";
export const iSearch = "/assets/icons/x-search.svg";
export const iSetting = "/assets/icons/x-setting.svg";
export const iTime = "/assets/icons/x-time.svg";
export const iUp = "/assets/icons/x-up.svg";
export const iView = "/assets/icons/x-view.svg";
export const iX = "/assets/icons/x-x.svg";

export const iCalendarRed = "/assets/icons/x-calendar-red.svg";
export const iGraphRed = "/assets/icons/x-graph-red.svg";
export const iFlowRed = "/assets/icons/x-flow-red.svg";
export const iHighlightRed = "/assets/icons/x-highlight-red.svg";
export const iMessageRed = "/assets/icons/x-message-red.svg";
export const iSocialRed = "/assets/icons/x-social-red.svg";
export const iInsightsRed = "/assets/icons/x-insights-red.svg";
export const iLinksRed = "/assets/icons/x-links-red.svg";
export const iEndorseRed = "/assets/icons/x-endorse-red.svg";
export const iServicesRed = "/assets/icons/x-services-red.svg";
export const iAiRed = "/assets/icons/x-ai-red.svg";
export const iChatRed = "/assets/icons/x-chat-red.svg";
export const iRecordRed = "/assets/icons/x-record-red.svg";

export const iPlanXWhite = "/images/iplanx-logo-white.png";
export const iPlanXBlack = "/images/iplanx-logo-black.png";

import { validateLeadInput } from "shared/service/utils/InputValidator";
import {
  CreateLeadInput,
  DeleteObjectInput,
  Lead,
  GetObjectInput,
  ListQueryInput,
  UpdateLeadInput,
  ModelResponse,
} from "shared/API";
import { FIREBASE_ANALYTICS, FIREBASE_AUTH } from "shared/Firebase";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  getFirestoreObjectById,
  listFirestoreObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { logEvent } from "firebase/analytics";

const LEAD_COLLECTION = "leads";

export async function leadsAPI(input: {
  query: ListQueryInput;
}): Promise<ModelResponse<Lead>> {
  return listFirestoreObjects<Lead>(LEAD_COLLECTION, input);
}

export async function leadByIdAPI(input: {
  lead: GetObjectInput;
}): Promise<Lead> {
  return getFirestoreObjectById<Lead>(LEAD_COLLECTION, input.lead);
}

export async function createLeadAPI(input: {
  lead: CreateLeadInput;
}): Promise<string> {
  const leadId = await createFirestoreObjectPrivileged<CreateLeadInput>(
    LEAD_COLLECTION,
    input.lead,
    validateLeadInput,
  );
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "lead_creation", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  return leadId;
}

export async function deleteLeadAPI(input: {
  lead: DeleteObjectInput;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "lead_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(LEAD_COLLECTION, input.lead);
}

export async function updateLeadAPI(input: {
  lead: UpdateLeadInput;
}): Promise<void> {
  await updateFirestoreObject<UpdateLeadInput>(
    LEAD_COLLECTION,
    input.lead,
    validateLeadInput,
  );
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "lead_update", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
}

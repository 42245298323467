import { useMutation } from "@tanstack/react-query";
import { deleteEventAPI } from "shared/service/Event";
import XConfirmationModal from "components/XConfirmationModal";

interface EventDeleteModalProps {
  onSuccess: () => void;
  onClose: () => void;
  eventId: string;
}

const EventDeleteModal = ({
  onSuccess,
  onClose,
  eventId,
}: EventDeleteModalProps) => {
  const { mutate } = useMutation({
    mutationKey: ["EventDeleteModal.delete", eventId],
    mutationFn: deleteEventAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to delete the artist: ", error);
    },
  });

  return (
    <XConfirmationModal
      title="Delete Event"
      message="Are you sure you want to delete this artist?"
      onConfirm={() =>
        mutate({
          event: { id: eventId },
        })
      }
      onClose={onClose}
    />
  );
};

export default EventDeleteModal;

import Footer from "layout/Footer";
import Header from "layout/Header";
import { Outlet } from "react-router";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      <main className="w-full flex flex-col flex-1 mx-auto py-4 md:py-9 px-[24px] md:px-[80px] overflow-y-scroll max-w-[1280px] scrollbar-tailwind relative">
        <Outlet />
        <div className="absolute w-[80%] m-auto h-[400px] bottom-0 left-0 right-0 bg-primary/30 blur-[250px]"></div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;

import { useState, useRef, useEffect } from "react";
import { Tooltip } from "@mui/material";

interface XTruncateProps {
  text: string;
  className?: string;
  width?: string;
}

const XTruncate: React.FC<XTruncateProps> = ({
  text,
  className = "",
  width = "w-[240px]",
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip title={isTruncated ? text : ""} arrow>
      <span
        ref={textRef}
        className={`block ${width} truncate overflow-hidden text-ellipsis ${className}`}
      >
        {text}
      </span>
    </Tooltip>
  );
};

export default XTruncate;

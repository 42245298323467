import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";

interface SnackbarOptions {
  message: string;
  entity?: string;
  severity?: AlertColor;
  duration?: number;
}

interface SnackbarContextType {
  showSnackbar: (options: SnackbarOptions) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions>({
    message: "",
    severity: "error",
    duration: 4000,
  });

  const showSnackbar = (options: SnackbarOptions) => {
    setOpen(false); // Close the Snackbar first
    setTimeout(() => {
      setSnackbarOptions({
        message: options.message,
        severity: options.severity || "error",
        duration: options.duration || 4000,
      });
      setOpen(true); // Reopen the Snackbar with updated options
    }, 0);
  };

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return; // Ignore clickaway close
    }
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={snackbarOptions.duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarOptions.severity}
          variant="filled"
        >
          {snackbarOptions.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

import Page from "components/Page";
import { useState } from "react";
import { deleteVendorAPI, vendorsAPI } from "shared/service/Vendor";
import { paths } from "router/routes";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { Link } from "react-router";
import XTable from "components/table/XTable";
import { useQuery } from "@tanstack/react-query";
import XIconCell from "components/table/XIconCell";
import XConfirmModal from "components/XConfirmModal";
import { iDelete, iEdit, iView } from "components/common/XIcons";

const ArtistsPage = () => {
  const [vendorId, setVendorId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);

  const { data: artists, refetch } = useQuery({
    queryKey: ["ArtistsPage"],
    queryFn: () =>
      vendorsAPI({
        query: {},
      }),
  });

  const rows =
    artists?.items?.map((x) => ({
      id: x.id,
      displayName: x.displayName,
      createdAt: x.createdAt,
      imageUrl: x.imageUrl,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Name",
      minWidth: 240,
      align: "center",
      renderCell: (params) => {
        return (
          <XIconCell
            title={params.row.displayName}
            imageURL={params.row.imageUrl}
          />
        );
      },
    },
    { field: "id", headerName: "Artist ID", minWidth: 240 },
    { field: "createdAt", headerName: "Created At", minWidth: 200 },
    {
      field: "actions",
      headerName: "",
      minWidth: 200,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <div className="flex items-center justify-end gap-2">
          <Link to={paths.Artists.details(params.row.id)}>
            <IconButton>
              <img src={iView} />
            </IconButton>
          </Link>
          <Link to={paths.Artists.update(params.row.id)}>
            <IconButton>
              <img src={iEdit} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setVendorId(params.row.id);
              setModalName("delete");
            }}
          >
            <img src={iDelete} />
          </IconButton>
        </div>
      ),
      flex: 1,
    },
  ];

  const onClose = () => {
    setVendorId(null);
    setModalName(null);
  };

  return (
    <Page>
      <XTable title="Artists" rows={rows} columns={columns} rowHeight={76} />
      {modalName === "delete" && vendorId && (
        <XConfirmModal
          entity="artist"
          action="delete"
          onClose={onClose}
          onSuccess={refetch}
          mutationFn={deleteVendorAPI}
          mutationInput={{ vendor: { id: vendorId } }}
          entityId={vendorId}
        />
      )}
    </Page>
  );
};

export default ArtistsPage;

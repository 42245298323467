import Page from "components/Page";
import { vendorByIdAPI } from "shared/service/Vendor";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { XTabPanel, XTabs } from "components/XTabs";
import ArtistPersonalInfoUpdate from "../ArtistTabPersonalInfo";
import ArtistUpdateSocialMedia from "./ArtistSocialMediaUpdate";
import ArtistTabHighlights from "../ArtistTabHighlights";
import ArtistTabServices from "../ArtistTabServices";
import { IconButton } from "@mui/material";
import ArtistProfilePictureUpdateModal from "./ArtistProfilePictureUpdateModal";
import ArtistTabPackages from "../ArtistTabPackages";
import { iEditRed } from "components/common/XIcons";
const ArtistUpdatePage = () => {
  const { id, ownerId } = useParams();
  const [vendorId, setVendorId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const { data: artist, refetch } = useQuery({
    queryKey: ["ArtistUpdatePage.vendorByIdAPI", id],
    queryFn: () =>
      vendorByIdAPI({
        vendor: { id: String(id) },
        isPrivate: Boolean(ownerId),
      }),
    enabled: !!id,
  });

  const handleTabChange = (index: number) => {
    setTabValue(index);
    window.location.hash = tabs[index].hash; // Update the hash in the URL
  };

  const tabs = [
    { label: "Personal Info", hash: "personal-info" },
    { label: "Social Media", hash: "social-media" },
    { label: "Highlights", hash: "highlights" },
    { label: "Services", hash: "services" },
    { label: "Packages", hash: "packages" },
  ];

  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the "#" from the hash
    const tabIndex = tabs.findIndex((tab) => tab.hash === hash);
    if (tabIndex !== -1) {
      setTabValue(tabIndex);
    }
  }, [tabs]);

  const onClose = () => {
    setVendorId(null);
    setModalName(null);
  };

  return (
    <Page title="Edit Profile">
      <div className="flex items-center gap-9 mb-10">
        <div className="relative">
          <img src={artist?.imageUrl} className="w-[128px] rounded-[50%]" />
          <IconButton
            onClick={() => {
              setModalName("profile");
              setVendorId(String(artist?.id));
            }}
            className="absolute bottom-[-5px] right-[-5px] bg-white border-solid border-[7px] border-black"
          >
            <img src={iEditRed} />
          </IconButton>
        </div>
        <div className="flex flex-col flex-1 gap-4">
          <h2 className="flex gap-2 items-center text-xxl font-medium leading-8 m-0">
            {artist?.displayName} <img src="/assets/icons/verified.svg" />
          </h2>
          <h3 className="text-md font-medium m-0">{artist?.headline}</h3>
        </div>
      </div>
      <XTabs
        tabs={tabs.map((tab) => tab.label)}
        tabValue={tabValue}
        onTabChange={handleTabChange}
      />
      {artist && (
        <XTabPanel value={tabValue} index={0}>
          <ArtistPersonalInfoUpdate artist={artist} refetch={refetch} />
        </XTabPanel>
      )}
      {artist && (
        <XTabPanel value={tabValue} index={1}>
          <ArtistUpdateSocialMedia artist={artist} refetch={refetch} />
        </XTabPanel>
      )}
      {artist && (
        <XTabPanel value={tabValue} index={2}>
          <ArtistTabHighlights vendorId={artist.id} refetch={refetch} />
        </XTabPanel>
      )}
      {artist && (
        <XTabPanel value={tabValue} index={3}>
          <ArtistTabServices vendorId={artist.id} />
        </XTabPanel>
      )}
      {artist && (
        <XTabPanel value={tabValue} index={4}>
          <ArtistTabPackages vendorId={artist.id} />
        </XTabPanel>
      )}
      {modalName === "profile" && vendorId && (
        <ArtistProfilePictureUpdateModal
          vendorId={vendorId}
          imageURL={artist?.imageUrl}
          isPrivate={Boolean(ownerId)}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
    </Page>
  );
};

export default ArtistUpdatePage;

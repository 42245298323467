import { useMutation } from "@tanstack/react-query";
import { publishVendorAPI } from "shared/service/Vendor";
import XConfirmationModal from "components/XConfirmationModal";

interface LeadConvertModalProps {
  onSuccess: () => void;
  onClose: () => void;
  leadId: string;
}

const LeadConvertModal = ({
  onSuccess,
  onClose,
  leadId,
}: LeadConvertModalProps) => {
  const { mutate } = useMutation({
    mutationKey: ["LeadConvertModal.convert", leadId],
    mutationFn: publishVendorAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to publish the artist: ", error);
    },
  });

  return (
    <XConfirmationModal
      title="Publish Artist"
      message="Are you sure you want to publish this artist?"
      onConfirm={() =>
        mutate({
          vendorId: leadId,
        })
      }
      onClose={onClose}
    />
  );
};

export default LeadConvertModal;

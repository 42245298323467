import { useQuery } from "@tanstack/react-query";
import { XFButton } from "components/form";
import { useState } from "react";
import { Service } from "shared/API";
import { servicesAPI } from "shared/service/Service";
import ArtistServiceCreateModal from "./actions/ArtistServiceCreateModal";
import ArtistServiceUpdateModal from "./actions/ArtistServiceUpdateModal";
import ArtistServiceDeleteModal from "./actions/ArtistServiceDeleteModal";
import XListMini from "components/XListMini";
import { iAddRed } from "components/common/XIcons";

const ArtistTabServices = ({ vendorId }: { vendorId: string }) => {
  const [serviceId, setServiceId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);

  const { data: services, refetch: refetchServices } = useQuery({
    queryKey: ["ArtistTabServices.servicesAPI", vendorId],
    queryFn: () =>
      servicesAPI({
        vendor: { id: vendorId },
      }),
    enabled: !!vendorId,
  });

  const onClose = () => {
    setModalName(null);
    setServiceId(null);
  };

  return (
    <>
      <div className="flex flex-col gap-6 w-[500px]">
        {services &&
          services.items.map((service: Service, i: number) => (
            <XListMini
              index={i}
              title={service.title}
              subtitle={service.serviceType}
              onUpdate={() => {
                setServiceId(service.id);
                setModalName("update");
              }}
              onDelete={() => {
                setServiceId(service.id);
                setModalName("delete");
              }}
              key={service.id}
            />
          ))}
        <XFButton
          title="Add Service"
          type="button"
          onClick={() => {
            setModalName("create");
          }}
          variant="quaternary"
          icon={iAddRed}
        />
      </div>
      {modalName === "create" && (
        <ArtistServiceCreateModal
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchServices}
        />
      )}
      {modalName === "update" && serviceId && (
        <ArtistServiceUpdateModal
          serviceId={serviceId}
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchServices}
        />
      )}
      {modalName === "delete" && serviceId && (
        <ArtistServiceDeleteModal
          serviceId={serviceId}
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetchServices}
        />
      )}
    </>
  );
};

export default ArtistTabServices;

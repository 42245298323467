import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

const white = "#FFFFFF";

// const fontFamily = `"Poppins", "Google Sans", "Roboto", "Helvetica", "Arial", "sans-serif"`;

const theme = createTheme({
  spacing: 8,
  palette: {
    mode: "dark",
    background: {
      default: "#000000",
      paper: "#000000",
    },
    primary: {
      contrastText: white,
      dark: colors.grey[700],
      main: colors.grey[800],
      light: colors.grey[100],
    },
    secondary: {
      contrastText: white,
      dark: colors.blueGrey[900],
      main: colors.blueGrey[900],
      light: colors.blueGrey[800],
    },
    success: {
      main: "#4caf50", // Green
      light: "#80e27e",
      dark: "#087f23",
      contrastText: "#ffffff",
    },
    error: {
      main: "#f44336", // Red
      light: "#ff7961",
      dark: "#ba000d",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ff9800", // Orange
      light: "#ffc947",
      dark: "#c66900",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2196f3", // Light Blue
      light: "#6ec6ff",
      dark: "#0069c0",
      contrastText: "#ffffff",
    },
    text: {
      primary: white,
      secondary: white,
    },
    divider: colors.blueGrey[900],
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "white", // Force black background
          boxShadow: "none", // Remove shadow
          opacity: 1, // Ensure full opacity
        },
      },
    },
  },
});

export default theme;

import Page from "components/Page";
import { Link, useParams } from "react-router";
import { vendorByIdAPI } from "shared/service/Vendor";
import {
  testEventListItem1,
  testEventListItem2,
  testEventListItem3,
  testEventListItem4,
} from "shared/service/__test__/TestData";
import { useQuery } from "@tanstack/react-query";
import { XFButton } from "components/form";
import { XLabeled, XChipped } from "components/XTitles";
import { extractLocation } from "utils/address";
import { XHighlight } from "components/XHighlight";
import { XEventItem } from "components/XEventItem";
import { XSocials, XSocialsPinned } from "components/XSocialMedia";
import { highlightsAPI } from "shared/service/Highlight";
import { iHeart, iLocation, iLocationWhite } from "components/common/XIcons";
import { useWindowSize } from "hooks/useWindowSize";
import { IconButton } from "@mui/material";
import { paths } from "router/routes";

const ArtistDetailsPage = () => {
  const { id, ownerId } = useParams();
  const { isMobile } = useWindowSize();

  const { data: artist } = useQuery({
    queryKey: ["ArtistDetailsPage.eventByIdAPI", id],
    queryFn: () =>
      vendorByIdAPI({
        vendor: { id: String(id) },
        isPrivate: Boolean(ownerId),
      }),
    enabled: !!id,
  });

  const { data: highlights } = useQuery({
    queryKey: ["ArtistDetailsPage.highlightsAPI", id],
    queryFn: () =>
      highlightsAPI({
        vendor: { id: String(id) },
      }),
    enabled: Boolean(id),
  });

  const events = [
    testEventListItem1,
    testEventListItem2,
    testEventListItem3,
    testEventListItem4,
    testEventListItem1,
    testEventListItem3,
  ];

  if (artist) {
    const items = [];
    if (artist.address)
      items.push({
        label: "Location",
        value: extractLocation(artist.address),
        icon: iLocation,
      });

    {
      /***** TODO MR: Update when backend is ready for verify *****
    items.push(
      { label: "Bookings", value: "500+", icon: iCalendar },
      { label: "Endorsed", value: "25+", icon: iEndorse }
    );
    *******/
    }

    return (
      <Page>
        <div className="flex flex-col gap-[36px]">
          <div className="w-full flex justify-center md:items-start lg:items-center gap-[16px] md:gap-[40px]">
            <img
              src={artist.imageUrl}
              className="md:w-[280px] rounded-[50%] w-[120px]"
            />
            <div className="flex flex-col md:flex-1 flex-wrap gap-[10px] md:gap-[20px] lg:gap-0 lg:flex-row">
              <div className="flex flex-col flex-1 gap-[4px] md:gap-[24px]">
                <div className="flex flex-col gap-[2px] md:gap-[24px]">
                  <h1 className="flex gap-2 m-0 items-center leading-[30px] text-md font-semibold md:text-xl lg:text-xxl md:font-medium">
                    {artist.displayName}
                    {/***** TODO MR: Update when backend is ready for verify
                    <img src="/assets/icons/verified.svg" />
                    ****/}
                  </h1>
                  <h2 className="text-3xs md:text-md">{artist.headline}</h2>
                </div>
                {artist.address && (
                  <XChipped
                    label={extractLocation(artist.address) || ""}
                    icon={iLocationWhite}
                  />
                )}

                {/* {!isMobile && <XLabeledContainer items={items} />} */}
                {!isMobile && artist.socialMedia && (
                  <XLabeled
                    label=""
                    value={<XSocialsPinned socialMedia={artist.socialMedia} />}
                  />
                )}
              </div>
              <div className="flex flex-row items-start gap-3">
                <XFButton
                  title="Book Now"
                  type="button"
                  size={isMobile ? "sm" : "lg"}
                  link={paths.Artists.book(artist.id)}
                  className="self-start"
                />
                <IconButton className="border border-white border-solid p-[8px] md:p-[15px]">
                  <img src={iHeart} className="h-[17px] md:h-[24px]" />
                </IconButton>
              </div>
            </div>
          </div>

          {isMobile && artist.socialMedia && (
            <XSocialsPinned socialMedia={artist.socialMedia} variant="bw" />
          )}

          {highlights?.items && (
            <div className="flex flex-col gap-[24px]">
              <h3>Highlights</h3>
              <div className="flex flex-col sm:flex-row flex-wrap gap-[16px] lg:gap-[24px]">
                {highlights.items
                  .sort((a, b) => a.order - b.order)
                  .map((x, i) => (
                    <Link
                      to={x.url}
                      key={i}
                      className={[
                        "flex flex-1 gap-3 rounded-[84px] bg-x-gray1/20 hover:bg-primary/20 transition-all duration-500",
                        "w-full min-w-[240px] max-w-[100%] p-2 pr-[20px]", // mobile
                        "sm:w-full sm:max-w-[calc(50%-8px)] sm:min-w-[calc(50%-8px)]", // ipad
                        "md:w-full", // desktop
                        "lg:max-w-[calc(50%-8px)] lg:min-w-[357px] lg:p-4 lg:pr-[30px]",
                      ].join(" ")}
                      target="_blank"
                    >
                      <XHighlight
                        title={x.title}
                        subtitle={x.subtitle}
                        imageURL={x.imageUrl}
                      />
                    </Link>
                  ))}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-[24px]">
            <h3>Upcoming Events</h3>
            <div className="w-full flex flex-col md:flex-row overflow-x-auto whitespace-nowrap divide-y divide-x-gray2/50 md:divide-y-0">
              {events.map((x, i) => (
                <Link to={paths.Events.details(x.id)} key={i}>
                  <XEventItem
                    key={i}
                    title={x.title}
                    date={new Date(x.startTimestamp)}
                    imageURL={x.imageUrl}
                    location={x.address && extractLocation(x.address)}
                    isMobile={isMobile}
                  />
                </Link>
              ))}
            </div>
          </div>

          {artist.description && (
            <div className="flex flex-col gap-[24px]">
              <h3>Biography</h3>
              <p className="text-md text-heading1">{artist.description}</p>
            </div>
          )}

          {artist.socialMedia && (
            <div className="flex flex-col gap-[24px]">
              <h3>Social Media</h3>
              <XSocials socialMedia={artist.socialMedia} />
            </div>
          )}
        </div>
      </Page>
    );
  } else {
    return <></>;
  }
};

export default ArtistDetailsPage;

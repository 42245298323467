import { validateServiceInput } from "shared/service/utils/InputValidator";
import {
  Service,
  UpdateServiceInput,
  ModelResponse,
  CreateServiceInput,
  DeleteObjectInput,
  GetObjectInput,
} from "shared/API";
import { FIREBASE_ANALYTICS, FIREBASE_AUTH } from "shared/Firebase";
import {
  createFirestoreObjectPrivileged,
  deleteFirestoreObject,
  getFirestoreObjectById,
  listFirestoreObjects,
  updateFirestoreObject,
} from "shared/service/utils/FirestoreUtils";
import { logEvent } from "firebase/analytics";
import { VENDOR_COLLECTION } from "./Vendor";

const SERVICE_COLLECTION = "services";

function generateServiceCollectionPath(vendorId: string): string {
  return `${VENDOR_COLLECTION}/${vendorId}/${SERVICE_COLLECTION}`;
}

export async function servicesAPI(input: {
  vendor: GetObjectInput;
}): Promise<ModelResponse<Service>> {
  return listFirestoreObjects<Service>(
    generateServiceCollectionPath(input.vendor.id),
    {
      query: {
        /* Empty query lists everything */
      },
    },
  );
}

export async function serviceByIdAPI(input: {
  vendor: GetObjectInput;
  service: GetObjectInput;
}): Promise<Service> {
  return getFirestoreObjectById<Service>(
    generateServiceCollectionPath(input.vendor.id),
    input.service,
  );
}

export async function createServiceAPI(input: {
  vendorId: string;
  service: CreateServiceInput;
}): Promise<string> {
  const serviceId = await createFirestoreObjectPrivileged<CreateServiceInput>(
    generateServiceCollectionPath(input.vendorId),
    input.service,
    validateServiceInput,
  );
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "service_creation", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  return serviceId;
}

export async function deleteServiceAPI(input: {
  vendorId: string;
  service: DeleteObjectInput;
}): Promise<void> {
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS, "service_deletion", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
  await deleteFirestoreObject(
    generateServiceCollectionPath(input.vendorId),
    input.service,
  );
}

export async function updateServiceAPI(input: {
  vendorId: string;
  service: UpdateServiceInput;
}): Promise<void> {
  await updateFirestoreObject<UpdateServiceInput>(
    generateServiceCollectionPath(input.vendorId),
    input.service,
    validateServiceInput,
  );
  if (FIREBASE_ANALYTICS) {
    logEvent(FIREBASE_ANALYTICS!, "service_update", {
      uid: FIREBASE_AUTH!.currentUser!.uid,
    });
  }
}

import {
  CreateChatInput,
  CreateEndorsementInput,
  CreateEventInput,
  CreateHighlightInput,
  CreateLeadInput,
  CreateMessageInput,
  CreateNotificationInput,
  CreatePackageInput,
  CreateServiceInput,
  CreateVendorInput,
  Name,
  ServiceStub,
  SocialMedia,
  UpdateChatInput,
  UpdateEventInput,
  UpdateHighlightInput,
  UpdateLeadInput,
  UpdateMessageInput,
  UpdateNotificationInput,
  UpdatePackageInput,
  UpdateServiceInput,
  UpdateVendorInput,
} from "shared/API";
import {
  isAddressValid,
  isContactValid,
  isDefined,
  isStringValid,
  isTimestampValid,
  isUrlValid,
} from "shared/utils/EntityValidator";
import { XStatus } from "shared/service/utils/XStatus";
import zipcodes from "zipcodes";

export function validateEventInput(
  eventInput: CreateEventInput | UpdateEventInput,
): XStatus {
  if (isDefined(eventInput.title) && !isStringValid(eventInput.title!)) {
    return XStatus.InvalidTitle;
  }
  if (isDefined(eventInput.ticketLink) && !isUrlValid(eventInput.ticketLink!)) {
    return XStatus.InvalidTicketLink;
  }
  if (
    isDefined(eventInput.description) &&
    !isStringValid(eventInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (
    isDefined(eventInput.startTimestamp) &&
    !isTimestampValid(eventInput.startTimestamp!)
  ) {
    return XStatus.InvalidStartTime;
  }
  if (
    isDefined(eventInput.endTimestamp) &&
    ((isDefined(eventInput.startTimestamp) &&
      eventInput.startTimestamp! > eventInput.endTimestamp!) ||
      !isTimestampValid(eventInput.endTimestamp!))
  ) {
    return XStatus.InvalidEndTime;
  }
  if (eventInput.contact && !isContactValid(eventInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(eventInput.address) && !isAddressValid(eventInput.address!)) {
    return XStatus.InvalidAddress;
  }
  return XStatus.Valid;
}

export function validateLeadInput(
  leadInput: CreateLeadInput | UpdateLeadInput,
): XStatus {
  if (
    isDefined(leadInput.eventTitle) &&
    !isStringValid(leadInput.eventTitle!)
  ) {
    return XStatus.InvalidTitle;
  }
  if (
    isDefined(leadInput.description) &&
    !isStringValid(leadInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (
    isDefined(leadInput.startTimestamp) &&
    isDefined(leadInput.endTimestamp) &&
    leadInput.startTimestamp! > leadInput.endTimestamp!
  ) {
    return XStatus.InvalidStartTime;
  }
  if (leadInput.contact && !isContactValid(leadInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(leadInput.address) && !isAddressValid(leadInput.address!)) {
    return XStatus.InvalidAddress;
  }
  if (leadInput.name && !isNameValid(leadInput.name!)) {
    return XStatus.InvalidName;
  }
  return XStatus.Valid;
}

async function populateAddress(
  vendorInput: CreateVendorInput | UpdateVendorInput,
) {
  if (!isDefined(vendorInput.address)) {
    return;
  }
  if (!isDefined(vendorInput.address!.postalCode)) {
    return;
  }
  const address = zipcodes.lookup(vendorInput.address!.postalCode!);
  if (!isDefined(address)) {
    return;
  }
  if (isDefined(address!.city)) {
    vendorInput.address!.city = address!.city;
  }
  if (isDefined(address!.state)) {
    vendorInput.address!.state = address!.state;
  }
  if (isDefined(address!.country)) {
    vendorInput.address!.country = address!.country;
  }
  if (isDefined(address!.latitude) && isDefined(address!.longitude)) {
    vendorInput.location = {
      latitude: address!.latitude,
      longitude: address!.longitude,
    };
  }
}

export function validateVendorInput(
  vendorInput: CreateVendorInput | UpdateVendorInput,
): XStatus {
  if (
    isDefined(vendorInput.username) &&
    !isStringValid(vendorInput.username!)
  ) {
    return XStatus.InvalidUsername;
  }
  if (
    isDefined(vendorInput.displayName) &&
    !isStringValid(vendorInput.displayName!)
  ) {
    return XStatus.InvalidName;
  }
  if (
    isDefined(vendorInput.headline) &&
    !isStringValid(vendorInput.headline!)
  ) {
    return XStatus.InvalidHeadline;
  }
  if (
    isDefined(vendorInput.description) &&
    !isStringValid(vendorInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  if (vendorInput.contact && !isContactValid(vendorInput.contact)) {
    return XStatus.InvalidContact;
  }
  if (isDefined(vendorInput.address) && !isAddressValid(vendorInput.address!)) {
    return XStatus.InvalidAddress;
  }
  if (isDefined(vendorInput.socialMedia)) {
    const status = isSocialMediaValid(vendorInput.socialMedia!);
    if (status != XStatus.Valid) {
      return status;
    }
  }
  populateAddress(vendorInput);
  return XStatus.Valid;
}

export function validateChatInput(
  chatInput: CreateChatInput | UpdateChatInput,
): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateEndorsementInput(
  endorsementInput: CreateEndorsementInput,
): XStatus {
  if (
    isDefined(endorsementInput.endorser.imageUrl) &&
    !isUrlValid(endorsementInput.endorser.imageUrl!)
  ) {
    return XStatus.InvalidLink;
  }
  if (
    isDefined(endorsementInput.endorser.displayName) &&
    !isStringValid(endorsementInput.endorser.displayName!)
  ) {
    return XStatus.InvalidName;
  }
  return XStatus.Valid;
}

export function validateHighlightInput(
  highlightInput: CreateHighlightInput | UpdateHighlightInput,
): XStatus {
  if (
    isDefined(highlightInput.title) &&
    !isStringValid(highlightInput.title!)
  ) {
    return XStatus.InvalidTitle;
  }
  if (
    isDefined(highlightInput.subtitle) &&
    !isStringValid(highlightInput.subtitle!)
  ) {
    return XStatus.InvalidTitle;
  }
  if (isDefined(highlightInput.url) && !isUrlValid(highlightInput.url!)) {
    return XStatus.InvalidLink;
  }
  return XStatus.Valid;
}

export function validateServiceInput(
  highlightInput: CreateServiceInput | UpdateServiceInput | ServiceStub,
): XStatus {
  if (
    isDefined(highlightInput.title) &&
    !isStringValid(highlightInput.title!)
  ) {
    return XStatus.InvalidTitle;
  }
  if (
    isDefined(highlightInput.description) &&
    !isStringValid(highlightInput.description!)
  ) {
    return XStatus.InvalidDescription;
  }
  return XStatus.Valid;
}

export function validatePackageInput(
  serviceInput: CreatePackageInput | UpdatePackageInput,
): XStatus {
  if (isDefined(serviceInput.title) && !isStringValid(serviceInput.title!)) {
    return XStatus.InvalidTitle;
  }
  if (isDefined(serviceInput.services)) {
    for (const service of serviceInput.services!) {
      const status = validateServiceInput(service);
      if (status != XStatus.Valid) {
        return status;
      }
    }
  }
  return XStatus.Valid;
}

export function validateNotificationInput(
  notificationInput: CreateNotificationInput | UpdateNotificationInput,
): XStatus {
  if (
    isDefined(notificationInput.message) &&
    !isStringValid(notificationInput.message!)
  ) {
    return XStatus.InvalidText;
  }
  if (
    isDefined(notificationInput.notifier?.id) &&
    !isStringValid(notificationInput.notifier!.id)
  ) {
    return XStatus.InvalidSenderId;
  }
  if (isDefined(notificationInput.url) && !isUrlValid(notificationInput.url!)) {
    return XStatus.InvalidLink;
  }
  return XStatus.Valid;
}

export function validateCreateChatInput(chatInput: CreateChatInput): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateUpdateChatInput(chatInput: UpdateChatInput): XStatus {
  if (isDefined(chatInput.title) && !isStringValid(chatInput.title!)) {
    return XStatus.InvalidTitle;
  }
  return XStatus.Valid;
}

export function validateCreateMessageInput(
  messageInput: CreateMessageInput,
): XStatus {
  if (isDefined(messageInput.text) && !isStringValid(messageInput.text!)) {
    return XStatus.InvalidText;
  }

  if (
    isDefined(messageInput.senderId) &&
    !isStringValid(messageInput.senderId!)
  ) {
    return XStatus.InvalidSenderId;
  }
  return XStatus.Valid;
}

export function validateUpdateMessageInput(
  messageInput: UpdateMessageInput,
): XStatus {
  if (isDefined(messageInput.text) && !isStringValid(messageInput.text!)) {
    return XStatus.InvalidText;
  }
  return XStatus.Valid;
}

export function isNameValid(name: Name): boolean {
  if (isDefined(name.firstName) && !isStringValid(name.firstName!)) {
    return false;
  }
  if (isDefined(name.lastName) && !isStringValid(name.lastName!)) {
    return false;
  }
  if (isDefined(name.middleName) && !isStringValid(name.middleName!)) {
    return false;
  }
  return true;
}

export function validateNameInput(name: Name): XStatus {
  if (!isNameValid(name)) {
    return XStatus.InvalidName;
  }
  return XStatus.Valid;
}

export function isSocialMediaValid(socialMedia: SocialMedia[]): XStatus {
  for (const media of socialMedia) {
    if (!isUrlValid(media.url)) {
      return XStatus.InvalidSocialMedia;
    }
  }
  return XStatus.Valid;
}

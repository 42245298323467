import { useEffect } from "react";

interface BackgroundProps {
  color?: string;
  imageUrl?: string;
}

function useBodyBackground({ color, imageUrl }: BackgroundProps): void {
  useEffect(() => {
    if (imageUrl) {
      document.body.style.backgroundImage = `url(${imageUrl})`;
      document.body.style.backgroundSize = "100%";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundColor = "";
    } else if (color) {
      document.body.style.backgroundColor = color;
      document.body.style.backgroundImage = "";
    }

    // Cleanup to reset the background when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.backgroundImage = "";
    };
  }, [color, imageUrl]);
}

export default useBodyBackground;

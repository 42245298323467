import { IconButton } from "@mui/material";
import { iDelete, iDrag, iEdit } from "./common/XIcons";

interface XListMiniProps {
  index: number;
  title: string;
  subtitle?: string;
  imageURL?: string;
  onUpdate: () => void;
  onDelete: () => void;
}

const XListMini = ({
  index,
  title,
  subtitle,
  imageURL,
  onUpdate,
  onDelete,
}: XListMiniProps) => {
  console.log(index);
  return (
    <div className="flex gap-2">
      <img src={iDrag} />
      <div
        className={`w-full flex items-center gap-4 p-4 border-[1px] border-x-gray3 rounded-[90px] ${!imageURL && "pl-8"}`}
      >
        {imageURL && <img src={imageURL} className="w-[80px] rounded-[50%]" />}
        <div className="flex flex-col flex-1">
          <span className="text-md font-medium">{title}</span>
          {subtitle && (
            <span className="text-2xs font-light text-white/70">
              {subtitle}
            </span>
          )}
        </div>
        <IconButton onClick={onUpdate}>
          <img src={iEdit} />
        </IconButton>
        <IconButton onClick={onDelete}>
          <img src={iDelete} />
        </IconButton>
      </div>
    </div>
  );
};

export default XListMini;

import { useMutation } from "@tanstack/react-query";
import { XFForm, XFTextField, XFUpload } from "components/form";
import XModal from "components/XModal";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "hooks/useSnackbar";
import { CreateHighlightInput } from "shared/API";
import { createHighlightAPI } from "shared/service/Highlight";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { schemaArtistTabHighlights } from "utils/validation/schema";

interface ArtistHighlightCreateModalProps {
  vendorId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const initialValues: FormikValues = {
  title: "",
  subtitle: "",
  url: "",
  image: null,
};

const getCreateHighlightInput = (
  vendorId: string,
  values: FormikValues
): { vendorId: string; highlight: CreateHighlightInput; image?: File } => ({
  vendorId,
  highlight: {
    title: values.title,
    subtitle: values.subtitle,
    url: values.url,
    order: values.order || 0,
  },
  image: values.image,
});

const ArtistHighlightCreateModal = ({
  vendorId,
  onSuccess,
  onClose,
}: ArtistHighlightCreateModalProps) => {
  const { showSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationKey: ["ArtistTabHighlights.createHighlight", vendorId],
    mutationFn: createHighlightAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage("Highlight", "create", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Highlight", "create", false)}. ${error.message}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    console.log("input", getCreateHighlightInput(vendorId, values));
    mutate(getCreateHighlightInput(vendorId, values), {
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaArtistTabHighlights}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <XModal
          title="Create Highlight"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField name="title" label="Title" />
              <XFTextField name="subtitle" label="Subtitle" />
              <XFTextField name="url" label="URL" />
              <XFUpload
                label="Thumbnail"
                name="image"
                onChange={(file) => setFieldValue("image", file)}
              />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default ArtistHighlightCreateModal;

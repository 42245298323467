import { useMutation, useQuery } from "@tanstack/react-query";
import { XFForm, XFTextField, XFUpload } from "components/form";
import XModal from "components/XModal";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "hooks/useSnackbar";
import { Highlight, UpdateHighlightInput } from "shared/API";
import { highlightByIdAPI, updateHighlightAPI } from "shared/service/Highlight";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { schemaArtistTabHighlights } from "utils/validation/schema";

interface ArtistHighlightUpdateModalProps {
  highlightId: string;
  vendorId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const getInitialValues = (highlight: Highlight | undefined) => ({
  title: highlight?.title || "",
  subtitle: highlight?.subtitle || "",
  url: highlight?.url || "",
});

const getUpdateHighlightInput = (
  vendorId: string,
  values: FormikValues,
  highlightId: string
): { vendorId: string; highlight: UpdateHighlightInput; image?: File } => ({
  vendorId,
  highlight: {
    id: highlightId,
    url: values.url,
    title: values.title,
    subtitle: values.subtitle,
    order: values.order || 0,
  },
  image: values.image,
});

const ArtistHighlightUpdateModal = ({
  highlightId,
  vendorId,
  onSuccess,
  onClose,
}: ArtistHighlightUpdateModalProps) => {
  const { showSnackbar } = useSnackbar();

  const { data: highlight } = useQuery({
    queryKey: ["ArtistHighlightUpdateModal.highlightsAPI", highlightId],
    queryFn: () =>
      highlightByIdAPI({
        vendor: { id: vendorId },
        highlight: { id: highlightId },
      }),
    enabled: !!vendorId,
  });

  const { mutate } = useMutation({
    mutationKey: ["ArtistHighlightUpdateModal.updateHighlight", highlightId],
    mutationFn: updateHighlightAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
      showSnackbar({
        message: generateMutationMessage("Highlight", "update", true),
        severity: "success",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Highlight", "update", false)}. ${error.message}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues, { setSubmitting }: any) => {
    mutate(getUpdateHighlightInput(vendorId, values, highlightId), {
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={getInitialValues(highlight)}
      validationSchema={schemaArtistTabHighlights}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <XModal
          title="Update Highlight"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFTextField name="title" label="Title" />
              <XFTextField name="subtitle" label="Subtitle" />
              <XFTextField name="url" label="URL" />
              <XFUpload
                label="Thumbnail"
                name="image"
                onChange={(file) => setFieldValue("image", file)}
              />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default ArtistHighlightUpdateModal;

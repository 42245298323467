import { useMutation } from "@tanstack/react-query";
import XConfirmationModal from "components/XConfirmationModal";
import { deleteHighlightAPI } from "shared/service/Highlight";

interface ArtistHighlightDeleteModalProps {
  onSuccess: () => void;
  onClose: () => void;
  highlightId: string;
  vendorId: string;
}

const ArtistHighlightDeleteModal = ({
  onSuccess,
  onClose,
  highlightId,
  vendorId,
}: ArtistHighlightDeleteModalProps) => {
  const { mutate } = useMutation({
    mutationKey: ["ArtistHighlightDeleteModal.delete", highlightId],
    mutationFn: deleteHighlightAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to delete the highlight: ", error);
    },
  });

  return (
    <XConfirmationModal
      title="Delete Highlight"
      message="Are you sure you want to delete this highlight?"
      onConfirm={() =>
        mutate({
          vendorId: vendorId,
          highlight: { id: highlightId },
        })
      }
      onClose={onClose}
    />
  );
};

export default ArtistHighlightDeleteModal;

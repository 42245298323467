import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { iAdd } from "components/common/XIcons";
import { XFButton } from "components/form";

interface XTableProps {
  rows: any[];
  columns: GridColDef[];
  title?: string;
  rowHeight?: number;
  onCreate?: () => void;
}

const XTable = (props: XTableProps) => {
  const paginationModel = { page: 0, pageSize: 25 };
  return (
    <div className="w-full bg-[#212121]/50 rounded-lg">
      <div className="flex justify-between px-8 py-6">
        {props.title && (
          <h1 className="flex-1 text-lg font-medium m-0 leading-normal">
            {props.title}
          </h1>
        )}

        {/* <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        /> */}
        {props.onCreate && (
          <XFButton
            onClick={props.onCreate}
            title="Create"
            variant="tertiary"
            size="md"
            icon={iAdd}
          />
        )}
      </div>

      <DataGrid
        rows={props.rows}
        columns={props.columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[25, 50]}
        rowHeight={props.rowHeight}
        disableRowSelectionOnClick
        classes={{
          root: "",
          columnHeader:
            "text-2xs text-heading2 font-normal bg-[#212121]/50 leading-[24px] h-[auto]  px-8 py-2",
          cell: "border-x-gray3 px-8",
        }}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            fontSize: 16,
            fontWeight: 700,
            background: "inherit",
            px: 0,
          },
          "& .MuiDataGrid-cell": {
            color: "rgba(255,255,255, 0.8)",
            fontWeight: 400,
            px: 0,
          },
          "& .MuiToolbar-root p": {
            fontWeight: 400,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "var(--tw-color-black)",
          },
        }}
      />
    </div>
  );
};

export default XTable;

import { useField, useFormikContext } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { iDown } from "components/common/XIcons";

export interface XFAutocompleteOption {
  label: string;
  value: string;
}

interface XFAutocompleteAPIProps {
  name: string;
  label: string;
  fetchOptions: (searchTerm: string) => Promise<XFAutocompleteOption[]>; // API function passed as prop
}

const XFAutocompleteAPI: React.FC<XFAutocompleteAPIProps> = ({
  name,
  label,
  fetchOptions,
}) => {
  const { setFieldValue } = useFormikContext(); // Get Formik context
  const [field, meta] = useField(name); // Formik field
  const [inputValue, setInputValue] = useState("");
  const [debouncedInput, setDebouncedInput] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [options, setOptions] = useState<XFAutocompleteOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    XFAutocompleteOption[]
  >(field.value || []);

  // Sync initial Formik value when it changes
  useEffect(() => {
    setSelectedOptions(field.value || []);
  }, [field.value]);

  // Debounce Input for API Call
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInput(inputValue);
    }, 1000);
    return () => clearTimeout(handler);
  }, [inputValue]);

  useEffect(() => {
    if (!debouncedInput) {
      setOptions([]);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const newOptions = await fetchOptions(debouncedInput);
        setOptions(newOptions);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [debouncedInput, fetchOptions]);

  const filteredOptions = options.filter(
    (option) =>
      !field.value?.some(
        (selected: XFAutocompleteOption) => selected.value === option.value
      )
  );

  return (
    <div className="flex flex-col flex-1 gap-3">
      <label className="text-md">{label}</label>
      <Autocomplete
        multiple
        options={filteredOptions}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        loading={loading}
        value={selectedOptions} // Formik field value
        onChange={(event, newValue) => {
          setSelectedOptions(newValue);
          setFieldValue(name, newValue); // Update Formik value
        }}
        onInputChange={(event, newValue) => setInputValue(newValue)}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        isOptionEqualToValue={(option, value) => option.value === value.value} // Ensure correct comparison
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            sx={{
              borderRadius: "50px",
              gap: 1,
              "& .MuiInputLabel-root": {
                px: 1,
                color: "rgba(255,255,255,0.4)",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "60px",
                borderColor: "rgba(255,255,255,0.2)",
                px: "16px",
                py: "13px",
                borderBottomLeftRadius: menuOpen ? 0 : "60px",
                borderBottomRightRadius: menuOpen ? 0 : "60px",
                borderTopLeftRadius: menuOpen ? "24px" : "60px",
                borderTopRightRadius: menuOpen ? "24px" : "60px",
                background: "#000",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderBottomWidth: menuOpen ? 0 : "1px",
                  borderBottomLeftRadius: menuOpen ? 0 : "60px",
                  borderBottomRightRadius: menuOpen ? 0 : "60px",
                  borderTopLeftRadius: menuOpen ? "24px" : "60px",
                  borderTopRightRadius: menuOpen ? "24px" : "60px",
                  borderColor: "rgba(255,255,255,0.7)",
                  borderWidth: "1px",
                },
              "& .MuiAutocomplete-tag": {
                m: 0,
                height: "30px",
              },
              "& .MuiAutocomplete-input": {
                py: "0 !important",
                height: "30px !important",
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.value}
            className="hover:bg-x-gray3 px-6 py-2 cursor-pointer mx-2 rounded-lg"
          >
            {option.label}
          </li>
        )}
        PaperComponent={(props) => (
          <div
            {...props}
            className="bg-black border border-t-0 border-white/70 rounded-bl-lg rounded-br-lg pt-2"
          />
        )}
        popupIcon={<img src={iDown} />}
        classes={{
          inputRoot: "text-heading1",
          groupLabel: "text-xs uppercase text-primary",
          option: "text-xs",
          listbox: "pt-0",
          endAdornment: "right-[24px]",
        }}
      />

      {/* Hidden input for Formik submission */}
      <input
        type="hidden"
        name={name}
        value={JSON.stringify(field.value || [])}
      />
    </div>
  );
};

export default XFAutocompleteAPI;

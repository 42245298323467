import Page from "components/Page";
import { XFButton, XFForm, XFTextField } from "components/form";
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { Formik, FormikValues } from "formik";
import useBodyBackground from "hooks/useBodyBackground";
import { useState } from "react";
import { useNavigate } from "react-router";
import { paths } from "router/routes";

import { schemaLogin } from "utils/validation/schema";

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  useBodyBackground({
    imageUrl: "/images/iplanx-background.jpg",
  });

  const [authing, setAuthing] = useState(false);

  const signInWithGoogle = async () => {
    setAuthing(true);

    signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        console.log(response);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        setAuthing(false);
      });
  };

  const handleSubmit = async (values: FormikValues, actions: any) => {
    try {
      // TODO MR: Fix
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("===> user", userCredential);
          navigate("/");
        } else {
          console.log("No user info found!");
        }
      });
    } catch (error) {
      // TODO MR: Fix
      actions.setFieldError("email", {
        message: "Invalid credentials. Please try again",
      });
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <Page title="Login">
      <Formik
        initialValues={initialValues}
        validationSchema={schemaLogin}
        onSubmit={handleSubmit}
      >
        {/* <div className="flex flex-col gap-6 w-[400px]"> */}
        <XFForm className="w-[400px]">
          <XFTextField name="email" label="Email" />
          <XFTextField name="password" type="password" label="Password" />
          <XFButton title="Login" type="submit" width="100%" size="lg" />
          <XFButton
            title="Sign In with Google"
            variant="secondary"
            onClick={() => signInWithGoogle()}
            disabled={authing}
            width="100%"
            size="lg"
            type="button"
          />
          <XFButton
            title="Sign Up with Email"
            variant="secondary"
            link={paths.Register}
            disabled={authing}
            width="100%"
            size="lg"
            type="button"
          />
          {/* </div> */}
        </XFForm>
      </Formik>
    </Page>
  );
};

export default Login;

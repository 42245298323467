import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import { Formik, FormikValues } from "formik";
import { schemaRegister } from "utils/validation/schema";
import { useNavigate } from "react-router";
import Page from "components/Page";
import { XFButton, XFForm, XFTextField } from "components/form";
import useBodyBackground from "hooks/useBodyBackground";

const Register = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  useBodyBackground({
    imageUrl: "/images/iplanx-background.png",
  });

  const handleSubmit = async (
    values: FormikValues,
    { setSubmitting, setErrors }: any,
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: values.name.split(" ", 2)[0],
      });

      navigate("/");
    } catch (error: any) {
      setErrors({ email: error.message }); // Update with specific error handling if needed
      console.error("Registration error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  return (
    <Page title="Register">
      <Formik
        initialValues={initialValues}
        validationSchema={schemaRegister}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <XFForm>
            <XFTextField name="name" label="Name" />
            <XFTextField name="email" label="Email" />
            <XFTextField name="password" type="password" label="Password" />
            {/* <XFTextField
              name="confirmPassword"
              type="password"
              label="Confirm Password"
            /> */}
            <XFButton
              type="submit"
              title="Register"
              disabled={isSubmitting}
              size="lg"
              width="100%"
            />
          </XFForm>
        )}
      </Formik>
    </Page>
  );
};

export default Register;

interface XEventDateProps {
  date: Date;
  variant: "light" | "dark";
}

const XEventDate = ({ date, variant }: XEventDateProps) => {
  const monthValue = date?.toLocaleString("default", { month: "short" });
  const dayValue = date?.toLocaleString("default", { day: "2-digit" });

  const baseClasses =
    "flex flex-col min-w-[55px] h-[63px] text-center justify-center uppercase rounded-[8px]";
  const lightClasses =
    "bg-white border border-x-heading2/30 shadow-[0px_0px_32px_8px_rgba(0,_0,_0,_0.25)] absolute top-1.5 left-1.5 z-10";
  const darkClasses = "bg-x-gray3";

  const resolvedClasses = [
    baseClasses,
    variant === "light" && lightClasses,
    variant === "dark" && darkClasses,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={resolvedClasses}>
      <div className="text-primary text-sm font-medium">{monthValue}</div>
      <div
        className={`${variant === "light" ? "text-x-gray2" : "text-x-heading1"} text-lg leading-[24px] font-semibold`}
      >
        {dayValue}
      </div>
    </div>
  );
};

export default XEventDate;

import { useMutation } from "@tanstack/react-query";
import { publishEventAPI } from "shared/service/Event";
import XConfirmModal from "components/XConfirmModal";

interface EventPublishModalProps {
  onSuccess: () => void;
  onClose: () => void;
  eventId: string;
}

const EventPublishModal = ({
  onSuccess,
  onClose,
  eventId,
}: EventPublishModalProps) => {
  const { mutate } = useMutation({
    mutationKey: ["EventPublishModal.publish", eventId],
    mutationFn: publishEventAPI,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error("Failed to publish the event: ", error);
    },
  });

  return (
    <XConfirmModal
      title="Publish Event"
      message="Are you sure you want to publish this event?"
      action="publish"
      entity="event"
      entityId={eventId}
      mutationFn={publishEventAPI}
      mutationInput={{
        eventId,
      }}
      onSuccess={() =>
        mutate({
          eventId,
        })
      }
      onClose={onClose}
    />
  );
};

export default EventPublishModal;

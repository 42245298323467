import Page from "components/Page";
import { Link } from "react-router";
import { vendorsAPI } from "shared/service/Vendor";
import { useQuery } from "@tanstack/react-query";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import XTable from "components/table/XTable";
import { paths } from "router/routes";
import { useState } from "react";
import { useParams } from "react-router";
import {
  ArtistCreateModal,
  ArtistDeleteModal,
  ArtistPublishModal,
} from "./actions";
import XIconCell from "components/table/XIconCell";
import { iDelete, iEdit, iPublish, iView } from "components/common/XIcons";

const MyArtistsPage = () => {
  const [vendorId, setVendorId] = useState<string | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);
  const { ownerId } = useParams();

  const { data: artists, refetch } = useQuery({
    queryKey: ["MyArtistsPage", ownerId],
    queryFn: () =>
      vendorsAPI({
        query: {
          queryInput: {
            andStatements: [
              {
                whereFilters: [
                  {
                    field: "ownerId",
                    operation: "==",
                    value: String(ownerId),
                  },
                ],
              },
            ],
          },
        },
        isPrivate: true,
      }),
    enabled: !!ownerId,
  });

  const rows =
    artists?.items?.map((x) => ({
      id: x.id,
      displayName: x.displayName,
      createdAt: x.createdAt,
      imageUrl: x.imageUrl,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Display Name",
      sortable: true,
      minWidth: 240,
      renderCell: (params) => (
        <XIconCell
          title={params.row.displayName}
          imageURL={params.row.imageUrl}
        />
      ),
    },
    { field: "id", headerName: "Artist ID", minWidth: 240 },
    { field: "createdAt", headerName: "Created At", minWidth: 200 },
    {
      field: "actions",
      headerName: "",
      minWidth: 200,
      sortable: false,
      align: "right",
      renderCell: (params) => (
        <div className="flex items-center justify-end gap-2">
          <Link to={paths.Artists.details(params.row.id, String(ownerId))}>
            <IconButton>
              <img src={iView} />
            </IconButton>
          </Link>
          <Link to={paths.Artists.update(params.row.id, String(ownerId))}>
            <IconButton>
              <img src={iEdit} />
            </IconButton>
          </Link>
          <IconButton
            onClick={() => {
              setModalName("publish");
              setVendorId(params.row.id);
            }}
          >
            <img src={iPublish} />
          </IconButton>
          <IconButton
            onClick={() => {
              setModalName("delete");
              setVendorId(params.row.id);
            }}
          >
            <img src={iDelete} />
          </IconButton>
        </div>
      ),
      flex: 1,
    },
  ];

  const onClose = () => {
    setVendorId(null);
    setModalName(null);
  };

  return (
    <Page>
      <XTable
        rows={rows}
        columns={columns}
        title="My Artists"
        onCreate={() => setModalName("create")}
      />
      {modalName === "create" && (
        <ArtistCreateModal onClose={onClose} onSuccess={refetch} />
      )}
      {modalName === "delete" && vendorId && (
        <ArtistDeleteModal
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
      {modalName === "publish" && vendorId && (
        <ArtistPublishModal
          vendorId={vendorId}
          onClose={onClose}
          onSuccess={refetch}
        />
      )}
    </Page>
  );
};

export default MyArtistsPage;

import { Divider } from "@mui/material";
import React from "react";

export interface XLabeledContainerProps {
  items: XLabeledProps[] | [];
}
export interface XLabeledProps {
  label: string;
  icon?: string;
  value: React.ReactNode | string;
  child?: React.ReactNode | string;
  className?: string;
}

export interface XIconedProps {
  label?: string;
  icon?: string;
}

export interface XChippedProps {
  label?: string;
  icon?: string;
}

export const XChipped = (props: XChippedProps) => {
  return (
    <div className="flex self-start items-center gap-[4px] bg-x-gray3 text-x-heading2 pl-[5px] pr-[7px] py-[3px] md:py-[5px] md:pl-[12px] md:pr-[16px] md:gap-[8px] rounded-lg">
      {props.icon && <img src={props.icon} className="md:w-[24px]" />}
      <div className="text-4xs text-heading2 md:text-md">{props.label}</div>
    </div>
  );
};

export const XIconed = (props: XIconedProps) => {
  return (
    <div className="flex items-center gap-2">
      {props.icon && <img src={props.icon} />}
      <div className="text-sm text-x-gray1">{props.label}</div>
    </div>
  );
};

export const XLabeled = (props: XLabeledProps) => {
  return (
    <div className="flex flex-col gap-1">
      {props.label && props.icon && (
        <XIconed icon={props.icon} label={props.label} />
      )}
      {props.value && <div className="text-lg font-medium">{props.value}</div>}
      {props.child && <div>{props.child}</div>}
    </div>
  );
};

export const XLabeledContainer = (props: XLabeledContainerProps) => {
  return (
    <div className="flex items-center gap-[36px]">
      {props.items.map((x, i) => (
        <React.Fragment key={i}>
          <XLabeled
            label={x.label}
            value={x.value}
            icon={x.icon}
            child={x.child}
          />
          {props.items.length - 1 !== i && (
            <Divider
              orientation="horizontal"
              style={{ borderWidth: "1px", height: "40px" }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

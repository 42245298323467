import { NavLink } from "react-router";
import { paths } from "router/routes";

import useAuth from "hooks/useAuth";
import AccountMenu from "./AccountMenu";
import { iMenu, iPlanXWhite } from "components/common/XIcons";
import { useState } from "react";
import { XFButton } from "components/form";
import { Drawer } from "@mui/material";

const Header = () => {
  const { userId, displayName } = useAuth();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const initial = displayName?.charAt(0);

  const isLogin = window.location.pathname.includes("login");

  return (
    <>
      <header className="hidden md:flex justify-between w-full max-w-[1280px] mx-auto py-6 px-4 md:px-[80px]">
        <div className="flex items-center gap-[40px]">
          <NavLink to={paths.Home}>
            <img className="w-[68px] mb-3" src={iPlanXWhite} />
          </NavLink>
          <div className="flex-1 flex gap-6">
            {/* 
            <NavLink to={paths.Artists.list()}>Artists</NavLink>
            <NavLink to={paths.Events.list()}>Events</NavLink>
            <NavLink to={paths.Events.details()}>Event Details</NavLink>
            <NavLink to={paths.Vendors.details()}>Artist Details</NavLink>
            <NavLink to={paths.Admin.Venues}>Admin Venues</NavLink> 
            */}
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          {userId ? (
            <>
              <div>Hi {displayName}</div>
              <AccountMenu initial={initial} userId={userId} />
            </>
          ) : isLogin ? null : (
            <XFButton
              title="Login"
              size="sm"
              variant="secondary"
              link={paths.Login}
            />
          )}
        </div>
      </header>
      <div className="flex md:hidden items-center px-[24px] py-[12px] sticky top-0 bg-black z-[100]">
        <div className="flex w-[60px] justify-start">
          <img src={iMenu} onClick={toggleDrawer(true)} />
        </div>
        <div className="flex flex-1 justify-center">
          <img className="w-[68px]" src={iPlanXWhite} />
        </div>
        <div className="flex w-[60px] justify-end">
          {userId ? (
            <AccountMenu initial={initial} userId={userId} />
          ) : isLogin ? null : (
            <XFButton title="Login" variant="secondary" link={paths.Login} />
          )}
        </div>
        {userId && (
          <Drawer open={open} onClose={toggleDrawer(false)}>
            <div className="flex flex-col gap-[8px] w-64 p-4 bg-x-gray3 h-full relative">
              <button
                onClick={() => setOpen(false)}
                className="mb-4 text-white absolute top-4 right-4"
              >
                X
              </button>
              <NavLink
                className="py-2 px-4 hover:bg-x-gray4 hover:rounded-md"
                to={paths.Home}
                onClick={() => setOpen(false)}
              >
                Home
              </NavLink>
              <NavLink
                className="py-2 px-4 hover:bg-x-gray4 hover:rounded-md"
                to={paths.Artists.list()}
                onClick={() => setOpen(false)}
              >
                Artists
              </NavLink>
              <NavLink
                className="py-2 px-4 hover:bg-x-gray4 hover:rounded-md"
                to={paths.Events.list()}
                onClick={() => setOpen(false)}
              >
                Events
              </NavLink>
            </div>
          </Drawer>
        )}
      </div>
    </>
  );
};

export default Header;

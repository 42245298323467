interface CardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ icon, title, description }) => {
  return (
    <div className="p-[20px] border-[0.5px] border-x-gray2 rounded-md">
      <div className="mb-6">{icon}</div>
      <h5 className="mt-0 mb-1 text-x-heading1 text-lg font-medium">{title}</h5>
      <p className="text-x-heading2 text-2xs font-light">{description}</p>
    </div>
  );
};

export default Card;

import { useMutation } from "@tanstack/react-query";
import { XFForm, XFButton, XFTextField, XFTextarea } from "components/form";
import XFServiceField from "components/form/XFServiceField";
import XModal from "components/XModal";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "hooks/useSnackbar";
import { createServiceAPI } from "shared/service/Service";
import { generateMutationMessage } from "utils/generateMutationMessage";
import { schemaArtistCreateService } from "utils/validation/schema";

interface ArtistServiceCreateModalProps {
  vendorId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const initialValues: FormikValues = {
  serviceType: "",
  title: "",
  description: "",
};

const ArtistServiceCreateModal = ({
  vendorId,
  onSuccess,
  onClose,
}: ArtistServiceCreateModalProps) => {
  const { showSnackbar } = useSnackbar();

  const { mutate } = useMutation({
    mutationKey: ["ArtistTabServices.createService", vendorId],
    mutationFn: createServiceAPI,
    onSuccess: () => {
      onClose();
      onSuccess();
      showSnackbar({
        message: `${generateMutationMessage("Service", "create", false)}`,
        severity: "error",
      });
    },
    onError: (error) => {
      showSnackbar({
        message: `${generateMutationMessage("Service", "create", false)}. ${error}`,
        severity: "error",
      });
    },
  });

  const onSubmit = (values: FormikValues) => {
    console.log("values", values);
    mutate({
      vendorId,
      service: {
        serviceType: values.serviceType,
        title: values.title,
        description: values.description,
      },
    });
  };

  return (
    <Formik
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={schemaArtistCreateService}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <XModal
          title="Create Service"
          onClose={onClose}
          onSubmit={handleSubmit} // Standardized action
          isSubmitting={isSubmitting}
          renderContent={() => (
            <XFForm>
              <XFServiceField name="type" label="Service Type" />
              <XFTextField name="title" label="Title" />
              <XFTextarea name="description" label="Description" />
              <XFButton title="Save Changes" type="submit" size="lg" />
            </XFForm>
          )}
        />
      )}
    </Formik>
  );
};

export default ArtistServiceCreateModal;

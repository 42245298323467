import { vendorsAPI } from "shared/service/Vendor";

export const fetchArtists = async (searchTerm: string) => {
  const response = await vendorsAPI({
    query: {
      queryInput: {
        andStatements: [
          {
            whereFilters: [
              {
                field: "displayName",
                operation: ">=",
                value: searchTerm,
              },
              {
                field: "displayName",
                operation: "<=",
                value: searchTerm + "\uf8ff",
              },
            ],
          },
        ],
      },
    },
  });

  return response.items.map((item: any) => ({
    label: item.displayName, // Adjust based on API response
    value: item.id,
  }));
};
